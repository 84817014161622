.report-display {
   
    padding: 15px;
   
    border-radius: 10px;
   
    /* margin-bottom: 5px; */
  
   
    /* box-shadow: inset 0px 4px 4px #dcd4ff89; */
}
       
.togglegroup{
    font-size: small !important;
}
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #5c2572 !important;
    background:radial-gradient(#0dc5c55e, transparent) !important;
    
    font-size: 15px;
    font-weight: bold;
 
}

.reports-menu{
  
    /* margin-left: 40px;
    margin-right: 10px;
    border-radius: 5px;
    */
}
.exportDisplay{
    width:98px;
    float: right;
    background-color: #D9D9D9;
    margin-right: 5.3%;
    z-index: 10000;
    position: relative;
   margin-top: 0.5%;
    border-radius: 10px;
}
.title-of-reportpage {
    text-align: center;
    color: #4e4b4f !important;
    text-transform: capitalize !important;
    font-weight: bolder;
    margin-top: 10px;
    margin-bottom: 10px;
}
.custombtn input{

height:22px;
border:none;
background-color: #f8f9fa;
margin-top: -5px;
}

.btnexport{
   
width: 98px;
height: 39px;
 color: rgba(23, 116, 168, 0.8);
 border: none ;
    font-size:medium;
background: #D9D9D9;
border-radius: 10px;
}
.custombtn{
    height:35px;
    
}
.custombtnreg{
   
    box-shadow:none;
}
.btn-col1 {
    background-color: #5c2572 !important;
    border-color: #173e81 !important;
    font-size: 17px !important;
}
.flex-column-report{
    background-color: rgba(0, 101, 124, 0.33);
    border-radius: 10%;
    margin-top: 20%;
}
.checkBoxWebReport {

	box-sizing: border-box;
	align-items: center;
	padding-top:5%;
	margin-top: 74%;
	height: 160px;
	color: #757575;
	
 background:#C3F0E0;
	border: 1px solid #CBCACA;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	padding-bottom: 5%;
    margin-left: 2%;
}
