.services-btn-grid {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
  justify-items: flex-end;
  gap: 10px;
  margin-top: 14px;
}

.booking-detail {
  /* background-color: #F8F8F8; */
  /* box-shadow: 1px 5px 6px #bebebe; */
  box-shadow: 1px 2px 3px 2px #bebebe;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width:90%
}

.flex-col33 {
  float: left;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-top: 10px;
  /* width:20%; */
}

.flex-col35 {
  float: left;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-top: 10px;
  width: 35% !important;
}

.flex-col2 {
  float: left;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  /* width:500px; */
  margin-top: 72px !important;
  padding: 20px;
  width: 60%;
}

.flex-col3 {
  background-color: #e8afed;
  float: right;
  margin-top: 92px !important;
  margin-left: 110px;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 300px;
  height: 60%;
  margin-top: 10px;
}

.flex-col34 {
  background-color: #e8afed;
  float: right;
  margin-top: 92px !important;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 25%;
  height: 530px !important;
  margin-top: 10px;
  padding-bottom: 50px;
}

.booking-details-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 10px;
}

.no-messages {
  margin-top: 20%;
  margin-left: 35%;
  font-size: 30px;
}

@media only screen and (min-width: 600px) {
  .book-modal-width {
    max-width: 500px;


    /* New width for default modal */
  }

  .book-modal-width {
    max-width: 500px;

  }
}

@media only screen and (max-width: 600px) {
  .customer-display {
    flex-direction: column;
  }
  .flex-col33 {
    width: 100% !important
  }
  .flex-col34 {
    width: 100% !important
  }
  .flex-col35 {
    width: 100% !important
  }
  .flex-col2 {
    width: 100% !important;
  }
  .message-container {
    width: 100% !important
  }
}

.detail-icon {
  align-self: center;
  justify-self: center;
  font-size: 17px;
  color: #696969;
}

.slot-booking {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  padding: 5px;
  border-radius: 2px;
  margin: 5px;
  color: black;
}

.appointment-doctor-list {
  background-color: white;
  box-shadow: 0 5px 6px #bebebe;
  padding: 10px;
  width: 40%;
  
}

/* .appointment-doctor-list .list-item :hover{
    background-color: #bebebe;
  }  */
/* .hover-service-listings :hover{
    background-color: #696969;
  }
  .hover-service-listings :focus{
    background-color: #696969;
  } */

#csc-listing:focus {
  background-color: #FFEDD4;
}

.service-details {
  background-color: white;
  box-shadow: 0 5px 6px #bebebe;
  padding: 10px;
  width: 35%;
}

.service-priority {
  width: 70%;
  margin-left: auto;
}

.service-date {
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  margin-left: auto;
  width: 50%;
}

.service-date:hover {
  border-color: rgb(24, 141, 236);
}

.service-phone-size {
  width: 70% !important;
  margin-left: auto;
}

.booking-tile {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 10px;
  margin-top: 10px;
  box-shadow: 0 4px 5px gray;
  width: 18%;
  height: 300px;
  position: relative; /* Add this */
}

.booking-tile .buttons {
  position: absolute; /* Add this */
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  display: flex;
  gap: 10px; /* Adjust as needed */
}

.cancel-booking {
  cursor: pointer;
  color: red;
  font-weight: bold;
}


.booking-header {
  background-color: var(--theme-blue);
  color: white;

  /* border-bottom: 1px solid #808080;  */
  padding: 10px;
}

.list-item1 {
  border-radius: 5px;



  width: 80%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  padding: 7px;
  margin-top: -1px;
  margin-bottom: 7px;
  margin-left: 8%;
  color: #d82323;
  border-radius: 5px;
  background-color: #fff;
}

.list-item5 {
  display: inline-flex;
  border-radius: 5px;
  width: 50%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  padding: 7px;
  margin-top: -1px;
  margin-bottom: 7px;
  margin-left: 5%;
  color: #d82323;
  border-radius: 5px;
  background-color: #fff;

}

.list-item6 {
  display: inline-flex;
  border-radius: 5px;
  width: 80%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  padding: 5px;
  margin-top: -1px;
  margin-bottom: 7px;
  margin-left: 0%;
  color: #0b0101;
  border-radius: 5px;
  background-color: #fff;

}

.list-item8 {
  border-radius: 10px;



  width: 80%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  height: 50%;
  margin-bottom: 7px;
  color: #d82323;
  /* border-radius: 5px; */
  background-color: #fff;
  margin-left: 10%;

}

.booking-body {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 5px;
}

.booking-body i {
  align-self: center;
}

.booking-body span {
  justify-self: flex-start;
}

.booking-tile h5 {
  font-weight: 600;
  margin-bottom: 0;
}

.doc-select-bg {
  background-color: antiquewhite;
}

.calender-app-sidebar-section .inline-slot-details h2 {
  font-weight: bold;
  color: #505050;
}

.calender-app-sidebar-section .flex-col {}

.inline-slot-details {
  display: inline-flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 5px 5px darkgray;
  align-items: center;
}

.cancel-booking {
  background-color: var(--flat-red);
  padding: 7px;
  align-self: flex-end;
  cursor: pointer;
  color: white;
  font-weight: bold;
  border-radius: 2px;
}


.cancel-booking:hover {
  background-color: rgb(255, 56, 99);
  box-shadow: 0 5px 5px darkgray;
}

.list-item .each-list-link11 {
  color: #b51929;
  font-weight: 600;
  text-transform: capitalize;
}

.card-content14 {

  background-color: #e8afed;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: left;
  padding-top: 15px;
  width: 200px;
  margin-left: 0px;
  height: 200px;
  border-radius: 5px;

}

.container12 {
  width: 500px;
  height: 750px;


}

.search-section1 {
  width: 50%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  margin-left: 8%;
}

          /* .active {
            color: #f03242 !important;
          }

          .inactive {
            color: #007bff;
          } */

.serviceshow {
  background-color: #C0C0C0;
  width: 550px;
  height: 450px;
  margin-top: 50px;
}

.title-of-page23 {
  padding: 10px 10px 30px 10px;
  color: #f9fdff;
  background-color: #7FB4B5;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 30px;
  font-weight: 600;

}
.cardcheckbox:hover,
.cardcheckbox:checked {
    opacity: 1;
}
.col1-3 {
  flex: 4 0 25%;
  max-width: 25%;
}

.title-of-page24 {
  margin: 30px;
  color: rgb(1, 1, 16);
  text-align: left;
  margin-top: 0%;
}

.best {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
}

.active1 {
  color: #007bff;

}

/* a:link, a:visited {
 
  color: black;
  border: 2px solid green;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a:hover, a:active {
  background-color: green;
  color: white;
} */

.super {
  margin-left: 30%;
}

.active-button {
  box-sizing: border-box;
  width: 127px;
  height: 47px;
  /* filter: drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.25)); */
  background: #54286E;
  border: 1px solid #54286E;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: white;
  margin-right: 10px;
}

.inactive-button {
  box-sizing: border-box;
  width: 127px;
  height: 47px;
  background: #FFFFFF;
  border: 1px solid #A4A2A2;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-right: 10px;
}

.header-title {

  /* CUSTOMER SERVICE CENTRE */
  height: 39px;
  margin-top: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  color: black;

}

.customer-display {
  display: flex;
  /* background-color: #f4e5f3; */
  background-color: #FFFFFF;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  width: 95%;
  margin-bottom: 0px;
  /* margin-top: 10px; */
  height: 100%;
  padding-bottom: 60px;
}

.flex-row1 {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap;
  justify-content: center; */
}

.search-form {
  display: flex;
  /* border-top-left-radius: 163px; */
  box-sizing: border-box;
  /* position: absolute; */
 width: 350px; 
  height: 58px;
  /* left: 35px; */
  /* top: 150px; */
  background: #FFFFFF;
  border: 1px solid #A4A2A2;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px 0px 0px 10px;
}

.search-input {
  border: 0px;
  border-radius: 10px 0px 0px 10px;
  padding-left: 2px;
  font-weight: 400;
  /* font-size: 20px; */
  line-height: 24px;
  width:56%;
  color: #000000;
}

.search-icon {
  margin-left: 87px;
  width: 62px;
  height: 58px !important;
  /* left: 249px; */
  /* top: 150px; */
  background: #3C689E;
  border-radius: 0px 4px 4px 0px !important;
  color: white;

}

.customer-listtext {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 12px;
  margin-left: 10px;
}

.customer-listdrop {

  box-sizing: border-box;
  height: 51px;
  background: #3C689E;
  border: 1px solid #A4A2A2;
  box-shadow: 5px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px 0px 0px 10px;
  color: white;
  padding-top: 13px;
  padding-left: 10px;
}

.card-container {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #A4A2A2;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 15px;
}

.card-listtext {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.master-serviceshow {

  box-sizing: border-box;

  /* margin: 30px; */
  background: #7FB4B5;
  border: 1px solid #A4A2A2;
  border-radius: 10px;
  width: 55%;
  min-height: 530px;
  margin-left: 2.5%;
}
.cartpage {

  box-sizing: border-box;

  /* margin: 30px; */
  background: #7FB4B5;
  border: 1px solid #A4A2A2;
  border-radius: 10px;
  width: 30%;
  min-height: 525px;
  margin-left: 60%;
  margin-top:-37.5%;
}

.search-section2 {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-left: 10%;
}

.search-input1 {
  flex: 1;
  margin-left: 25%;
}

@media only screen and (max-width: 600px) {
  .customer-display {
    flex-direction: column;
  }
  .search-section2 {
    flex-direction: column;
  }
  .search-input1 {
    flex: 1;
    margin-left: 0% !important;
    margin-top: 10px;
  }
}

.fav-layout {
  height: 47px;
  width: 190px;
  /* background: #FFFFFF; */
  background: #ccc;
  border-radius: 10px;
  margin-top: 10px;
  opacity: 70%;
  /* cursor: pointer; */
}

.fav-text {
  line-height: 45px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
}

.list-section {
  height: 330px;
  overflow-y: auto;
}

.cart-icon {

  color: white;
  margin-right: 6px;
  cursor: pointer;
  background: #88ACAC;
  padding: 10px;
  border-radius: 100px;
  font-size: 20px;
}

.feature-servicelist {

  text-decoration: none;
  flex: 1;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.cleaner-layout {
  margin-right: 30px;
  margin-top: 5px;
  /* background-color: #FFC107; */
  background-color: #ccc;
  border-radius: 20px;
  opacity: 70%;
}

.flex-cart-icon {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.expand-cart-list {
  /* border-radius: 5px; */
  width: 80%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  padding: 7px;
  margin-top: -7px;
  margin-bottom: 10px;
  margin-left: 10%;
  /* color: #d82323; */
  border-radius: 10px;
  background-color: #fff;
}


.cart-box {
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 10px;
}

.cart-inner-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 20px;
  margin-top: -30px;
  background-color: #fff;
  height: 420px !important;
  overflow-y: auto;
}

.cart-submit {
  height: 40px;
  /* background: #ccc; */
  background: #3C689E;
  cursor: pointer;
  border-radius: 15px;
  width: fit-content;
  padding-left: 10px;

}

.overflow {
  overflow-y: auto;
}
.message-menu {
  height: 650px;
  background: #7FB4B5;
  border: 1px solid #A4A2A2;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.message-container {
  /* width: 65%;
  height: 700px;
  margin-top: 170px;
  padding-left: 20px; */
  width: 65%;
  height: 790px;
  margin-top: 88px;
  padding-left: 20px;
}

.message-no-patients {
  text-align: center;
  font-size: 30px;
  margin-top: 240px;
  color: white !important;
}

.message-inner {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  /* height: 650px; */
  height: 728px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.message-header {

  background: #E3DDA6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.message-menu-item {
  height: 81px;
  background: #FFFFFF;
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  cursor: pointer;
}

.message-item-letter {
  width: 81px;
  height: 81px;
  background: #E3DDA6;
  text-align: center;
}

.message-item-font {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 81px;
  color: #FFFFFF;
  /* margin-left: 24px; */


}

.message-item-name {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 81px;
  color: #000;
  margin-left: 24px;


}

.message-content {
  flex: 1;
  margin-top: 20px;
  overflow-y: auto;
}

.message-layout {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message-textArea {
  background: #FFFFFF;
  border: 1px solid #A4A2A2;
  box-shadow: 9px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height:60px;
  width: 100%;
  flex: 1;
  padding: 15px;
}

.message-button {
  padding-left: 10px;
  cursor: pointer;
}

.message-received {
  background: #FFFFFF;
  border: 1px solid #A4A2A2;
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 20px 0px;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  /* margin-bottom: 10px; */
}

.message-sent {
  background: rgba(169, 221, 205, 0.54);
  border: 1px solid #A4A2A2;
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 0px 20px;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  /* margin-bottom: 10px; */
}
.message-margin {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

/* width */
.message-menu::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.message-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000000; 
  border-radius: 10px;
}
 
/* Handle */
.message-menu::-webkit-scrollbar-thumb {
  background: #FFFFFF; 
  border-radius: 10px;
}

/* Handle on hover */
.message-menu::-webkit-scrollbar-thumb:hover {
  background: #FFFFFF; 
}
.fc th {

  background-color: aqua;
}