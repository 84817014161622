.hosp-patient-form{
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 5px 10px rgb(0 0 0 / 30%) !important;
    margin-bottom: 50px;
}
.hosp-patient-form1{
    background-color: #FFFFFF;
  
    padding: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  
    margin-bottom: 50px;
}
.form-align{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.form-align .item-left-row{
    width: 45% !important;
    margin-bottom: 20px;
    align-items: flex-start;
}
.progress-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #F8F8F8;
}
.progress-bar .step{
    text-align: center;
    width: 100%;
    color: black;
}
.hosp-patient-form .title-of-tasks{
    color: var(--theme-blue) !important;
}

.vitals-row button{
    align-self: center;
    font-size: 1.5rem !important;
    cursor: pointer;
    background-color: var(--theme-blue);
    color: white;
    padding: 0 10px !important;
    border: none;
    outline: none;
}

.vitals-row .btn-add-vitals :hover{
    box-shadow: 0 1px 2px grey;
    background-color: rgb(114, 114, 211) !important;
    transform: scale(1.5);
}
.vitals-row .btn-add-vitals :focus{
    box-shadow: 0 1px 2px grey;
    background-color: rgb(114, 114, 211) !important;
    transform: scale(1.5);
}
.consent-div{
    align-self: center;
}
.allergy-body-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
#diagnostic-sec{
    width: 35% !important;
}
.search-procedure{
    display: flex;
    justify-content: space-evenly;
    background-color: gainsboro;
    border-bottom: 2px solid rgb(124, 122, 151);
    margin-bottom: 2px;
}
.vl{
    border-left: 4px solid grey;

}
.schedule-btn{
    display: flex;
    justify-content: center;
}
.scan-react-table .rt-table .rt-tbody .rt-tr-group .rt-tr :nth-child(5) .test-name-col {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}
.hosp-patient-form .error-validation-msg{
    z-index: 1;
}

.invoice-header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.invoice-detail{
    display: grid;
    justify-items: end;
}
.grid-procedure{
    display: grid;
    grid-template-columns: 6fr 1fr ;
}
.next-visit-box{
    background-color: #c2c0c094;
    padding: 20px;
    width: 95%;
    border-radius: 5px;
    box-shadow:  0 2px 2px #808080;
}

.next-visit-box h5{
    color: var(--theme-dark);
    margin-left: 40%;
}
.next-visit-box h6{
    color: var(--dark-teal);
}

.head-strip{
    background-color: #FFEDD4;
    padding: 10px;
    text-align: left;
    border-radius: 5px;

}
.print-btn{
    position: absolute;
    top: 15px;
    right: 15px;
}

@media only screen and (max-width : 800px){
        .sticky-nav{
            display: none;
        }
        .patient-record-holder{
            justify-content: center !important;
        }
     }

.personal-details-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
}