.search-form .search-div {
	margin:auto;
	text-align: center;
}

 .title-of-tasks {
    text-align: center;
    color: #666666;
    text-transform: capitalize !important;
    font-weight: bolder;
   }

  .search-section{
  	display: flex;
  	flex-direction: column;
  	flex-wrap: nowrap;
  	justify-content: center;
  	align-items: center;
  }

  .search-section .search-input{
  	width: 300px !important;
  }
  .search-btn{
    height:44px !important;
    border-radius: 0px 4px 4px 0px !important;
  }

  .list-item .each-list-link,
.list-item h5 {
  color: white;
  margin-right: 10px;
}
.list-item .each-list-link,
.list-item a {
  color: #6c757d ;
  font-weight: 600;
  text-transform: capitalize;
}

 .search-patient-page .list-item {
  padding: 10px;

  border-radius: 5px;
  margin: 10px;
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-align: left;
  transition: all 0.3s ease;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
}
.list-item:hover {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.list-item .details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.search-patient-page .list-item .details p {
  margin-right: 20px;
  background-color: #33B5E5;
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
}

.search-patient-page .list-item{
  width:70%;
 margin: 10px auto;
 text-decoration: none;

}
.search-patient-page h5{
  text-decoration: none;
}
.search-patient-page .list-item:hover{
  background-color: var(--theme-blue);
  box-shadow: 0 4px 4px var(--theme-blue);
}
.search-patient-page h5:hover{
  color:#fff !important;
}
.search-results{
  margin:30px auto;
}

.search-patient-page a:hover{
  text-decoration: none;
  color:#fff !important;
}
#buttons li {
  
  float: left;
  list-style: none;
  text-align: right;
  background-color:yellowgreen;
  margin-right: 30px;
  width: 150px;
  line-height: 60px;
}
#buttons li a {
  text-decoration: none;
  color: #FFFFFF;
  display: block;
}

#buttons li a:hover {
  text-decoration: none;
  color: #000000;
  background-color: #33B5E5;
}

.btn-letter{
  width:30px !important;
  margin:2px !important;
}

.directory-page{
 margin-top:10px;
}
.scroll-class{
  position: fixed;
  top:600px;

}
.search-patient-page .list-item .details span {
  margin-right: 20px;
  background-color: #a4a777;
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
  align-self: flex-start;
}