.deals-upload {
  margin-top: 3%;
  /* width: 200px; */
  /* height: 25px; */
  margin-left: 35%;
  background: #115269;
  border-radius: 20px;
  color: white;
  /* width:200px; */
}

.image-holder-div {
  /* width: 200px; */
   height: 100px; 
  background-color: #F1F1F1;
  border-radius: 10px;
}

.new-search {

  width: 90%;
  height: 35px;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.input-group-addon {
  height: 35px;
  background: #FFFFFF;
  border: 0px solid #ECECEC;
  box-shadow: 1px 1px 0px 1px rgb(199 199 199);
  border-radius: 10px;
  padding-top: 1%;

}

.dealsinput {
  box-sizing: border-box;
  width: 70px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 20px;
}

.dealsinputDate {
  box-sizing: border-box;
  padding-top: 1%;
  padding-left: 2%;
  width: 220px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 
}

.dealsdisc {
  box-sizing: border-box;
  width: 90px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
}

.dealdates {
  box-sizing: border-box;


  width: 100px;
  height: 30px;

  margin-top: 3%;
  background: #FFFFFF;
  border: 1px solid #C1C1C1;
  box-shadow: 0px 4px 4px rgba(164, 164, 164, 0.122);
  border-radius: 10px;
}

.deals-div {

  /* border-right: 2px solid #979797; */

}

.deal-border {
  border-right: 1px solid #979797;
}

.rs-picker-menu {
  z-index: 3000 !important;
  /* added a large random number, you can choose it more carefully based on your use-case.*/
  left: 523.667px !important;
  top: 10.406px !important;

}


.promo-items {
  margin-top: 2%;
  box-sizing: border-box;
  align-items: center;
  width:95%;
  color: #757575;
  background: #ffffff;
  /* border: 1px solid #CBCACA; */
  
  border-radius: 5px;
  padding: 1%;
}
.prod-div{
 
  display: flex;
  	/* flex-direction: column; */
  	flex-wrap: nowrap;
  	justify-content: center;
  	align-items: center;
   
   
   
   
}
.prod-div-search{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
 padding-top: 1%;
  align-items: center;
 
 
}

.promo-edit-button{
  width:"40px ";
  border: none;
height: 24px;
background:#ffffff;
color: #0350f6a7;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
}
.promo-save-button{
  width:"40px ";
 border: none;
 height: 24px;
 background:#ffffff;
 color: #077d58e9;
 box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
 }
 .avatardeals {
  width:100%;
  background-color: #f0f0f0;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  height:126px;
  
}
.css-df17o1 {
  background: rgb(0 0 0 / 25%);
}
.prod-div-date{
  display: flex;
  
  
  align-items: center;
 
 
}
.left-side{
background: #FFFFFF;
box-shadow: 0px 10px 10px rgba(214, 210, 210, 0.25) !important;
}
.main-section{
  box-sizing: border-box;
  text-align: center;
height: 33px;
background: #115269;
border: 1px solid #DADADA;
color:#ffffff;
align-items: center;
padding-top: 3%;
}
.sub-section-web{
  box-sizing: border-box;
width: 90%;
height: 33px;
margin-left: 10%;
background: #B4B2B2;
border: 1px solid #DADADA;
padding-top: 3%;
color: #ffffff;
padding-left: 4%;
}
.insideavtr{
  
box-sizing: border-box;

padding-top: 2%;
height: 35px;
text-align: center;
background: #FFFFFF;
border: 1px solid #9B9797;
}
.css-df17o1 {
  opacity:0.5 !important;
}