.notfn-holder {

	box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.2);

	margin: 20px auto;
	width: 700px;
	border-radius: 8px;
	min-height: 50vh;

}

.notfn-inner-holder {
	max-height: 1000px;
	overflow-y: auto;
	border-radius: 0px 0px 8px 8px;

}

.content-notfn {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.each-notfn {
	padding: 5px;
	border-top: 2px solid #dcdcdc;
	width: fit-content;
	/* To adjust the height as well */
	height: fit-content;

}

.toggle:hover {
	background-color: #FF8A00;
}

.each-notfn:hover {
	background-color: #ced6e4;
	cursor: pointer;
}

.notfn-title {

	color: var(--theme-blue);
}

.notfn-header {

	background-color: var(--dark-teal);
	color: white;
	padding: 10px;
	border-radius: 8px 8px 0px 0px;
	text-align: center;

}

.notfn-msg {
	color: grey;
	text-align: left;
	background-color: var(--theme-blue);
	margin: 10px;
	padding: 10px;
	color: #fff;
	border-radius: 4px;
}

.read-notfn {
	background-color: #ececec !important;

}

.unread-notfn {
	background-color: #fff !important;
}

.old-update-header {
	border-top: 2px solid #dcdcdc;

	background-color: grey;
	margin-top: 30px;
}

.dismiss {
	border-top: 2px solid #dcdcdc;
	text-align: right;
	align-content: flex-end;
	padding: 20px;
	color: blue;
}

.each-notfn-header {
	justify-content: flex-start !important;
}

.notfn1-header {
	justify-content: space-between !important;
}

.flex-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.notfn-type {
	color: var(--theme-blue);
	padding: 5px 8px;



	margin-left: 10px;
	margin-right: 10px;


	font-weight: bolder;
	text-transform: capitalize;

}

.notfn-patient {
	padding: 5px 8px;
	background-color: var(--flat-blue);
	color: white !important;
	border-radius: 15px;
	margin-left: 10px;
	margin-right: 10px;
	min-width: 150px;
	text-transform: capitalize;

}

.link-record {
	text-decoration: none !important;
}

.each-notfn:hover {

	background-color: #cdcdcd !important;
}

.notification-div {

	/* display: flex; */
	display: flex;

	right: 25px;
	margin-top: 38%;
	width: 96%;
	height: 70%;
	background-color: rgb(210, 225, 220);
}

.notification-space {
	/* padding: 0px 20px; */
	margin: 10px;
}

.dashboard1 {
	box-sizing: border-box;
	margin-right: 2%;
	width: 100%;
margin-left: -25px;


	background: #FFFFFF;
	border: 1px solid #E1E1E1;
	border-radius: 0px 20px 20px 20px;
}


.nav-tabs {
	margin-bottom: 0rem;
	/* float:left; */
	margin-right: 50%;
}

.health {
	box-sizing: border-box;


	width: 177px;
	height: 57px;
	display: flex;

	background: #FFFFFF;
	border: 1px solid #D9D9D9;
	box-shadow: 0px 4px 4px rgba(203, 203, 203, 0.25);
	border-radius: 20px 20px 0px 0px;
}

.general {
	margin-top: 1%;
	display: inline-flex;
	width: 170px;
	height: 40px;
	background: rgba(0, 101, 124, 0.33);
	border-radius: 20px 20px 0px 0px;
}

.healthtext {

	width: 72px;
	height: 27px;


	font-family: 'Inter';
	font-style: normal;
	font-weight: 800;
	font-size: 22px;
	line-height: 27px;
	/* identical to box height */


	color: #4B83EF;
}

.generalText {

	width: 82px;
	height: 27px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 27px;
	/* identical to box height */


	color: #FFFFFF;
}

.dropdown1 {

	box-sizing: border-box !important;


	width: 157px !important;
	height: 49px !important;
	margin-top: -1%;
	border-radius: 2%;
	background: #FFFFFF !important;
	border: 3px solid #E1E1E1 !important;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	border-radius: 20px !important;
}

.mb-one {

	width: 157px;
	height: 51px;



	border-radius: 20px;
}
.left-nav ul.stickynav-ul .checkBoxWebpatient li{
	 margin-bottom: 4px !important;
    padding: 6px !important;
	background-color:  var(--theme-blue);
border-left: solid 0.28rem var(--theme-blue);
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	 color: #fff !important;
    width:100%;
}
.checkBoxWebpatient {

	box-sizing: border-box;
	align-items: center;
	
	/* height: 260px; */
	color: #757575;
	
	background: #ffffff;;
	border: 1px solid #CBCACA;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	padding: 1%;
	
}
.checkBoxWebmenu {

	box-sizing: border-box;
	align-items: center;
	
	/* height: 260px; */
	color: #757575;
	
	background: #ffffff;;
	border: 1px solid #CBCACA;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	
	
}
.checkBoxWeb {

	box-sizing: border-box;
	align-items: center;
	display: flex;
	width: 160px;
	/* height: 260px; */
	color: #757575;
	
	background: #FFFFFF;
	border: 1px solid #CBCACA;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	padding-bottom: 5%;
}

.checkBoxWeb1 {

	box-sizing: border-box;
	align-items: center;
	display: flex;
	width: 150px;
	height: 230px;
	color: #757575;
	margin-left: 20px;
	margin-top: 10%;
	background: #FFFFFF;
	border: 1px solid #CBCACA;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}

.list-container {
	display: flex;
	font-size: medium;
	flex-direction: column;
	gap: 5px;
	margin-left: 3px;
	border-radius: 20px;
	/* margin-top: 20%; */
}

.list-container1 {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-left: 20px;
	/* margin-top: 20%; */
}

.checked-item,
.not-checked-item {
	margin-left: 10px;
}


/* /////////////////////////////////// */


.newcontainer {
	max-width: 1400px;
	padding: 0 15px;
	margin: 0 auto;
	overflow-x: scroll;
	border-style: solid;
	border-color: #DD621D;
	border-radius: 20px 20px 0px 0px;

}

.newcontainer3 {
	max-width: 1400px;
	padding: 0 15px;
	margin: 0 auto;
	overflow-x: scroll;
	border-style: solid;
	border-color: #FF8A00;
	border-radius: 20px 20px 0px 0px;
}

.newcontainer2 {
	max-width: 1400px;
	padding: 0 15px;
	margin: 0 auto;
	overflow-x: scroll;
	border-style: solid;
	border-color: #4B83EF;
	border-radius: 20px 20px 0px 0px;
}

h2 {
	font-size: 32px;
	margin-bottom: 1em;
}

.cards {
	display: grid;
	grid-auto-columns: 100%;
	grid-column-gap: 5px;
	grid-auto-flow: column;
	padding: 5px 0px;
	list-style: none;

	scroll-snap-type: x mandatory;
}

.cards3 {
	display: grid;
	grid-auto-columns: 100%;
	grid-column-gap: 5px;
	grid-auto-flow: column;
	padding: 5px 0px;
	list-style: none;

	scroll-snap-type: x mandatory;
}

.cards2 {
	display: grid;
	grid-auto-columns: 100%;
	grid-column-gap: 5px;
	grid-auto-flow: column;
	padding: 5px 0px;
	list-style: none;

	scroll-snap-type: x mandatory;
}

.cardm {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3px;
	background: #F07A7A;
	border-radius: 12px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
	scroll-snap-align: start;
	transition: all 0.1s;
	min-height: 140px;
	width: 150px;
}

/* .card1 {
	display: flex;
	flex-direction: column;
	padding: 15px;
	background: #E0D67F;
	border-radius: 12px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
	scroll-snap-align: start;
	transition: all 0.2s;
  } */
.card2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px;
	background: #78D29C;
	border-radius: 12px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
	scroll-snap-align: start;
	transition: all 0.2s;
	min-height: 140px;
	width: 150px;
}


.cardm:hover,
.card1:hover,
.card2:hover {
	color: "black";
	background: var(--orange);
}

.cardm .card-title {
	font-size: 20px;
}

.cardm .card-content {
	/* margin: 10px 0; */
	max-width: 85%;
}

.cardm .card-link-wrapper {
	margin-top: auto;
}

.cardm .card-link,
.card1 .card-link,
.card2 .card-link {
	display: inline-block;
	text-decoration: none;
	color: white;
	background: var(--orange);
	padding: 6px 12px;
	border-radius: 8px;
	transition: 0.2s;
}

.cardm:hover .card-link {
	background: var(--darkorange);
}

.cards::-webkit-scrollbar {
	height: 12px;
}

.cards3::-webkit-scrollbar {
	height: 12px;
}

.cards2::-webkit-scrollbar {
	height: 12px;
}

.cards::-webkit-scrollbar-thumb,
.cards::-webkit-scrollbar-track {
	border-radius: 92px;
}

.cards3::-webkit-scrollbar-thumb,
.cards3::-webkit-scrollbar-track {
	border-radius: 92px;
}

.cards2::-webkit-scrollbar-thumb,
.cards2::-webkit-scrollbar-track {
	border-radius: 92px;
}

.cards::-webkit-scrollbar-thumb {
	background: var(--darkorange);
}

.cards3::-webkit-scrollbar-thumb {
	background: var(--darkorange);
}

.cards2::-webkit-scrollbar-thumb {
	background: var(--darkorange);
}

.cards::-webkit-scrollbar-track {
	background: var(--thumb);
}

.cards3::-webkit-scrollbar-track {
	background: var(--thumb);
}

.cards2::-webkit-scrollbar-track {
	background: var(--thumb);
}


@media (width: 500px) {
	.cards {
		grid-auto-columns: calc(50% - 10px);
		grid-column-gap: 20px;
	}
}

@media (width: 500px) {
	.cards3 {
		grid-auto-columns: calc(50% - 10px);
		grid-column-gap: 10px;
	}
}

@media (width: 500px) {
	.cards2 {
		grid-auto-columns: calc(50% - 10px);
		grid-column-gap: 20px;
	}
}

@media (height: 300px) {
	.cards {
		grid-auto-columns: calc(50% - 10px);
		grid-column-gap: 20px;
	}
}

@media (height: 300px) {
	.cards3 {
		grid-auto-columns: calc(50% - 10px);
		grid-column-gap: 20px;
	}
}

@media (height: 300px) {
	.cards2 {
		grid-auto-columns: calc(50% - 10px);
		grid-column-gap: 20px;
	}
}


@media (width: 700px) {
	.cards {
		grid-auto-columns: calc(calc(100% / 3) - 20px);
		grid-column-gap: 30px;
	}
}

@media (width: 700px) {
	.cards3 {
		grid-auto-columns: calc(calc(100% / 3) - 20px);
		grid-column-gap: 30px;
	}
}

@media (width: 700px) {
	.cards2 {
		grid-auto-columns: calc(calc(100% / 3) - 20px);
		grid-column-gap: 30px;
	}
}

@media (height: 300px) {
	.cards {
		grid-auto-columns: calc(calc(100% / 3) - 20px);
		grid-column-gap: 30px;
	}
}

@media (height: 300px) {
	.cards3 {
		grid-auto-columns: calc(calc(100% / 3) - 20px);
		grid-column-gap: 30px;
	}
}

@media (height: 300px) {
	.cards2 {
		grid-auto-columns: calc(calc(100% / 3) - 20px);
		grid-column-gap: 30px;
	}
}


@media (min-width: 1100px) {
	.cards {
		grid-auto-columns: calc(19.5% - 30px);;
		grid-column-gap: 5px;
	}
}

@media (min-width: 1100px) {
	.cards3 {
		grid-auto-columns: calc(25% - 30px);
		grid-column-gap: 0px;
	}
}

@media (min-width: 1100px) {
	.cards2 {
		grid-auto-columns: calc(21% - 30px);
		grid-column-gap: 1px;
	}
}



/* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
	position: fixed;
	right: 0;
	bottom: 50px;
	display: flex;
	align-items: center;
	padding: 5px;
	z-index: 1;
}

.page-footer a {
	display: flex;
	margin-left: 4px;
}

.list::-webkit-scrollbar {
	-webkit-appearance: scrollbarthumb-vertical;
}

.todaycalender {

	width: 196px;
	height: 45px;
	float: right;
	margin-left: 48%;
	background: #E7F2E7;
	border-radius: 10px;
}

.newcontainerfilter {
	max-width: 1400px;
	padding: 0 15px;
	margin: 0 auto;
	overflow-x: scroll;
	border-style: solid;
	border-color: #DD621D;
	border-radius: 20px 20px 0px 0px;
	height: 500px;

}

.drs-container-not {
	display: flex;
	flex-direction: row !important;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 30px;
	height: 500px;
	overflow-x: scroll;

}


.doctorselect {
	/* padding-left:5px; */
	border: 1px solid #CBCACA;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	margin-right: 100px;
	color: #6e6767;
	box-sizing: border-box;
	margin-left: 2%;
	height: 44px;
	background: #E7F2E7;
	padding: 3px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 15px;
}

.filterClass {
margin-top: 5%;
margin-left: 12%;
	width: 105px;
	height: 32px;
	left: 30px;
	top: 772px;

	
	border-radius: 10px;
}