.food-habits-page .inference-container{
    background: white;
   
    margin: .71rem ;
    border-radius: 0.21rem;
   
    height:60%;
    
}
.food-table{
    box-shadow: 0 5px 10px rgba(13, 197, 197, 0.182) !important;
border-radius: 0px;
}
.inference-container-title{
    color:white;
    font-weight: bold;
    text-transform: capitalize;


}
.span-date {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto 10px;
}
.addH5 svg {
	float: right;
    margin-top: 5px;
}
.addH5 svg.opened {
	transform: rotate(180deg);

}
.food-habits-header{
	height: 70px;
}
.span-date1{
	margin-left:35% ;
}
Accordion.Collapse{
	position: absolute;
  z-index: 1;
}
 .addH5{
	color: rgb(5, 0, 0)!important;
}

.food-habits-sections .card {
   border : 0px;
}

.food-habits-sections .card-header {
   border : 0px;
   padding-top: 12px;
   cursor: pointer;
   background-color: blue;
   width:100%;
}

.svgHeader {
	margin-top: 5px;
    float: right;
}

.inference-container-header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width:95%;
    background-color: grey;
    padding: 8px !important;
}

/* .inference-container-body{
    padding:10px;
} */
.inference-container-body h6{
    display: inline-flex;

}

.inference-container-body p{
    display: inline-flex;
    word-spacing: 10px;
    
}

.inference-container-body p{
    margin-right: 50px;
}

.inference-container-footer{
    width:100%;
  
   padding: 8px !important;
}

.analysis-subsection{
    width: 100%;
}