.invoice-table{
	
	min-width:800px;
	

}

.invoice-row1,.invoice-row2,.invoice-row3{
	border-top:3px solid black;
	border-left:3px solid black;
	border-right:3px solid black;
	width:93%;
	padding:10px;
  background-color: rgba(239, 255, 0, 0.1);
}
.invoice-row4{
	border-bottom:3px solid black;
	border-left:3px solid black;
	border-right:3px solid black;
	width:93%;
	padding:10px;
  background-color: rgba(239, 255, 0, 0.1);

}
.invoice-table .invoice-heading{
	font-weight: bold;
	color:black !important;
}
.caps{
	text-transform: capitalize;
}

.invoice-table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 80%;
  margin:-14;
}

.invoice-table td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  
  color:grey;
}

.invoice-table tr:nth-child(1) {
  background-color: #dddddd;
}
.caps-full{
	text-transform: uppercase;
}
.tenure-content{

  min-height:200px;

}
.end-flex-content{
width:40%;
display: flex;
flex-direction: row;
justify-content:flex-end; 

}

.center-flex-content{
width:60%;
display: flex;
flex-direction: row;
justify-content:center; 
text-align: right;
}
.center-flex-content .flex-col{
  align-self:flex-start !important;
  align-items: center;
}
.end-flex-content .flex-col{
  align-self:flex-start !important;
  align-items: flex-start;
 
}
.invoice-heading-normal{
  font-weight: normal !important;
}
.invoice-header{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: rgba(0, 101, 124, 0.33);
}
@media print { 
	body * {
    visibility: hidden ;
    
  }
   .hide-me{
  	visibility:visible;
  }
  .to-print ,.to-print *{
    visibility: visible;

  }
  .to-print  { 
    	 /* position: absolute; */
    /* left: 0;
    top: 0; */
    margin-top: 100px !important;
    margin-right: 70px !important;
    }
    

}
 