/* *{
  font-family: "Poppins";
} */
.ProfilePagecontainer {
  margin: 27px 30px 27px 0px;
  padding: 25px;
  border: 1px solid #007e85;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 160%;
  /* height: 100%; */
}
.profileBtn {
  border-radius: 30px;
  width: 223px;
  height: 55px;
  background: #007e85;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}
.physiqueCards {
  height: 107px;
  width: 160px;
  margin: 8px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 4px rgba(181, 181, 181, 0.25);
  border-radius: 12px;
 
}
.pInPhysique {
  font-size: 16px;
  font-family: "Mulish" "Poppins";
  font-weight: 700;
  color: #5f5f5f;
  margin: 0;
}
.bInPhysique {
  font-weight: 800;
  font-size: 24px;
  font-family: "Mulish" "Poppins";
}
.graphBtn{
  width: 160px;
height: 45px;
border: 1px solid #C5C5C5;
border-radius: 20px;
box-shadow: "2px 2px 4px rgba(0, 0, 0, 0.2)";
}
.graphBtn:hover{
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);

  box-shadow: 1px 1px 5px;
}
.SaveBtn{
  width: 130px;
  height: 40px;
  background: #007E85;
border: 1px solid #C5C5C5;
border-radius: 10px;
box-shadow: "2px 2px 4px rgba(0, 0, 0, 0.2)";
color: white;
font-weight: 800;
font-family: "Poppins";

}
.textFormFieldGraph {
  height: 50px;
  width: 250px;
  border: 1px solid #007e85;
  border-radius: 8px;
}
.dropDownProfileText{
  font-family: "Poppins";
  font-size: 20px;
  
}
.dropDownProfileContainer{
  border: 1px solid #007e85;
  margin-bottom: 2px;
  padding: 3%;
  cursor: pointer; 

}
.dropDownProfileContainer:hover{
  -ms-transform: scale(1.0);
  /* IE 9 */
  -webkit-transform: scale(1.0);
  /* Safari 3-8 */
  transform: scale(1.0);

  box-shadow: 1px 1px 5px;
  color: black;
}


