.doc-searchbtn {
    color: white;
    background: transparent linear-gradient(281deg, #007C9D 0%, #01C9C9 100%) 0% 0% no-repeat;
    width: 90px;
    height: 28px;
    border-radius: 10px;
    align-self: center;
    font-size: medium;
    font-size: small;
}

.doc-searchbtn:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);

    box-shadow: 1px 1px 10px;
}

.search-in-appointment {

    background: #FAFAFA;
    border: 1px solid #007E85;
    border-radius: 16px;
    height: 130px;
}

.find-doc {
    margin-left: 1%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 46px;
    /* identical to box height, or 177% */

    /* text-align: center; */
    text-transform: capitalize;

    color: #000000;
}

.booking-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* line-height: 41px; */


    color: #23262F;

}

.booking-details-div {

margin-bottom: 2%;
    padding: 1%;
    background: #F0F9FD;
    border-radius: 10.2413px;
}

.first-letter {
    /* margin-top: 13%; */
    padding:1%;
    font-size: 20px;
    border-radius:50%;
    width: 28.27px;
    height: 28.27px;
    text-align: center;
color:white;
    background: linear-gradient(66.15deg, #57B4DE -5.25%, #58B6DD 35.43%, #3B29AB 98.77%);
}
.round-button{
    padding:1%;
    
    border-radius:50%;
    width: 28.27px;
    height: 28.27px;
    text-align: center;
   color:white;
    background: #007E85;
}
.content-appointment{
    
/* height: 23.49px; */

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12.3861px;
/* line-height: 20px; */

/* DMV/Black */
text-transform: capitalize;
color: #23262F;

}
.booking-button{
    float:right;
    background-color: white;
    font-size: small;
    text-decoration: underline;
    color: #007E85;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12.2896px;
    line-height: 25px;
    /* identical to box height, or 200% */
    
   
}
.booking-button:hover{
    
    color: #034245;

}
.each-list-doc{
    /* Rectangle 39895 */


box-sizing: border-box;
padding:3%;
background: #FFFFFF;
border: 1px solid #007E85;
box-shadow: 0px 4px 4px 2px rgba(84, 84, 84, 0.25);
border-radius: 20px;
}
.each-list-doc-selected{
    /* Rectangle 39895 */
box-sizing: border-box;
padding:3%;
background: #FFFFFF;
border: 3px solid #007E85;
box-shadow: 0px 4px 4px 2px rgba(84, 84, 84, 0.25);
border-radius: 20px;
}
.doc-name{
   
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;

/* identical to box height */

text-align: center;
letter-spacing: 0.266977px;
text-transform: capitalize;
color: #222B45;
}
.doc-details{
    
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 12px;
/* identical to box height */
text-align: center;
color: #11234A;

}
.book-appointment-btn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 17px; */
    /* identical to box height */
    margin-left: 6%;
    padding:5%;
    text-align: center;
    letter-spacing: 0.266977px;
    width:100%;
    
    color: #FFFFFF;
background: #007E85;
border-radius: 20px;
}
.book-appointment-btn-n{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 17px; */
    /* identical to box height */
    margin-left: 6%;
    padding:5%;
    text-align: center;
    letter-spacing: 0.266977px;
    width:100%;
    
    color: #FFFFFF;
background: #D8D8D8;
border-radius: 20px;
}
.book-appointment-btn:hover{
    border: 1px solid #404040;
    transform: scale(1.05);
    background: #0e585c;
}
.book-appointment-btn-n:hover{
    border: 1px solid #404040;
    transform: scale(1.05);
    background: #c5c1c1;
}
.day-head{
   
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 21px;
/* identical to box height */
font-weight:bolder ;
text-align: center;
letter-spacing: 0.01em;

color: #000000;

}
.fc .fc-button:not(:disabled) {
    cursor: pointer;
    background-color: white;
    border: none;
    color: black;
    margin-top: 8%;
}
.fc .fc-toolbar-title{
    font-size: medium;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 0em;
    min-height: fit-content;
}
.fc-h-event{
    text-align: center;
    font-size: xx-small;
    border-radius: 50%;
    height: 5px;
    width:5px;
   padding:2%;
   
}