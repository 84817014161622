/*
@File: Drodo Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Top Header Area CSS
** - Navbar Area CSS
** - Search Overlay CSS
** - Main Banner Area CSS
** - About Area CSS
** - Banner Categories Area CSS
** - Categories Area CSS
** - Products Area CSS
** - Products Details Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Woocommerce Widget Area CSS
** - Products Promotions Area CSS
** - Feedback Area CSS
** - Why Choose Us Area CSS
** - Hot Deal Area CSS
** - Brands Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Facility Area CSS
** - Gallery Area CSS
** - History Area CSS
** - Doctors Area CSS
** - Funfacts Area CSS
** - Page Title Area CSS
** - Pagination CSS
** - Widget Sidebar CSS
** - Profile Authentication Area CSS
** - 404 Error Area CSS
** - Coming Soon Area CSS
** - Purchase Guide Area CSS
** - Privacy Policy Area CSS
** - Terms Of Service Area CSS
** - Customer Service Area CSS
** - FAQ Area CSS
** - Tracking Order Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Go Top CSS
** - Modal CSS
*/

/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');

:root {
    --fontFamily: 'Open Sans', sans-serif;
    --fontFamily2: 'Hind Siliguri', sans-serif;
    --mainColor: #2a96ff;
    --optionalColor: #696969;
    --whiteColor: #ffffff;
    --blackColor: #111111;
    --fontSize: 15px;
    --transition: .5s;
}
body {
    padding: 0;
    margin: 0;
    font: {
        size: var(--fontSize);
        family: var(--fontFamily);
    };
}
.web-ordering .h1 .h2 .h3 .h4 .h5 .h6 h1 h2 h3 h4 h5 h6 {
    font: {
        family: var(--fontFamily2);
        weight: 700;
    };
}
a {
    color: var(--blackColor);
    transition: var(--transition);
    text-decoration: none;
    outline: 0 !important;

    &:hover {
        color: var(--mainColor);
        text-decoration: none;
    }
}
:focus {
    outline: 0 !important;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 100%;
    height: auto;
}
p {
    color: var(--optionalColor);
    font-size: var(--fontSize);
    margin-bottom: 15px;
    line-height: 1.8;

    &:last-child {
        margin-bottom: 0;
    }
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.ptb-40 {
    padding: {
        top: 40px;
        bottom: 40px;
    };
}
.pt-40 {
    padding-top: 40px;
}
.pb-40 {
    padding-bottom: 40px;
}
.container {
    max-width: 1230px;
}
.bg-f7f8fa {
    background-color: #f7f8fa;
}
/*default-btn*/
.default-btn {
    display: inline-block;
    position: relative;
    z-index: 1;
    border: none;
    background-color: var(--mainColor);
    color: var(--whiteColor);
    border-radius: 5px;
    box-shadow: unset;
    transition: var(--transition);
    text-align: center;
    padding: {
        top: 11px;
        bottom: 11px;
        left: 52px;
        right: 25px;
    };
    font: {
        size: var(--fontSize);
        weight: 600;
    };
    i {
        position: absolute;
        left: 25px;
        top: 13px;
        font-size: 19px;
        color: var(--whiteColor);
        line-height: 1;
        transition: var(--transition);
    }
    &:hover {
        background-color: var(--whiteColor);
        color: var(--blackColor);
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

        i {
            color: var(--mainColor);
        }
    }
}
/*section-title*/
.section-title {
    margin-bottom: 40px;
    position: relative;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 8px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100px;
        background-color: var(--mainColor);
        height: 1px;
    }
    h2 {
        margin-bottom: 0;
        font-size: 30px;
    }
}
/*form-control*/
.form-control {
    height: 50px;
    color: var(--blackColor);
    box-shadow: unset !important;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    transition: var(--transition);
    border-radius: 3px;
    padding: 1px 0 0 15px;
    font: {
        size: 15px;
        weight: 500;
    };
    &::placeholder {
        color: #7e7e7e;
        transition: var(--transition);
    }
    &:focus {
        border-color: var(--mainColor);
        background-color: transparent;

        &::placeholder {
            color: transparent;
        }
    }
}
textarea.form-control {
    height: auto;
    padding-top: 15px;
}

/*================================================
Top Header Area CSS
=================================================*/
.top-header {
    background-color: #f7f8fa;
    padding: {
        top: 12px;
        bottom: 12px;
    };
}
.top-header-contact-info {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
        display: inline-block;
        margin-right: 15px;
        position: relative;
        padding-left: 24px;

        a {
            font-weight: 600;
            display: inline-block;
        }
        i {
            position: absolute;
            left: 0;
            top: 2.5px;
            color: var(--mainColor);
            font-size: 17px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.top-header-menu {
    text-align: right;
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
        display: inline-block;
        margin-left: 18px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: -14px;
            top: 4.5px;
            width: 1.4px;
            height: 14px;
            background-color: #dfdfdf;
        }
        &:first-child {
            margin-left: 0;

            &::before {
                display: none;
            }
        }
        &:nth-child(2) {
            &::before {
                top: 6.5px;
            }
        }
        .language-switcher {
            position: relative;

            .dropdown-toggle {
                padding: 0;
                border: none;
                background-color: transparent;
                color: var(--blackColor);
                font: {
                    weight: 600;
                    size: 14.5px;
                };
                &::after {
                    display: none;
                }
                img {
                    width: 35px;
                    border-radius: 3px;
                    border: 2px solid var(--whiteColor);
                    position: relative;
                    top: -2px;
                }
                span {
                    display: inline-block;
                    margin-left: 5px;

                    i {
                        position: relative;
                        font-size: 20px;
                        top: 4px;
                        margin-left: -3px;
                    }
                }
            }
            .dropdown-menu {
                padding: 15px;
                float: unset;
                border: none;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                border-radius: 0;
                display: block;
                margin-top: 12px;
                opacity: 0;
                visibility: visible;
                transition: var(--transition);
                transform: scaleX(0);

                .dropdown-item {
                    padding: 0;
                    color: var(--blackColor);
                    margin-bottom: 10px;
                    font: {
                        weight: 600;
                        size: 14.5px;
                    };
                    &::before {
                        display: none;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    img {
                        width: 40px;
                        border-radius: 5px;
                        border: 3px solid var(--whiteColor);
                    }
                    span {
                        display: inline-block;
                        margin-left: 7px;
                    }
                    &:hover, &:focus {
                        background-color: transparent !important;
                    }
                    &:active, &.active {
                        color: var(--blackColor);
                        background-color: transparent;
                    }
                }
                &.show {
                    opacity: 1;
                    visibility: visible;
                    transform: scaleX(1);
                }
            }
        }
        .currency-switcher {
            position: relative;

            .dropdown-toggle {
                padding: 0;
                border: none;
                background-color: transparent;
                color: var(--blackColor);
                font: {
                    weight: 600;
                    size: 14.5px;
                };
                &::after {
                    display: none;
                }
                span {
                    display: inline-block;

                    i {
                        position: relative;
                        font-size: 20px;
                        top: 4px;
                        margin-left: -3px;
                    }
                }
            }
            .dropdown-menu {
                padding: 15px;
                float: unset;
                border: none;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                border-radius: 0;
                display: block;
                margin-top: 12px;
                opacity: 0;
                visibility: visible;
                transition: var(--transition);
                transform: scaleX(0);

                .dropdown-item {
                    padding: 0;
                    color: var(--blackColor);
                    margin-bottom: 12px;
                    font: {
                        weight: 600;
                        size: 14.5px;
                    };
                    &::before {
                        display: none;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        display: inline-block;
                    }
                    &:hover, &:focus {
                        background-color: transparent !important;
                    }
                    &:active, &.active {
                        color: var(--blackColor);
                        background-color: transparent;
                    }
                }
                &.show {
                    opacity: 1;
                    visibility: visible;
                    transform: scaleX(1);
                }
            }
        }
        a {
            font-weight: 600;
        }
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.drodo-responsive-nav {
    display: none;
}
.drodo-nav {
    background-color: var(--whiteColor);

    .container-fluid {
        padding: {
            left: 40px;
            // right: 40px;
        };
    }
    .navbar {
        position: inherit;
        padding: {
            left: 0;
            right: 0;
        };      
        .navbar-brand {
            font-size: inherit;
            width:150px;
            line-height: 1;
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            };
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: {
                left: auto;
                right: auto;
            };
            .nav-item {
                position: relative;

                a {
                    color: var(--blackColor);
                    font: {
                        size: 16.5px;
                        weight: 600;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                        top: 17px;
                        bottom: 20px;
                    };
                    margin: {
                        left: 13px;
                        right: 13px;
                    };
                    i {
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin: {
                            left: -3px;
                            right: -3px;
                        };
                    }
                    &:hover, &:focus, &.active {
                        color: var(--mainColor);
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &.active {
                    a {
                        color: var(--mainColor);
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: var(--whiteColor);
                    position: absolute;
                    border: none;
                    top: 67px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.2s ease-in-out;
                    padding: 0;
                    transform: scaleX(0);

                    li {
                        a {
                            padding: 13px 20px 11px;
                            margin: 0;
                            position: relative;
                            display: block;
                            color: #666666;
                            border-bottom: 1px dashed #e5e5e5;
                            font: {
                                size: 15px;
                                weight: 600;
                            };
                            i {
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                font-size: 20px;
                                transform: translateY(-50%);
                                right: 10px;
                            }
                            &:hover, &:focus, &.active {
                                color: var(--mainColor);
                            }
                        }
                        .dropdown-menu {
                            left: -250px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;
                            transform: scaleY(0);
                            
                            li {
                                a {
                                    color: #515050;
                                    
                                    &:hover, &:focus, &.active {
                                        color: var(--mainColor);
                                    }
                                }
                                .dropdown-menu {
                                    left: 250px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
                                    transform: scaleY(0);
        
                                    li {
                                        a {
                                            color: #515050;
        
                                            &:hover, &:focus, &.active {
                                                color: var(--mainColor);
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;
                                            transform: scaleY(0);
                
                                            li {
                                                a {
                                                    color: #515050;
                
                                                    &:hover, &:focus, &.active {
                                                        color: var(--mainColor);
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: 250px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;
                                                    transform: scaleY(0);
                        
                                                    li {
                                                        a {
                                                            color: #515050;
                
                                                            &:hover, &:focus, &.active {
                                                                color: var(--mainColor);
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;
                                                            transform: scaleY(0);
                                
                                                            li {
                                                                a {
                                                                    color: #515050;
                
                                                                    &:hover, &:focus, &.active {
                                                                        color: var(--mainColor);
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: 250px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                                                    transform: scaleY(0);
                                        
                                                                    li {
                                                                        a {
                                                                            color: #515050;
                
                                                                            &:hover, &:focus, &.active {
                                                                                color: var(--mainColor);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: var(--mainColor);
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        transform: scaleY(1);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: var(--mainColor);
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                transform: scaleY(1);
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: var(--mainColor);
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        transform: scaleY(1);
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: var(--mainColor);
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                transform: scaleY(1);
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: var(--mainColor);
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        transform: scaleY(1);
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: var(--mainColor);
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                transform: scaleY(1);
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        transform: scaleX(1);
                    }
                }
                &.megamenu {
                    position: inherit;

                    .container {
                        display: block;
                        flex-wrap: unset;
                        align-items: unset;
                        justify-content: unset;
                    }
                    .dropdown-menu {
                        width: 100%;
                        margin-top: 8px;
                        position: absolute;
                        top: auto;
                        left: 0;
                        padding: 30px 0;
                        transform: unset !important;

                        .submenu-title {
                            color: var(--blackColor);
                            position: relative;
                            border-bottom: 1px solid #eeeeee;
                            padding-bottom: 8px;
                            text-transform: uppercase;
                            margin: {
                                bottom: 20px;
                                top: 25px;
                            };
                            font: {
                                size: 15.5px;
                                weight: 600;
                            };
                            &::before {
                                width: 30px;
                                height: 1px;
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: -1px;
                                background-color: var(--mainColor);
                            }
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                        a {
                            border-bottom: none !important;
                        }
                        .megamenu-submenu {
                            li {
                                a {
                                    margin-top: 14px;
                                    color: #666666;
                                    padding: 0;
                                    font: {
                                        weight: 600;
                                    };
                                    &:hover, &.active {
                                        color: var(--mainColor);
                                    }
                                }
                            }
                            .aside-trending-products {
                                overflow: hidden;
                                position: relative;
                                text-align: center;

                                img {
                                    transition: var(--transition);
                                }
                                .category {
                                    position: absolute;
                                    left: 0;
                                    transition: var(--transition);
                                    right: 0;
                                    bottom: 0;
                                    background-color: var(--whiteColor);
                                    padding: 15px;
                                    margin: {
                                        left: 10px;
                                        right: 10px;
                                    };
                                    h4 {
                                        text-transform: uppercase;
                                        margin-bottom: 0;
                                        position: relative;
                                        z-index: 1;
                                        font: {
                                            size: var(--fontSize);
                                            weight: 600;
                                        };
                                    }
                                }
                                .link-btn {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 3;
                                }
                                &:not(:first-child) {
                                    margin-top: 15px;
                                }
                                &:hover {
                                    img {
                                        transform: scale(1.3);
                                    }
                                    .category {
                                        bottom: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .others-option {
            .option-item {
                display: inline-block;
                margin-left: 15px;
                position: relative;
                z-index: 3;

                &:first-child {
                    margin-left: 0;
                }
                .search-btn-box {
                    position: relative;
                    top: 1px;
                    
                    .search-btn {
                        font-size: 25px;
                        cursor: pointer;
                        color: var(--blackColor);
                        transition: var(--transition);

                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                }
                .cart-btn {
                    font-size: 25px;
                    position: relative;
                    line-height: 1;

                    a {
                        display: inline-block;
                        position: relative;

                        span {
                            position: absolute;
                            right: -4px;
                            top: -4px;
                            width: 14px;
                            height: 14px;
                            text-align: center;
                            line-height: 13.9px;
                            border-radius: 50%;
                            color: var(--whiteColor);
                            background-color: red;
                            font: {
                                size: 10px;
                                weight: 600;
                            };
                        }
                    }
                }
                .wishlist-btn {
                    font-size: 25px;
                    position: relative;
                    line-height: 1;
                    top: 1px;

                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
.navbar-area {
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: var(--whiteColor) !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding: {
            top: 0;
            bottom: 0;
        };
    }
}
// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {

    .drodo-responsive-nav {
        display: block;
        
        .drodo-responsive-menu {
            position: relative;

            &.mean-container {
                .mean-nav {
                    margin-top: 51px;

                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    height: 306px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);

                    .nav-item {
                        &.megamenu {
                            .dropdown-menu {
                                .nav-item {
                                    overflow: hidden;

                                    .container {
                                        padding: {
                                            left: 0;
                                            right: 0;
                                        };
                                    }
                                    .row {
                                        display: block;
                                        flex-wrap: unset;
                                        margin: {
                                            left: 0;
                                            right: 0;
                                            top: -20px;
                                        };
                                        .col {
                                            flex-basis: unset;
                                            flex-grow: unset;
                                            max-width: 100%;
                                            padding: {
                                                left: 0;
                                                right: 0;
                                                top: 20px;
                                            };
                                            &:last-child {
                                                margin-bottom: 15px;
                                            }
                                        }
                                    }
                                    a {
                                        width: 100%;
                                        float: unset;
                                        display: block;
                                    }
                                }
                                .submenu-title {
                                    color: var(--blackColor);
                                    position: relative;
                                    border-bottom: 1px solid #eeeeee;
                                    padding-bottom: 8px;
                                    text-transform: uppercase;
                                    margin: {
                                        bottom: 5px;
                                        top: 25px;
                                        left: 15px;
                                        right: 15px;
                                    };
                                    font: {
                                        size: 13.5px;
                                        weight: 600;
                                    };
                                    &::before {
                                        width: 30px;
                                        height: 1px;
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        bottom: -1px;
                                        background-color: var(--mainColor);
                                    }
                                    &:first-child {
                                        margin-top: 5px;
                                    }
                                }
                                a {
                                    border-bottom: none !important;
                                }
                                .mean-expand {
                                    display: none !important;
                                }
                                .megamenu-submenu {
                                    width: auto;
                                    overflow: hidden;
                                    display: block !important;
                                    padding: {
                                        left: 15px;
                                        right: 15px;
                                    };
                                    li {
                                        a {
                                            border: none;
                                            margin-top: 15px;
                                            color: #666666;
                                            padding: 0;
                                            font: {
                                                weight: 400;
                                            };
                                            &:hover, &.active {
                                                color: var(--mainColor);
                                            }
                                        }
                                    }
                                    .aside-trending-products {
                                        overflow: hidden;
                                        position: relative;
                                        text-align: center;
        
                                        img {
                                            transition: var(--transition);
                                        }
                                        .category {
                                            position: absolute;
                                            left: 0;
                                            transition: var(--transition);
                                            right: 0;
                                            bottom: 0;
                                            background-color: var(--whiteColor);
                                            padding: 15px;
                                            margin: {
                                                left: 10px;
                                                right: 10px;
                                            };
                                            h4 {
                                                text-transform: uppercase;
                                                margin-bottom: 0;
                                                position: relative;
                                                z-index: 1;
                                                font: {
                                                    size: var(--fontSize);
                                                    weight: 600;
                                                };
                                            }
                                        }
                                        .link-btn {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            z-index: 3;
                                        }
                                        &:not(:first-child) {
                                            margin-top: 15px;
                                        }
                                        &:hover {
                                            img {
                                                transform: scale(1.3);
                                            }
                                            .category {
                                                bottom: 10px;
                                            }
                                        }
                                    }
                                }
                                .brand-slides {
                                    margin-top: 0;
                                    border: {
                                        top: none;
                                        bottom: none;
                                        left: none;
                                    };
                                    .brand-item a {
                                        display: block !important;
                                        padding: 20px !important;
                                        width: auto !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .others-option {
                    position: absolute;
                    right: 50px;
                    top: 8px;

                    .option-item {
                        display: inline-block;
                        margin-left: 12px;
                        position: relative;
                        z-index: 3;
        
                        &:first-child {
                            margin-left: 0;
                        }
                        .search-btn-box {
                            position: relative;
                            top: 2px;
                            
                            .search-btn {
                                font-size: 20px;
                                cursor: pointer;
                                color: var(--blackColor);
                                transition: var(--transition);
        
                                &:hover {
                                    color: var(--mainColor);
                                }
                            }
                        }
                        .cart-btn {
                            font-size: 20px;
                            position: relative;
                            line-height: 1;
        
                            a {
                                display: inline-block;
                                position: relative;
        
                                span {
                                    position: absolute;
                                    right: -3px;
                                    top: -3px;
                                    width: 12px;
                                    height: 12px;
                                    text-align: center;
                                    line-height: 11.9px;
                                    border-radius: 50%;
                                    color: var(--whiteColor);
                                    background-color: red;
                                    font: {
                                        size: 9px;
                                        weight: 600;
                                    };
                                }
                            }
                        }
                        .wishlist-btn {
                            font-size: 20px;
                            position: relative;
                            line-height: 1;
                            margin-right: -2px;
                            top: 1px;
        
                            a {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: var(--blackColor);

                    span {
                        background: var(--blackColor);
                    }
                }
            }
        }
        .logo {
            position: relative;
            width: 50%;
            z-index: 999;
        }
    }
    .drodo-nav {
        display: none;
    }
    .navbar-area {
        padding: {
            top: 8px;
            bottom: 8px;
        };
        &.is-sticky {
            // padding: {
            //     top: 15px;
            //     bottom: 15px;
            // };
        }
    }

}

/*================================================
Search Overlay CSS
=================================================*/
.search-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    .search-overlay-layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(100%);

        &:nth-child(1) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.3s ease-in-out 0s;
        }
        &:nth-child(2) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: all 0.3s ease-in-out 0.3s;
        }
        &:nth-child(3) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            transition: all 0.9s ease-in-out 0.6s;
        }
    }
    .search-overlay-close {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 50px;
        z-index: 2;
        text-align: center;
        cursor: pointer;
        padding: 10px;
        transition: all 0.9s ease-in-out 1.5s;
        opacity: 0;
        visibility: hidden;

        .search-overlay-close-line {
            width: 100%;
            height: 3px;
            float: left;
            margin-bottom: 5px;
            background-color: var(--whiteColor);
            transition: all 500ms ease;

            &:nth-child(1) {
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                margin-top: -7px;
                transform: rotate(-45deg);
            }
        }
        &:hover {
            .search-overlay-close-line {
                background: var(--mainColor);
                transform: rotate(180deg);
            }
        }
    }
    .search-overlay-form {
        transition: all 0.9s ease-in-out 1.4s;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 2;
        max-width: 500px;
        width: 500px;

        form {
            position: relative;

            .input-search {
                display: block;
                width: 100%;
                height: 60px;
                border: none;
                border-radius: 30px;
                color: var(--blackColor);
                padding: 0 0 0 25px;
                
                &::placeholder {
                    transition: var(--transition);
                    letter-spacing: .5px;
                    color: var(--blackColor);
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                position: absolute;
                right: 5px;
                top: 5px;
                width: 50px;
                color: var(--whiteColor);
                height: 50px;
                border-radius: 50%;
                background-color: var(--mainColor);
                transition: var(--transition);
                border: none;
                font-size: 25px;
                line-height: 47px;

                &:hover {
                    background-color: var(--blackColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    &.search-overlay-active {
        &.search-overlay {
            opacity: 1;
            visibility: visible;
    
            .search-overlay-layer {
                transform: translateX(0);
            }
            .search-overlay-close {
                opacity: 1;
                visibility: visible;
            }
            .search-overlay-form {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.main-banner-content {
    .sub-title {
        display: block;
        font-weight: 600;
        margin-bottom: 12px;
        color: var(--mainColor);
    }
    h1 {
        margin-bottom: 12px;
        font-size: 55px;
        line-height: 1.1;

        span {
            color: var(--mainColor);
        }
    }
    p {
        font-size: 16px;
    }
    .default-btn {
        margin-top: 10px;
    }
}
.single-banner-item {
    background-color: #ebf7fa;
    height: 620px;
}
.banner-content {
    .sub-title {
        display: block;
        font-weight: 600;
        margin-bottom: 12px;
        color: var(--mainColor);
    }
    h1 {
        margin-bottom: 12px;
        font-size: 64px;
        line-height: 1.1;

        span {
            color: var(--mainColor);
        }
    }
    p {
        font-size: 16px;
    }
    .btn-box {
        margin-top: 30px;

        .price {
            display: block;
            color: var(--mainColor);
            margin-left: 25px;
            font: {
                size: 20px;
                weight: 600;
            };
        }
    }
}
.banner-image {
    text-align: center;

    img {
        width: auto !important;
        display: inline-block !important;
    }
}



.owl-item {
    &.active {
        .banner-content {
            .sub-title {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.3s;
                };
            }
            h1 {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.7s;
                };
            }
            p {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.9s;
                };
            }
            .btn-box {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 1.1s;
                };
            }
        }
        .banner-image {
            img {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInRight;
                    delay: 0.9s;
                };
            }
        }
    }
}
.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
                margin: 0;
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                background-color: #dce9ec;
                transition: var(--transition);
                color: var(--blackColor);
                border-radius: 50%;
                font-size: 25px;
                padding-right: 5px !important;

                &.owl-next {
                    left: auto;
                    right: 20px;
                    padding: {
                        left: 5px !important;
                        right: 0 !important;
                    };
                }
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
}
.home-wrapper-area {
    background-color: #080e32;
    position: relative;
    z-index: 1;
    padding: {
        top: 95px;
        bottom: 95px;
    };
}
.wrapper-content {
    .sub-title {
        display: block;
        margin-bottom: 15px;
        color: var(--mainColor);
        font: {
            size: 15.5px;
            weight: 700;
        };
    }
    h1 {
        margin-bottom: 20px;
        font-size: 50px;
        color: var(--whiteColor);
        line-height: 1.3;
    }
    p {
        color: var(--whiteColor);
        opacity: 0.85;
        max-width: 520px;
    }
    .default-btn {
        margin-top: 15px;

        &:hover {
            box-shadow: unset !important;
        }
    }
}
.owl-item {
    &.active {
        .wrapper-content {
            .sub-title {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.3s;
                };
            }
            h1 {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.7s;
                };
            }
            p {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.9s;
                };
            }
            .default-btn {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 1.1s;
                };
            }
        }
        .wrapper-image {
            img {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInRight;
                    delay: 0.7s;
                };
            }
        }
    }
}
.home-slides-two {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-left: -90px;
            bottom: 0;

            [class*=owl-] {
                background-color: transparent;
                border: 1px solid #9b9dac;
                margin: 0 7px;
                transition: var(--transition);
                border-radius: 50%;
                width: 40px;
                height: 40px;
                color: #9b9dac;
                font-size: 22px;
                text-align: center;
                line-height: 40px;
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 1.3s;
                };
                &:hover {
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
        .owl-dots {
            position: absolute;
            left: 0;
            bottom: 0;
            margin-top: 0;
            animation: {
                duration: 1s;
                fill-mode: both;
                name: fadeInRight;
                delay: 1.3s;
            };
            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 8px 0 0;
                    background: transparent;
                    border: 1px solid var(--whiteColor);
                    transition: var(--transition);
                    border-radius: 50%;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: var(--mainColor);
                        border-radius: 50%;
                        margin: 2px;
                        opacity: 0;
                        transition: var(--transition);
                        visibility: hidden;
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: var(--mainColor);

                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}
.banner-area {
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 84px;
        bottom: 124px;
        left: 70px;
        right: 70px;
    };
    &.bg-1 {
        background-image: url(../../assets/img/main-banner1.jpg);
    }
    &.bg-2 {
        background-image: url(../../assets/img/main-banner2.jpg);
    }
    .banner-content {
        max-width: 600px;

        p {
            max-width: 350px;
        }
    }
}
.home-slides-three {
    &.owl-theme {
        .owl-nav.disabled+.owl-dots {
            text-align: left;
            margin-top: 0;
        }
        .owl-dots {
            position: absolute;
            left: 70px;
            bottom: 20px;

            .owl-dot {
                margin: {
                    left: 3px;
                    right: 3px;
                };
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0;
                    border: 1px solid var(--mainColor);
                    background: transparent;
                    transition: var(--transition);
                    border-radius: 50%;
                    position: relative;
    
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: 3px;
                        background-color: var(--mainColor);
                        transition: var(--transition);
                        border-radius: 50%;
                        opacity: 0;
                        visibility: hidden;
                    }
                }
                &:hover, &.active {
                    span {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}
.megamenu-container {
    position: relative;

    .megamenu-title {
        background-color: var(--mainColor);
        text-transform: uppercase;
        color: var(--whiteColor);
        cursor: pointer;
        font: {
            size: 16px;
            weight: 700;
        };
        padding: {
            left: 20px;
            right: 20px;
            top: 12px;
            bottom: 12px;
        };
    }
    .megamenu-category {
        border: 1px solid #eeeeee;
        border-top: none;
        margin-top: 5px;

        .nav {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            display: block !important;
            flex-wrap: unset;

            .nav-item {
                position: relative;
                
                .nav-link {
                    background-color: #f5f5f5;
                    border-top: 1px solid #eeeeee;
                    color: var(--blackColor);
                    position: relative;
                    font: {
                        size: 15px;
                        weight: 200;
                    };
                    padding: {
                        left: 20px;
                        right: 25px;
                        top: 12px;
                        bottom: 12px;
                    };
                    i {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:hover {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                        border-color: var(--mainColor);
                    }
                }
                .dropdown-menu {
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    display: block !important;
                    float: unset;
                    background-color: var(--whiteColor);
                    width: 250px;
                    transition: all 0.2s ease-in-out;
                    border: none;
                    left: 100%;
                    top: 0;
                    margin: 15px 0 0;
                    border-radius: 0;
                    padding: 0;

                    .nav-item {
                        .nav-link {
                            background-color: var(--whiteColor);

                            &:hover {
                                background-color: var(--mainColor);
                            }
                        }
                    }
                    &.megamenu {
                        width: 650px;
                        padding: 30px;

                        h3 {
                            text-transform: uppercase;
                            margin-bottom: 15px;
                            font: {
                                size: 16px;
                                weight: 700;
                            };
                        }
                        .megamenu-nav {
                            padding-left: 0;
                            margin-bottom: 0;
                            list-style-type: none;

                            li {
                                margin-bottom: 11px;

                                a {
                                    display: inline-block;
                                    font-size: 15px;
                                    color: var(--optionalColor);

                                    &:hover {
                                        color: var(--mainColor);
                                    }
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        margin-top: 0;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes rotatemetwo {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

/*================================================
About Area CSS
=================================================*/
.about-content {
    padding-right: 15px;

    .sub-title {
        display: block;
        font-weight: 600;
        margin-bottom: 12px;
        color: var(--mainColor);
    }
    h2 {
        margin-bottom: 15px;
        font-size: 30px;
    }
    .quotes-box {
        margin-top: 20px;

        span {
            display: block;
            color: var(--optionalColor);
            margin-bottom: 15px;
        }
        p {
            text-align: center;
            color: var(--blackColor);
            font-weight: 600;
            position: relative;
            z-index: 1;
            padding: {
                top: 30px;
                bottom: 25px;
            };
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                content: "\f111";
                color: var(--optionalColor);
                opacity: 0.5;
                line-height: 1;
                font: {
                    family: Flaticon;
                    size: 25px;
                };
            }
            &::after {
                position: absolute;
                right: 0;
                bottom: 0;
                content: "\f111";
                color: var(--optionalColor);
                opacity: 0.5;
                line-height: 1;
                transform: rotate(180deg);
                font: {
                    family: Flaticon;
                    size: 25px;
                };
            }
        }
    }
}
.about-image {
    text-align: center;
    position: relative;
    z-index: 1;
    padding: {
        left: 30px;
        top: 30px;
        bottom: 30px;
    };
    .shape {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        img {
            &:nth-child(1) {
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: -1;
            }
            &:nth-child(2) {
                position: absolute;
                right: 0;
                top: 0;
                z-index: -1;
            }
        }
    }
}

/*================================================
Banner Categories Area CSS
=================================================*/
.single-banner-categories-box {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    img {
        transition: var(--transition);
    }
    .content {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 25px;

        .sub-title {
            display: block;
            margin-bottom: 10px;
            color: var(--optionalColor);
            font: {
                weight: 600;
            };
        }
        h3 {
            font-size: 25px;
            margin-bottom: 0;
        }
        .btn-box {
            margin-top: 15px;
    
            .default-btn {
                font-size: 15px;
            }
            .price {
                display: block;
                color: var(--mainColor);
                margin-left: 15px;
                font: {
                    size: 20px;
                    weight: 600;
                };
            }
        }
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}
.categories-box {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;

    img {
        transition: var(--transition);
    }
    .link-btn {
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2;
        left: 0;
        top: 0;
        position: absolute;
    }
    .content {
        position: absolute;
        top: 20px;
        left: 20px;
        transition: var(--transition);
        background-color: var(--whiteColor);
        box-shadow: 1px 1px 3px rgba(136, 136, 136, .19);
        padding: {
            top: 10px;
            bottom: 8px;
            left: 25px;
            right: 25px;
        };
        h3 {
            margin-bottom: 0;
            transition: var(--transition);
            font: {
                size: 20px;
                style: italic;
            };
        }
    }
    &:hover {
        img {
            transform: scale(1.07);
        }
        .content {
            background-color: var(--mainColor);

            h3 {
                color: var(--whiteColor);
            }
        }
    }
}

/*================================================
Categories Area CSS
=================================================*/
.single-categories-box {
    margin-bottom: 30px;
    position: relative;
    border-radius: 3px;
    text-align: center;
    background-color: var(--whiteColor);
    box-shadow: 0 0 7px rgba(0, 0, 0, .10);
    padding: 30px 15px;

    img {
        width: auto !important;
        display: inline-block !important;
        transition: var(--transition);
    }
    h3 {
        font-size: 18px;
        margin: {
            bottom: 0;
            top: 25px;
        };
    }
    .link-btn {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        z-index: 2;
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}

/*================================================
Products Area CSS
=================================================*/
.single-products-box {
    margin-bottom: 30px;

    .image {
        position: relative;
        overflow: hidden;

        img {
            transition: var(--transition);
        }
        .new {
            position: absolute;
            left: 12px;
            top: 12px;
            background-color: var(--mainColor);
            color: var(--whiteColor);
            padding: 1px 12px;
            z-index: 2;
            font: {
                size: 14px;
                weight: 600;
            };
        }
        .sale {
            position: absolute;
            z-index: 2;
            right: 12px;
            top: 12px;
            background-color: red;
            color: var(--whiteColor);
            padding: 1px 12px;
            font: {
                size: 14px;
                weight: 600;
            };
        }
        .buttons-list {
            position: absolute;
            text-align: center;
            z-index: 1;
            left: 0;
            right: 0;
            bottom: 20px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    opacity: 0;
                    visibility: hidden;
                    display: inline-block;
                    transform: translateX(30px);
                    transition: all 0.3s ease-in-out 0s;
                    margin: {
                        left: 3px;
                        right: 3px;
                    };
                    a {
                        display: inline-block;
                        font-size: 22px;
                        line-height: 1;
                        color: var(--blackColor);
                        position: relative;
                        width: 40px;
                        border-radius: 50%;
                        line-height: 43px;
                        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                        background-color: var(--whiteColor);
                        height: 40px;

                        &:hover {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                        }
                        .tooltip-label {
                            right: 50%;
                            top: -15px;
                            transform: translateX(50%);
                            position: absolute;
                            padding: 0 10px;
                            line-height: 28px;
                            font-size: 12px;
                            letter-spacing: .3px;
                            visibility: hidden;
                            white-space: nowrap;
                            opacity: 0;
                            color: transparent;
                            background-color: transparent;
                            transition: all .2s ease-in-out;
                            background-color: var(--blackColor);
                            color: var(--whiteColor);

                            &::before {
                                content: "\e9ff";
                                color: var(--blackColor);
                                left: 50%;
                                position: absolute;
                                transform: translateX(-50%) rotate(90deg);
                                transition: all .2s ease-in-out;
                                bottom: -16px;
                                font: {
                                    family: 'boxicons'!important;
                                    weight: normal;
                                    style: normal;
                                    size: 20px;
                                };
                            }
                        }
                        &:hover {
                            .tooltip-label {
                                visibility: visible;
                                opacity: 1;
                                top: -45px;
                            }
                        }
                    }
                    &:nth-child(1) {
                        transition-delay: 0.1s;
                    }
                    &:nth-child(2) {
                        transition-delay: 0.2s;
                    }
                    &:nth-child(3) {
                        transition-delay: 0.3s;
                    }
                }
            }
        }
    }
    .content {
        margin-top: 25px;

        h3 {
            margin-bottom: 8px;
            font-size: 20px;
        }
        .price {
            span {
                color: var(--mainColor);
                font: {
                    size: 14.5px;
                    weight: 600;
                };
                &.old-price {
                    color: var(--optionalColor);
                    text-decoration: line-through;
                }
            }
        }
    }
    &:hover {
        .image {
            img {
                transform: scale(1.05);
            }
            .buttons-list {
                ul {
                    li {
                        transform: translateX(0);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.products-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            right: 0;
            top: -82px;

            [class*=owl-] {
                margin: 0;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                background-color: transparent;
                color: var(--blackColor);
                transition: var(--transition);
                text-align: center;
                line-height: 31px;
                border: 1px solid #ebebeb;
                font-size: 20px;
                padding-right: 2px !important;

                &.owl-next {
                    margin-left: 8px;
                    padding: {
                        right: 0 !important;
                        left: 2px !important;
                    };
                }
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                }
            }
        }
    }
}
.drodo-grid-sorting {
    margin-bottom: 40px;

    .result-count {
        p {
            font: {
                weight: 500;
            };
            .count {
                font-weight: 700;
                color: var(--blackColor);
            }
        }
        .sub-title {
            text-transform: uppercase;
            font-weight: 700;
        }
    }
    .ordering {
        text-align: right;

        label {
            display: inline-block;
            margin-bottom: 0;
            color: var(--optionalColor);
            margin-right: 5px;
            font: {
                weight: 600;
            };
        }
        .nice-select {
            display: inline-block;
            width: 215px;
            float: unset;
            background: #f8f8f8;
            border-color: #eeeeee;
            color: var(--blackColor);
            transition: var(--transition);
            padding: 0 0 0 12px;
            height: 45px;
            line-height: 45px;
            font: {
                size: 15px;
                weight: 600;
            };
            .list {
                background-color: var(--whiteColor);
                border-radius: 5px;
                box-shadow: 0 10px 30px rgba(0,0,0,.2);
                list-style-type: none;
                border: none;
                width: 100%;
                margin: {
                    bottom: 0;
                    top: 0;
                };
                padding: {
                    left: 0;
                    top: 10px;
                    bottom: 10px;
                };
                .option {
                    line-height: 38px;
                    min-height: 38px;
                    color: var(--blackColor);
                    position: relative;
                    transition: var(--transition);
                    padding: {
                        left: 32px;
                        right: 25px;
                    };
                    font: {
                        size: 15px;
                        weight: 600;
                    };
                    &:hover {
                        background-color: var(--mainColor) !important;
                        color: var(--whiteColor);

                        &::before {
                            color: var(--whiteColor);
                        }
                    }
                    &.focus, &.selected.focus {
                        background-color: transparent !important;
                        color: var(--blackColor);
                    }
                    &::before {
                        content: "\ea0f";
                        position: absolute;
                        left: 10px;
                        top: -3px;
                        opacity: 0;
                        visibility: hidden;
                        transition: var(--transition);
                        color: var(--mainColor);
                        font: {
                            family: 'boxicons';
                            size: 20px;
                        };
                    }
                    &.selected {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                        &:hover {
                            background-color: var(--mainColor) !important;
                            color: var(--whiteColor);

                            &::before {
                                color: var(--whiteColor);
                            }
                        }
                    }
                }
            }
            &:after {
                border-color: var(--blackColor);
                height: 8px;
                width: 8px;
                margin-top: -5px;
            }
            &:hover {
                border-color: var(--mainColor);
                background-color: transparent;
            }
        }
    }
}
.products-collections-area {
    .container-fluid {
        width: 90%;
    }
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
    img {
        width: 100%;
    }
    .products-details-image-slides {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
    }
    .products-details-image-slides-owl-thumbs {
        padding: 0;
        text-align: center;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
            left: -5px;
            right: -5px;
            top: 0;
            bottom: 0;
        };
        .owl-thumb-item {
            display: block;
            flex: 0 0 25%;
            max-width: 25%;
            position: relative;
            cursor: pointer;
            padding: {
                left: 5px;
                right: 5px;
                top: 10px;
            };
            img {
                transition: var(--transition);

                &:hover {
                    opacity: 0.5;
                }
            }
            &.active {
                img {
                    opacity: 0.5;
                }
            }
        }
    }
    .row {
        margin: {
            left: -8px;
            right: -8px;
            top: -15px;
        };
        .col-lg-6 {
            padding: {
                left: 8px;
                right: 8px;
            };
            .single-products-details-image {
                margin-top: 15px;
            }
        }
    }
    .single-products-details-image {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }
}
.products-details-image-slider {
    &.owl-theme {
        margin-bottom: 30px;

        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                color: #87a1c1;
                font-size: 15px;
                margin: 0;
                padding: 0;
                background: var(--whiteColor);
                width: 40px;
                height: 40px;
                line-height: 35px;
                position: absolute;
                left: 15px;
                transition: var(--transition);
                top: 50%;
                padding-right: 2px !important;
                transform: translateY(-50%);
                border: {
                    radius: 50%;
                    style: solid;
                    color: var(--whiteColor);
                    width: 1.5px;
                };
                &.owl-next {
                    left: auto;
                    right: 15px;
                    padding: {
                        right: 0 !important;
                        left: 2px !important;
                    };
                }
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                }
            }
        }
    }
}
.products-details-desc {
    h3 {
        margin-bottom: 18px;
        font: {
            size: 22px;
        };
    }
    .price {
        margin-bottom: 15px;
        
        font: {
            size: 22px;
            weight: 700;
        }
        width:"10%";
        .old-price {
            text-decoration: line-through;
            color: #828893;
            font-weight: normal;
        }
    }
    .products-review {
        margin-bottom: 12px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 18px;

            i {
                color: #ffba0a;
                display: inline-block;
                margin-right: -2px;
            }
        }
        .rating-count {
            display: inline-block;
            color: var(--blackColor);
            border-bottom: 1px solid var(--blackColor);
            line-height: initial;
            position: relative;
            top: -2px;
            font-weight: 600;

            &:hover {
                color: var(--mainColor);
                border-color: var(--mainColor);
            }
        }
    }
    .products-info {
        list-style-type: none;
        padding-left: 0;
        margin: {
            top: 15px;
            bottom: 0;
        };
        li {
            color: var(--blackColor);
            margin-bottom: 8px;
            font: {
                size: 16px;
            };
            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: var(--optionalColor);
            }
            a {
                display: inline-block;
                font-weight: 600;
                color: var(--blackColor);

                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }
    .products-color-switch {
        margin-top: 10px;

        span {
            margin-bottom: 8px;
            display: block;
            font: {
                size: 17px;
                weight: 600;
            };
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                top: -3px;
                left: -3px;
            };
            li {
                display: inline-block;
                margin: {
                    left: 3px;
                    top: 3px;
                };
                a {
                    display: block;
                    position: relative;
                    border: 1px solid transparent;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;

                    &::before {
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: #eeeeee;
                        border-radius: 50%;
                        margin: 2px;
                        transition: var(--transition);
                    }
                    &:hover, &:focus {
                        border-color: var(--blackColor);
                    }
                    &.color-white {
                        &::before {
                            background: var(--whiteColor);
                            border: 1px solid #eeeeee;
                        }
                    }
                    &.color-black {
                        &::before {
                            background: var(--blackColor);
                        }
                    }
                    &.color-red {
                        &::before {
                            background: red;
                        }
                    }
                    &.color-blue {
                        &::before {
                            background: blue;
                        }
                    }
                    &.color-green {
                        &::before {
                            background: green;
                        }
                    }
                    &.color-yellow {
                        &::before {
                            background: yellow;
                        }
                    }
                    &.color-yellowgreen {
                        &::before {
                            background: yellowgreen;
                        }
                    }
                    &.color-pink {
                        &::before {
                            background: pink;
                        }
                    }
                    &.color-violet {
                        &::before {
                            background: violet;
                        }
                    }
                    &.color-teal {
                        &::before {
                            background: teal;
                        }
                    }
                    &.color-plum {
                        &::before {
                            background: plum;
                        }
                    }
                    &.color-lime {
                        &::before {
                            background: lime;
                        }
                    }
                    &.color-blueviolet {
                        &::before {
                            background: blueviolet;
                        }
                    }
                }
                &.active {
                    a {
                        border-color: var(--blackColor);
                    }
                }
            }
        }
    }
    .products-size-wrapper {
        margin: {
            top: 8px;
            bottom: 25px;
        };
        span {
            margin-bottom: 8px;
            display: block;
            font: {
                size: 17px;
                weight: 600;
            };
        }
        ul {
            list-style-type: none;
            padding-left: 0;
            margin: {
                bottom: 0;
                top: -8px;
                left: -8px;
            };
            li {
                display: inline-block;
                margin: {
                    left: 8px;
                    top: 8px;
                };
                a {
                    width: 38px;
                    height: 35px;
                    display: block;
                    line-height: 32px;
                    border: 1px solid #eeeeee;
                    color: #858585;
                    text-align: center;
                    font: {
                        size: var(--fontSize);
                        weight: 600;
                    };
                    &:hover {
                        border-color: var(--mainColor);
                        color: var(--mainColor);
                    }
                }
                &.active {
                    a {
                        border-color: var(--mainColor);
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
    .products-info-btn {
        margin-bottom: 25px;

        a {
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            padding-left: 22px;
            line-height: initial;
            font: {
                size: 14px;
                weight: 600;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 0;
                height: 1.5px;
                background: var(--mainColor);
                transition: var(--transition);
            }
            i {
                font-size: 18px;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:hover {
                color: var(--mainColor);

                &::before {
                    width: 100%;
                }
            }
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
    .products-add-to-cart {
        .input-counter {
            max-width: 120px;
            min-width: 120px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;    

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 40px;
                height: 100%;
                line-height: 50px;
                transition: var(--transition);
                font-size: 22px;

                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: var(--mainColor);
                }
            }
            input {
                height: 45px;
                color: var(--blackColor);
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: var(--blackColor);
                }
            }
        }
        .default-btn {
            border-radius: 0;
            height: 45px;
            top: -1px;
        }
    }
    .wishlist-btn {
        margin-top: 20px;

        a {
            display: inline-block;
            font-weight: 700;
            position: relative;
            line-height: 1;
            padding-left: 20px;

            i {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 16px;
            }
        }
    }
    .buy-checkbox-btn {
        margin-top: 20px;

        input {
            display: none;
        }
        .cbx {
            margin: auto;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;

            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);

                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: var(--transition);

                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: var(--whiteColor);
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: var(--transition);
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: var(--mainColor);
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: var(--transition);
                    }
                }
                &:last-child {
                    padding-left: 4px;
                    color: var(--blackColor);
                    position: relative;
                    top: -1px;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: var(--mainColor);
                    }
                }
            }
        }
        .inp-cbx:checked + .cbx span:first-child {
            background: var(--mainColor);
            border-color: var(--mainColor);
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked + .cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked + .cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
        .default-btn {
            display: block;
            text-align: center;
            padding: {
                left: 30px;
                right: 30px;
                top: 12.5px;
                bottom: 12.5px;
            };
        }
    }
    .products-details-accordion {
        margin-top: 25px;

        .accordion {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            
            .accordion-item {
                border-radius: 5px;
                display: block;
                background: var(--whiteColor);
                margin-bottom: 20px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .accordion-title {
                padding-left: 25px;
                color: var(--blackColor);
                text-decoration: none;
                position: relative;
                display: block;
                font: {
                    size: 16px;
                    weight: 700;
                };
                i {
                    position: absolute;
                    left: -6px;
                    top: -3px;
                    font-size: 30px;
                    line-height: 1;
                    transition: var(--transition);
                }
                &.active {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
            .accordion-content {
                display: none;
                position: relative;
                margin-top: -5px;
                padding: {
                    top: 15px;
                };
                &.show {
                    display: block;
                }
                ul, ol {
                    padding-left: 0;
                    list-style-type: none;
                    margin-bottom: 0;

                    li {
                        margin-bottom: 10px;
                        position: relative;
                        padding-left: 13px;
                        color: var(--optionalColor);

                        &::before {
                            content: '';
                            position: absolute;
                            top: 8px;
                            left: 0;
                            width: 6px;
                            height: 6px;
                            background: var(--mainColor);
                            border-radius: 50%;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .table {
                    margin-bottom: 0;

                    &.table-striped {
                        tbody {
                            tr {
                                td {
                                    border-top: none;
                                    vertical-align: middle;
                                    white-space: nowrap;
                                    padding: 10px 15px;
                                    color: var(--blackColor);

                                    &:first-child {
                                        font: {
                                            weight: 500;
                                        };
                                    }
                                }
                            }
                        }
                    }
                    &.table-bordered {
                        tbody {
                            tr {
                                td {
                                    vertical-align: middle;
                                    white-space: nowrap;
                                    padding: 10px 15px;
                                    color: var(--optionalColor);
                                    line-height: 1.7;
                                    border-color: #eeeeee;

                                    &:first-child {
                                        color: var(--blackColor);
                                        font: {
                                            weight: 500;
                                        };
                                    }
                                }
                            }
                        }
                    }
                }
                h3 {
                    margin-bottom: 12px;
                    font: {
                        size: 18px;
                    };
                }
                .products-reviews {
                    margin-top: 10px;

                    h3 {
                        margin-bottom: 0;
                        display: inline-block;
                        margin-right: 15px;
                        font: {
                            size: 22px;
                        };
                    }
                    .rating {
                        display: inline-block;
                        
                        span {
                            font-size: 17px;
                            color: #cecfd2;
                            margin-right: -2px;
            
                            &.checked {
                                color: orange;
                            }
                        }
                    }
                    .rating-count {
                        margin: {
                            top: 10px;
                            bottom: 20px;
                        };
                        span {
                            display: block;
                            color: var(--optionalColor);
                        }
                    }
                    .row {
                        overflow: hidden;
                        margin: {
                            left: 0;
                            right: 0;
                        };
                    }
                    .side {
                        float: left;
                        width: 9%;
                        margin-top: 10px;
            
                        div {
                            font: {
                                weight: 700;
                            };
                        }
                    }
                    .middle {
                        margin-top: 14px;
                        float: left;
                        width: 82%;
                    }
                    .right {
                        text-align: right;
                    }
                    .bar-container {
                        width: 100%;
                        background-color: #f1f1f1;
                        text-align: center;
                        color: var(--whiteColor);
                        border-radius: 5px;
                    }
                    .bar-5 {
                        width: 100%;
                        height: 18px;
                        background-color: #4CAF50;
                        border-radius: 5px;
                    }
                    .bar-4 {
                        width: 75%;
                        height: 18px;
                        background-color: #2196F3;
                        border-radius: 5px;
                        border-radius: 5px;
                    }
                    .bar-3 {
                        width: 50%;
                        height: 18px;
                        background-color: #00bcd4;
                        border-radius: 5px;
                    }
                    .bar-2 {
                        width: 25%;
                        height: 18px;
                        background-color: #ff9800;
                        border-radius: 5px;
                    }
                    .bar-1 {
                        width: 0;
                        height: 18px;
                        background-color: #f44336;
                        border-radius: 5px;
                    }
                }
                .products-review-comments {
                    margin-top: 40px;
                
                    h3 {
                        border-bottom: 1px solid #f3f3f3;
                        padding-bottom: 10px;
                        margin: {
                            top: 0;
                            bottom: 10px;
                        };
                        font: {
                            size: 22px;
                        };
                    }
                    .user-review {
                        border-bottom: 1px solid #f3f3f3;
                        padding: 20px 0 20px 110px;
                        position: relative;
                
                        img {
                            position: absolute;
                            left: 0;
                            top: 20px;
                            width: 90px;
                            height: 90px;
                            border-radius: 5px;
                        }
                        .sub-comment {
                            margin-bottom: 8px;
                            font: {
                                weight: 700;
                                family: var(--fontFamily);
                            };
                        }
                        .review-rating {
                            display: block;
                            margin-bottom: 8px;
                
                            .review-stars {
                                display: inline-block;
                
                                i {
                                    color: #cecfd2;
                                    font-size: 18px;
                                    display: inline-block;
                                    margin-right: -3px;
                    
                                    &.checked {
                                        color: orange;
                                    }
                                }
                            }
                            span {
                                color: var(--blackColor);
                                position: relative;
                                top: -2px;
                                margin-left: 5px;
                                font: {
                                    family: var(--fontFamily2);
                                    weight: 700;
                                };
                            }
                        }
                    }
                }
                .review-form-wrapper {
                    margin-top: 30px;
    
                    h3 {
                        margin-bottom: 10px;
                        font: {
                            size: 22px;
                        };
                    }
                    .comment-notes {
                        span {
                            color: red;
                        }
                    }
                    form {
                        margin-top: 20px;
    
                        .form-group {
                            margin-bottom: 25px;
                            text-align: left;
                        }
                        .rating {
                            text-align: left;
                            overflow: hidden;
                            max-width: 115px;
                            margin: {
                                top: -5px;
                                bottom: 20px;
                            };
                            label {
                                float: right;
                                position: relative;
                                width: 23px;
                                height: 23px;
                                cursor: pointer;
    
                                &:not(:first-of-type) {
                                    padding-right: 5px;
                                }
                                &:before {
                                    content: "\2605";
                                    transition: var(--transition);
                                    font-size: 27px;
                                    color: #CCCCCC;
                                    line-height: 1;
                                }
                            }
                            input {
                                display: none;
                            }
                            input:checked ~ label:before, &:not(:checked) > label:hover:before, &:not(:checked) > label:hover ~ label:before {
                                color: #f6b500;
                            }
                        }
                        .comment-form-cookies-consent {
                            text-align: left;
                            margin-bottom: 0;
                
                            [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
                                display: none;
                            }
                            [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
                                position: relative;
                                padding-left: 28px;
                                cursor: pointer;
                                line-height: 20px;
                                display: inline-block;
                                margin-bottom: 0;
                                color: var(--optionalColor);
                                font: {
                                    weight: 500;
                                };
                            }
                            [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 1px;
                                width: 19px;
                                height: 19px;
                                transition: all 0.2s ease;
                                border: 1px solid #f5f5f5;
                                border-radius: 3px;
                                background: #f5f5f5;
                            }
                            [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                                content: '';
                                width: 8px;
                                height: 8px;
                                background: var(--mainColor);
                                position: absolute;
                                top: 6.5px;
                                left: 6px;
                                -webkit-transition: all 0.2s ease;
                                transition: all 0.2s ease;
                            }
                            [type="checkbox"]:not(:checked) + label:after {
                                opacity: 0;
                                -webkit-transform: scale(0);
                                transform: scale(0);
                            }
                            [type="checkbox"]:checked + label:after {
                                opacity: 1;
                                -webkit-transform: scale(1);
                                transform: scale(1);
                            }
                            [type="checkbox"]:hover + label:before {
                                border-color: var(--mainColor);
                            }
                            [type="checkbox"]:checked + label:before {
                                border-color: var(--mainColor);
                            }
                        }
                        button {
                            margin-top: 22px;
                            padding: {
                                left: 50px;
                                right: 50px;
                                top: 11px;
                                bottom: 11px;
                            };
                        }
                    }
                }
            }
        }
    }
}
.products-details-tabs {
    margin-top: 50px;

    .nav {
        text-align: center;
        padding-left: 0;
        margin-bottom: 40px;
        list-style-type: none;
        display: block;

        .nav-item {
            display: inline-block;
            margin: {
                left: 15px;
                right: 15px;
            };
            .nav-link {
                color: #cccccc;
                border: none;
                border-bottom: 1px solid #eeeeee;
                padding: 0;
                background-color: transparent;
                position: relative;
                padding-bottom: 8px;
                font: {
                    size: 20px;
                    weight: 700;
                    family: var(--fontFamily);
                };
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 0;
                    height: 3px;
                    background-color: var(--mainColor);
                    transition: var(--transition);
                    bottom: -2px;
                }
                &:hover, &.active {
                    color: var(--blackColor);

                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            p {
                line-height: 1.8;
            }
            ul {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    top: 20px;
                    bottom: 0;
                };
                li {
                    margin-bottom: 12px;
                    position: relative;
                    color: var(--optionalColor);
                    padding-left: 15px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 8px;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background-color: var(--blackColor);
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .products-reviews {
                h3 {
                    margin-bottom: 0;
                    display: inline-block;
                    margin-right: 15px;
                    font: {
                        size: 22px;
                    };
                }
                .rating {
                    display: inline-block;
                    
                    span {
                        font-size: 17px;
                        color: #cecfd2;
                        margin-right: -2px;
        
                        &.checked {
                            color: orange;
                        }
                    }
                }
                .rating-count {
                    margin: {
                        top: 10px;
                        bottom: 20px;
                    };
                    span {
                        display: block;
                        color: var(--optionalColor);
                    }
                }
                .row {
                    overflow: hidden;
                    margin: {
                        left: 0;
                        right: 0;
                    };
                }
                .side {
                    float: left;
                    width: 9%;
                    margin-top: 10px;
        
                    div {
                        font: {
                            weight: 700;
                        };
                    }
                }
                .middle {
                    margin-top: 14px;
                    float: left;
                    width: 82%;
                }
                .right {
                    text-align: right;
                }
                .bar-container {
                    width: 100%;
                    background-color: #f1f1f1;
                    text-align: center;
                    color: var(--whiteColor);
                    border-radius: 5px;
                }
                .bar-5 {
                    width: 100%;
                    height: 18px;
                    background-color: #4CAF50;
                    border-radius: 5px;
                }
                .bar-4 {
                    width: 75%;
                    height: 18px;
                    background-color: #2196F3;
                    border-radius: 5px;
                    border-radius: 5px;
                }
                .bar-3 {
                    width: 50%;
                    height: 18px;
                    background-color: #00bcd4;
                    border-radius: 5px;
                }
                .bar-2 {
                    width: 25%;
                    height: 18px;
                    background-color: #ff9800;
                    border-radius: 5px;
                }
                .bar-1 {
                    width: 0;
                    height: 18px;
                    background-color: #f44336;
                    border-radius: 5px;
                }
            }
            .products-review-comments {
                margin-top: 40px;
            
                h3 {
                    border-bottom: 1px solid #f3f3f3;
                    padding-bottom: 10px;
                    margin: {
                        top: 0;
                        bottom: 10px;
                    };
                    font: {
                        size: 22px;
                    };
                }
                .user-review {
                    border-bottom: 1px solid #f3f3f3;
                    padding: 20px 0 20px 110px;
                    position: relative;
            
                    img {
                        position: absolute;
                        left: 0;
                        top: 20px;
                        width: 90px;
                        height: 90px;
                        border-radius: 5px;
                    }
                    .sub-comment {
                        margin-bottom: 8px;
                        font: {
                            weight: 700;
                            family: var(--fontFamily);
                        };
                    }
                    .review-rating {
                        display: block;
                        margin-bottom: 8px;
            
                        .review-stars {
                            display: inline-block;
            
                            i {
                                color: #cecfd2;
                                font-size: 18px;
                                display: inline-block;
                                margin-right: -3px;
                
                                &.checked {
                                    color: orange;
                                }
                            }
                        }
                        span {
                            color: var(--blackColor);
                            position: relative;
                            top: -2px;
                            margin-left: 5px;
                            font: {
                                family: var(--fontFamily2);
                                weight: 700;
                            };
                        }
                    }
                }
            }
            .review-form-wrapper {
                margin-top: 30px;

                h3 {
                    margin-bottom: 10px;
                    font: {
                        size: 22px;
                    };
                }
                .comment-notes {
                    span {
                        color: red;
                    }
                }
                form {
                    margin-top: 20px;

                    .form-group {
                        margin-bottom: 25px;
                        text-align: left;
                    }
                    .rating {
                        text-align: left;
                        overflow: hidden;
                        max-width: 115px;
                        margin: {
                            top: -5px;
                            bottom: 20px;
                        };
                        label {
                            float: right;
                            position: relative;
                            width: 23px;
                            height: 23px;
                            cursor: pointer;

                            &:not(:first-of-type) {
                                padding-right: 5px;
                            }
                            &:before {
                                content: "\2605";
                                transition: var(--transition);
                                font-size: 27px;
                                color: #CCCCCC;
                                line-height: 1;
                            }
                        }
                        input {
                            display: none;
                        }
                        input:checked ~ label:before, &:not(:checked) > label:hover:before, &:not(:checked) > label:hover ~ label:before {
                            color: #f6b500;
                        }
                    }
                    .comment-form-cookies-consent {
                        text-align: left;
                        margin-bottom: 0;
            
                        [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
                            display: none;
                        }
                        [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
                            position: relative;
                            padding-left: 28px;
                            cursor: pointer;
                            line-height: 20px;
                            display: inline-block;
                            margin-bottom: 0;
                            color: var(--optionalColor);
                            font: {
                                weight: 500;
                            };
                        }
                        [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 1px;
                            width: 19px;
                            height: 19px;
                            transition: all 0.2s ease;
                            border: 1px solid #f5f5f5;
                            border-radius: 3px;
                            background: #f5f5f5;
                        }
                        [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                            content: '';
                            width: 8px;
                            height: 8px;
                            background: var(--mainColor);
                            position: absolute;
                            top: 6.5px;
                            left: 6px;
                            -webkit-transition: all 0.2s ease;
                            transition: all 0.2s ease;
                        }
                        [type="checkbox"]:not(:checked) + label:after {
                            opacity: 0;
                            -webkit-transform: scale(0);
                            transform: scale(0);
                        }
                        [type="checkbox"]:checked + label:after {
                            opacity: 1;
                            -webkit-transform: scale(1);
                            transform: scale(1);
                        }
                        [type="checkbox"]:hover + label:before {
                            border-color: var(--mainColor);
                        }
                        [type="checkbox"]:checked + label:before {
                            border-color: var(--mainColor);
                        }
                    }
                    button {
                        margin-top: 22px;
                        padding: {
                            left: 50px;
                            right: 50px;
                            top: 11px;
                            bottom: 11px;
                        };
                    }
                }
            }
            table {
                margin-bottom: 0;

                tr {
                    td {
                        white-space: nowrap;
                        border-color: #eeeeee;
                        color: var(--optionalColor);
                        vertical-align: middle;
                        padding: 10px 15px;

                        &:first-child {
                            color: var(--blackColor);
                            font: {
                                weight: 600;
                                family: var(--fontFamily2);
                            };
                        }
                    }
                }
            }
        }
    }
}
.related-products {
    margin-top: 50px;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
    table {
        margin-bottom: 0;

        thead {
            tr {
                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 0 0 15px;
                    text-transform: uppercase;
                    border: none;
                    white-space: nowrap;
                    font: {
                        size: 16px;
                        weight: 700;
                    };
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;
                    color: var(--optionalColor);
                    white-space: nowrap;
                    font: {
                        weight: 700;
                        size: 15px;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                    };
                    border: {
                        color: #eaedff;
                        left: none;
                        right: none;
                    };
                    &.product-thumbnail {
                        a {
                            display: block;

                            img {
                                width: 80px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            display: inline-block;
                        }
                    }
                    &.product-subtotal {
                        .remove {
                            color: red;
                            float: right;
                            position: relative;
                            top: -1px;
                            font-size: 18px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            text-align: center;
                            display: inline-block;
                            position: relative;    
                
                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 50px;
                                transition: var(--transition);
                                font-size: 22px;
                
                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: var(--mainColor);
                                }
                            }
                            input {
                                height: 45px;
                                color: var(--blackColor);
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                font: {
                                    size: 17px;
                                    weight: 600;
                                }
                                &::placeholder {
                                    color: var(--blackColor);
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
.cart-buttons {
    margin-top: 30px;

    .shopping-coupon-code {
        position: relative;
        max-width: 530px;

        button {
            position: absolute;
            right: 0;
            top: 0;
            height: 50px;
            background: var(--blackColor);
            color: var(--whiteColor);
            border: none;
            padding: 0 25px;
            line-height: 47px;
            outline: 0;
            transition: var(--transition);
            font: {
                size: 15px;
                weight: 700;
            };
            &:hover {
                background-color: var(--mainColor);
            }
        }
    }
}
.cart-totals {
    background: var(--whiteColor);
    padding: 40px;
    max-width: 500px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: {
        top: 60px;
        left: auto;
        right: auto;
    };
    h3 {
        margin-bottom: 25px;
        font: {
            size: 22px;
        };
    }
    ul {
        padding-left: 0;
        margin: 0 0 25px;
        list-style-type: none;
        
        li {
            border: 1px solid #eaedff;
            padding: 10px 15px;
            color: var(--blackColor);
            overflow: hidden;
            font: {
                weight: 700;
                size: 15px;
            };
            &:first-child {
                border: {
                    bottom: none;
                }
            }
            &:last-child {
                font-size: 18px;
                border: {
                    top: none;
                };
                span {
                    color: var(--blackColor);
                    font-weight: 600;
                }
            }
            span {
                float: right;
                color: var(--optionalColor);
                font: {
                    weight: normal;
                };
            }
        }
    }
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: var(--whiteColor);
    padding: 15px 20px;
    border-top: 3px solid var(--mainColor);
    position: relative;
    margin-bottom: 40px;

    i {
        color: var(--mainColor);
        margin-right: 2px;
        font-size: 20px;
        position: relative;
        top: 3px;
    }
    span {
        display: inline-block;
        color: var(--mainColor);
        font: {
            weight: 700;
            size: 16px;
        };
        a {
            display: inline-block;
        }
    }
}
.billing-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 22px;
        };
        &::before {
            content: '';
            position: absolute;
            background: var(--mainColor);
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .form-group {
        margin-bottom: 25px;

        label {
            display: block;
            margin-bottom: 8px;
            font: {
                weight: 700;
                size: 14px;
            };
            .required {
                color: red;
            }
        }
        .nice-select {
            display: block;
            float: unset;
            background: #f8f8f8;
            border-color: #eeeeee;
            color: var(--blackColor);
            transition: var(--transition);
            padding: 0 0 0 12px;
            height: 50px;
            line-height: 50px;
            width: 100%;
            font: {
                size: 15px;
                weight: 600;
            };
            .list {
                background-color: var(--whiteColor);
                border-radius: 5px;
                box-shadow: 0 10px 30px rgba(0,0,0,.2);
                list-style-type: none;
                border: none;
                width: 100%;
                margin: {
                    bottom: 0;
                    top: 0;
                };
                padding: {
                    left: 0;
                    top: 10px;
                    bottom: 10px;
                };
                .option {
                    line-height: 38px;
                    min-height: 38px;
                    color: var(--blackColor);
                    position: relative;
                    transition: var(--transition);
                    padding: {
                        left: 32px;
                        right: 25px;
                    };
                    font: {
                        size: 15px;
                        weight: 600;
                    };
                    &:hover {
                        background-color: var(--mainColor) !important;
                        color: var(--whiteColor);

                        &::before {
                            color: var(--whiteColor);
                        }
                    }
                    &.focus, &.selected.focus {
                        background-color: transparent !important;
                        color: var(--blackColor);
                    }
                    &::before {
                        content: "\ea0f";
                        position: absolute;
                        left: 10px;
                        top: -3px;
                        opacity: 0;
                        visibility: hidden;
                        transition: var(--transition);
                        color: var(--mainColor);
                        font: {
                            family: 'boxicons';
                            size: 20px;
                        };
                    }
                    &.selected {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                        &:hover {
                            background-color: var(--mainColor) !important;
                            color: var(--whiteColor);

                            &::before {
                                color: var(--whiteColor);
                            }
                        }
                    }
                }
            }
            &:after {
                border-color: var(--blackColor);
                height: 8px;
                width: 8px;
                margin-top: -5px;
            }
            &:hover {
                border-color: var(--mainColor);
                background-color: transparent;
            }
        }
    }
    .form-check {
        margin-bottom: 20px;

        .form-check-label {
            color: var(--blackColor);
            font-weight: 600;
        }
        label {
            position: relative;
            left: -3px;
            top: 0;
            font: {
                weight: 500;
            }
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
.order-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 22px;
        };
        &::before {
            content: '';
            position: absolute;
            background: var(--mainColor);
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .order-table {
        table {
            margin-bottom: 0;
    
            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        padding: 12px 20px 10px;
                        white-space: nowrap;
                        text-transform: uppercase;
                        font: {
                            weight: 700;
                            size: 15px;
                        };
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: var(--optionalColor);
                        white-space: nowrap;
                        border-color: #eaedff;
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                        padding: {
                            left: 20px;
                            right: 20px;
                            top: 15px;
                            bottom: 13px;
                        };
                        &.product-name {
                            a {
                                display: inline-block;
                            }
                        }
                        &.order-subtotal, &.order-shipping , &.total-price {
                            span {
                                color: var(--blackColor);
                                font: {
                                    weight: 700;
                                };
                            }
                        }
                        &.shipping-price, &.order-subtotal-price, &.product-subtotal {
                            color: var(--blackColor);
                            font: {
                                weight: 700;
                            };
                        }
                    }
                }
            }
        }
    }
    .payment-box {
        background-color: var(--whiteColor);
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;
        
        .payment-method {
            p {
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        + label {
                            padding-left: 27px;
                            cursor: pointer;
                            display: block;
                            color: var(--blackColor);
                            position: relative;
                            margin-bottom: 8px;
                            font: {
                                weight: 700;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 4px;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: var(--whiteColor);
                            }
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: var(--mainColor);
                                position: absolute;
                                top: 7px;
                                left: 3px;
                                border-radius: 50%;
                                transition: var(--transition);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        + label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        + label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .default-btn {
            margin-top: 15px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            padding: {
                left: 30px;
                right: 30px;
            };
        }
    }
}

/*================================================
Woocommerce Widget Area CSS
=================================================*/
.woocommerce-widget-area {
    .woocommerce-widget {
        margin-bottom: 35px;

        .woocommerce-widget-title {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 8px;
            border-bottom: 1px solid #eeeeee;
            font: {
                size: 22px;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50px;
                height: 1px;
                bottom: -1px;
                background-color: var(--mainColor);
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .color-list-widget {
        .color-list-row {
            list-style-type: none;
            padding-left: 0;
            margin: {
                bottom: -5px;
                top: -4px;
            };
            li {
                display: inline-block;
                margin: {
                    right: 5px;
                    top: 5px;
                };
                a {
                    display: block;
                    position: relative;
                    border: 1px solid transparent;
                    width: 18px;
                    height: 18px;

                    &::before {
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: #eeeeee;
                        margin: 2px;
                        transition: var(--transition);
                    }
                    &:hover {
                        border-color: var(--blackColor);
                    }
                    &.color-white {
                        &::before {
                            background: var(--whiteColor);
                            border: 1px solid #eeeeee;
                        }
                    }
                    &.color-black {
                        &::before {
                            background: var(--blackColor);
                        }
                    }
                    &.color-red {
                        &::before {
                            background: red;
                        }
                    }
                    &.color-blue {
                        &::before {
                            background: blue;
                        }
                    }
                    &.color-green {
                        &::before {
                            background: green;
                        }
                    }
                    &.color-yellow {
                        &::before {
                            background: yellow;
                        }
                    }
                    &.color-yellowgreen {
                        &::before {
                            background: yellowgreen;
                        }
                    }
                    &.color-pink {
                        &::before {
                            background: pink;
                        }
                    }
                    &.color-violet {
                        &::before {
                            background: violet;
                        }
                    }
                    &.color-teal {
                        &::before {
                            background: teal;
                        }
                    }
                    &.color-plum {
                        &::before {
                            background: plum;
                        }
                    }
                    &.color-lime {
                        &::before {
                            background: lime;
                        }
                    }
                    &.color-blueviolet {
                        &::before {
                            background: blueviolet;
                        }
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                &.active {
                    a {
                        border-color: var(--blackColor);
                    }
                }
            }
        }
    }
    .best-seller-widget {
        position: relative;
        overflow: hidden;
        
        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 5px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                        background-image: url(../../assets/img/products/products-img3.jpg);
                    }
                    &.bg2 {
                        background-image: url(../../assets/img/products/products-img2.jpg);
                    }
                    &.bg3 {
                        background-image: url(../../assets/img/products/products-img5.jpg);
                    }
                }
                &::before, &::after {
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                    content: '';
                    background-color: var(--whiteColor);
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: var(--mainColor);
                    font: {
                        size: 14px;
                        weight: 600;
                    };
                    margin: {
                        top: 2px;
                        bottom: 5px;
                    };
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 17px;
                    };
                    a {
                        display: inline-block;
                    }
                }
                .rating {
                    color: #ffba0a;
                }
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .woocommerce-ads-widget {
        position: relative;
        text-align: center;
        overflow: hidden;

        img {
            transition: var(--transition);
            width: 100%;
        }
        .content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            padding: {
                top: 20px;
                left: 15px;
                right: 15px;
            };
            span {
                display: block;
                font-weight: 600;
                color: var(--mainColor);
                margin-bottom: 5px;
            }
            h3 {
                font-size: 22px;
                margin-bottom: 15px;
            }
        }
        .link-btn {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
        }
        &:hover {
            img {
                transform: scale(1.06);
            }
        }
    }
    .brands-list-widget {
        .brands-list-row {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 9px;

                a {
                    display: inline-block;
                    position: relative;
                    padding-left: 25px;
                    font: {
                        weight: 600;
                    };
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 2.5px;
                        width: 15px;
                        transition: var(--transition);
                        height: 15px;
                        border-radius: 3px;
                        border: 1px solid var(--blackColor);
                    }
                    &:hover {
                        color: var(--mainColor);

                        &::before {
                            border-color: var(--mainColor);
                        }
                    }
                    &.active {
                        &::before {
                            background-color: var(--mainColor);
                            border-color: var(--mainColor);
                        }
                    }
                }
                &.active {
                    a {
                        &::before {
                            background-color: var(--mainColor);
                            border-color: var(--mainColor);
                        }
                    }
                }
                &:last-child {
                    display: inline-block;
                }
            }
        }
    }
    .price-list-widget {
        .collection-filter-by-price {
            margin-top: 20px;

            .irs-handle {
                cursor: pointer;
            }
            .irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
                background-color: var(--mainColor);
                padding: 1px 5px 1px 11.5px;

                &::before {
                    border-top-color: var(--mainColor);
                }
                &::after {
                    content: '$';
                    position: absolute;
                    left: 5px;
                    top: 0.5px;
                }
            }
            .irs--flat .irs-bar {
                background-color: var(--mainColor);
            }
            .irs--flat .irs-handle>i:first-child {
                background-color: var(--mainColor);
            }
            .irs--flat .irs-min, .irs--flat .irs-max {
                padding: 1px 3px 1px 9.5px;

                &::before {
                    content: '$';
                    position: absolute;
                    left: 3px;
                    top: 0.5px;
                }
            }
        }
    }
}

/*================================================
Products Promotions Area CSS
=================================================*/
.single-products-promotions-box {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    img {
        transition: var(--transition);
    }
    .content {
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: right;
        padding: {
            left: 15px;
            right: 15px;
        };
        .sub-title {
            display: block;
            margin-bottom: 10px;
            color: var(--optionalColor);
            font: {
                weight: 600;
            };
        }
        h3 {
            font-size: 25px;
            margin-bottom: 0;
            background-color: #cae7d9;
            display: inline-block;
            margin-right: -15px;
            border-radius: 30px 0 0 30px;
            padding: {
                top: 3px;
                bottom: 3px;
                left: 15px;
                right: 15px;
            };
        }
        .discount {
            display: block;
            font: {
                size: 25px;
                weight: 700;
            };
            span {
                font: {
                    size: var(--fontSize);
                    weight: 600;
                };
            }
        }
        .link-btn {
            display: inline-block;
            margin-top: 20px;
            font: {
                weight: 600;
            };
            i {
                position: relative;
                top: 1px;
                margin-left: -2px;
            }
        }
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}
.col-lg-4 {
    &:nth-child(2) {
        .single-products-promotions-box {
            .content {
                h3 {
                    background-color: #f1e6b9;
                }
            }
        }
    }
    &:nth-child(3) {
        .single-products-promotions-box {
            .content {
                h3 {
                    background-color: #b6d2f1;
                }
            }
        }
    }
}
.promotion-banner {
    background: {
        image: url(../../assets/img/promotions-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 60px;
        bottom: 60px;
        left: 30px;
        right: 30px;
    };
    .sub-title {
        display: block;
        font-weight: 600;
        margin-bottom: 12px;
    }
    h2 {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 1.3;

        span {
            color: var(--mainColor);
        }
    }
    #timer {
        margin-bottom: 30px;

        div {
            background-color: var(--whiteColor);
            color: var(--blackColor);
            box-shadow: 0 0 7px rgba(0, 0, 0, .10);
            width: 70px;
            height: 70px;
            border-radius: 5px;
            font: {
                size: 24px;
                weight: 700;
            };
            margin: {
                right: 10px;
            };
            span {
                display: block;
                color: var(--optionalColor);
                margin-top: 0;
                font: {
                    size: 14px;
                    weight: 600;
                };
            }
        }
    }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-inner {
    text-align: center;
    z-index: 1;
    position: relative;
    padding: {
        left: 30px;
        right: 30px;
        bottom: 30px;
    };
    h2 {
        line-height: 1;
        font-size: 30px;
        position: relative;
        top: -4px;
        margin: {
            bottom: 30px;
        };
        span {
            display: inline-block;
            background-color: var(--whiteColor);
            padding: {
                left: 35px;
                right: 35px;
            };
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        border: 1px solid var(--mainColor);
    }
    .owl-theme {
        .owl-nav.disabled+.owl-dots {
            text-align: right;
            margin-top: 25px;
        }
        .owl-dots {
            .owl-dot {
                margin-left: 7px;

                span {
                    width: 15px;
                    height: 15px;
                    margin: 0;
                    border: 1px solid var(--mainColor);
                    background: transparent;
                    transition: var(--transition);
                    border-radius: 50%;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: 3px;
                        background-color: var(--mainColor);
                        transition: var(--transition);
                        border-radius: 50%;
                        opacity: 0;
                        visibility: hidden;
                    }
                }
                &:hover, &.active {
                    span {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}
.single-feedback-item {
    text-align: center;
    position: relative;
    padding-top: 68px;

    &::before {
        line-height: 1;
        content: "\f111";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        color: var(--mainColor);
        font: {
            size: 55px;
            family: Flaticon;
        };
    }
    p {
        margin-bottom: 0;
        font-size: 18px;
        color: var(--blackColor);
    }
    .client-info {
        margin-top: 25px;
        text-align: left;

        img {
            width: 70px !important;
            display: inline-block !important;
            height: 70px;
            border-radius: 50%;
        }
        .title {
            margin-left: 15px;
            
            h3 {
                font-size: 20px;
                margin-bottom: 2px;
            }
            span {
                display: block;
            }
        }
    }
}
.single-feedback-box {
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    background-color: var(--whiteColor);
    padding: {
        left: 30px;
        right: 30px;
        bottom: 30px;
        top: 98px;
    };
    &::before {
        line-height: 1;
        content: "\f111";
        position: absolute;
        left: 0;
        right: 0;
        top: 30px;
        color: var(--mainColor);
        font: {
            size: 55px;
            family: Flaticon;
        };
    }
    p {
        margin-bottom: 0;
        font-size: 18px;
        color: var(--blackColor);
    }
    .client-info {
        margin-top: 25px;
        text-align: left;

        img {
            width: 70px !important;
            display: inline-block !important;
            height: 70px;
            border-radius: 50%;
        }
        .title {
            margin-left: 15px;
            
            h3 {
                font-size: 20px;
                margin-bottom: 2px;
            }
            span {
                display: block;
            }
        }
    }
}
.feedback-slides-two {
    &.owl-theme {
        .owl-nav.disabled+.owl-dots {
            margin-top: 10px;
        }
        .owl-dots {
            .owl-dot {
                margin: {
                    left: 3px;
                    right: 3px;
                };
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0;
                    border: 1px solid var(--mainColor);
                    background: transparent;
                    transition: var(--transition);
                    border-radius: 50%;
                    position: relative;
    
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: 3px;
                        background-color: var(--mainColor);
                        transition: var(--transition);
                        border-radius: 50%;
                        opacity: 0;
                        visibility: hidden;
                    }
                }
                &:hover, &.active {
                    span {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.single-why-choose-box {
    margin-bottom: 30px;
    text-align: center;
    background-color: #f2f8f6;
    padding: 35px 20px;
    transition: var(--transition);

    .icon {
        line-height: 1;
        margin-bottom: 25px;
        font-size: 45px;
        color: var(--mainColor);
    }
    h3 {
        font-size: 22px;
        margin-bottom: 12px;
    }
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 0 7px rgba(0, 0, 0, .10);
        background-color: var(--whiteColor) !important;
    }
}
.col-lg-4 {
    &:nth-child(2) {
        .single-why-choose-box {
            background-color: #f3f7fa;
        }
    }
    &:nth-child(3) {
        .single-why-choose-box {
            background-color: #fef7ee;
        }
    }
}

/*================================================
Hot Deal Area CSS
=================================================*/
.hot-deal-area {
    background: {
        image: url(../../assets/img/hot-deal-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
}
.hot-deal-content {
    text-align: center;
    position: relative;
    z-index: 1;

    .sub-title {
        display: block;
        font-weight: 600;
        margin-bottom: 12px;
        color: var(--mainColor);
    }
    h2 {
        margin-bottom: 25px;
        font-size: 70px;
        line-height: 1.1;

        span {
            color: var(--mainColor);
        }
    }
    #timer {
        margin-bottom: 40px;

        div {
            background-color: var(--whiteColor);
            color: var(--blackColor);
            box-shadow: 0 0 7px rgba(0, 0, 0, .10);
            width: 100px;
            height: 105px;
            border-radius: 5px;
            font: {
                size: 35px;
                weight: 700;
            };
            margin: {
                left: 10px;
                right: 10px;
            };
            span {
                display: block;
                color: var(--optionalColor);
                margin-top: 0;
                font: {
                    size: 16px;
                    weight: 600;
                };
            }
        }
    }
    .back-text {
        position: absolute;
        left: 0;
        right: 0;
        top: -35px;
        z-index: -1;
        line-height: 1;
        color: var(--blackColor);
        opacity: .04;
        font: {
            size: 270px;
            weight: 700;
        };
    }
}

/*================================================
Brands Area CSS
=================================================*/
.single-brands-item {
    margin-bottom: 30px;
    text-align: center;
    border-right: 1px solid #eeeeee;
}
.col-lg-2 {
    &:nth-child(6), &:nth-child(12), &:nth-child(18) {
        .single-brands-item {
            border-right: none;
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-post {
    margin-bottom: 30px;

    .post-image {
        overflow: hidden;

        img {
            transition: var(--transition);
        }
    }
    .post-content {
        margin-top: 25px;

        h3 {
            margin-bottom: 15px;
            font-size: 21px;
        }
        .post-meta {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-right: 20px;
                position: relative;
                color: var(--optionalColor);
                font: {
                    weight: 600;
                };
                &::before {
                    content: '';
                    position: absolute;
                    right: -11px;
                    top: 10.8px;
                    width: 1px;
                    height: 14px;
                    background-color: #b3afaf;
                }
                &:last-child {
                    margin-right: 0;

                    &::before {
                        display: none;
                    }
                }
                img {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 5px;
                }
                a {
                    color: var(--optionalColor);

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
    &:hover {
        .post-image {
            img {
                transform: scale(1.05);
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    background-color: #f7f8fa;
    padding: 25px;

    .article-image-slides {
        &.owl-theme {
            .owl-nav {
                margin-top: 0;
                opacity: 0;
                visibility: hidden;
                transition: var(--transition);
    
                [class*=owl-] {
                    color: #87a1c1;
                    font-size: 18px;
                    margin: 0;
                    padding: 0;
                    background: var(--whiteColor);
                    width: 45px;
                    height: 45px;
                    line-height: 40px;
                    position: absolute;
                    left: 20px;
                    transition: var(--transition);
                    top: 50%;
                    transform: translateY(-50%);
                    border: {
                        radius: 50%;
                        style: solid;
                        color: var(--whiteColor);
                        width: 1.5px;
                    };
                    &.owl-next {
                        left: auto;
                        right: 20px;
                    }
                    &:hover {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                        border-color: var(--mainColor);
                    }
                }
            }
            &:hover {
                .owl-nav {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .article-video {
        iframe {
            width: 100%;
            height: 400px;
            border: none !important;
        }
    }
    .article-content {
        margin-top: 30px;

        .entry-meta {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
    
                li {
                    margin-right: 20px;
                    position: relative;
                    display: inline-block;
                    border-right: 1px solid #eeeeee;
                    font: {
                        weight: 600;
                    };
                    padding: {
                        right: 20px;
                        left: 45px;
                    };
                    i {
                        font-size: 32px;
                        color: #cfcfcf;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    span {
                        display: block;
                        color: var(--mainColor);
                        text-transform: uppercase;
                        margin-bottom: 2px;
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                    }
                    &:last-child {
                        padding-right: 0;
                        margin-right: 0;
                        border-right: none;
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 15px;
                top: 25px;
            };
            font: {
                size: 22px;
            };
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            };
            li {
                margin-bottom: 15px;
                position: relative;
                padding-left: 26px;
                color: var(--optionalColor);

                i {
                    color: var(--mainColor);
                    display: inline-block;
                    font-size: 20px;
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #f1ecec;
        padding-top: 30px;
        margin: {
            top: 30px;
        };
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: var(--optionalColor);
            
            span {
                display: inline-block;
                color: var(--blackColor);
                margin-right: 3px;
                position: relative;
                top: 3px;
                font: {
                    size: 20px;
                };
            }
            a {
                display: inline-block;
                font: {
                    weight: 600;
                };
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;
            
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                };
                li {
                    display: inline-block;

                    span {
                        display: inline-block;
                        margin-right: 3px;
                        font-weight: 600;
                        position: relative;
                        top: -1px;
                    }
                    a {
                        display: block;
                        width: 32px;
                        height: 32px;
                        line-height: 34px;
                        border-radius: 50%;
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                        border: 1px solid var(--mainColor);
                        text-align: center;
                        font-size: 17px;
                        margin-left: 2px;
        
                        &:hover, &:focus {
                            color: var(--mainColor);
                            background-color: transparent;
                        }
                        &.facebook {
                            background-color: #3b5998;
                            border-color: #3b5998;
                            color: var(--whiteColor);

                            &:hover, &:focus {
                                color: #3b5998;
                                background-color: transparent;
                            }
                        }
                        &.twitter {
                            background-color: #1da1f2;
                            border-color: #1da1f2;
                            color: var(--whiteColor);

                            &:hover, &:focus {
                                color: #1da1f2;
                                background-color: transparent;
                            }
                        }
                        &.linkedin {
                            background-color: #007bb5;
                            border-color: #007bb5;
                            color: var(--whiteColor);

                            &:hover, &:focus {
                                color: #007bb5;
                                background-color: transparent;
                            }
                        }
                        &.instagram {
                            background-color: #c13584;
                            border-color: #c13584;
                            color: var(--whiteColor);

                            &:hover, &:focus {
                                color: #c13584;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    .article-author {
        margin-top: 30px;
        border-radius: 5px;
        background: var(--whiteColor);

        .author-profile-header {
            height: 115px;
            border-radius: 5px 5px 0 0;
            background: {
                color: var(--mainColor);
                image: url(../../assets/img/bg.jpg);
                position: center center;
                size: cover;
                repeat: no-repeat;
            };
        }
        .author-profile {
            padding: 0 25px 25px;

            .author-profile-title {
                position: relative;
                z-index: 1;
                margin: {
                    top: -45px;
                };
                img {
                    display: inline-block;
                    border: 3px solid var(--whiteColor);
                    width: 100px;
                    height: 100px;
                    margin-bottom: 25px;
                }
                h4 {
                    margin-bottom: 5px;
                    font: {
                        size: 20px;
                    };
                }
                span {
                    display: block;
                    margin-bottom: 12px;
                    color: var(--optionalColor);
                }
            }
        }
    }
}
blockquote, .blockquote {
    overflow: hidden;
    background-color: var(--whiteColor);
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    };
    p {
        color: var(--blackColor);
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 700;
            size: 20px !important;
        };
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        position: absolute;
        animation: fade-up 1.5s infinite linear;
        left: 50px;
        top: -50px;
        z-index: -1;
        content: "\f111";
        font: {
            family: Flaticon;
            size: 135px;
        };
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: var(--mainColor);
        margin: {
            top: 20px;
            bottom: 20px;
        };
    }
}
.drodo-post-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    margin-top: 30px;
    padding: {
        top: 30px;
        bottom: 30px;
    };
    border: {
        top: 1px solid #f1ecec;
        bottom: 1px solid #f1ecec;
    };
}
.prev-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
        right: 15px;
    };
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-prev {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .prev-link-info-wrapper {
                color: var(--mainColor);
            }
        }
    }
    .image-prev {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-right: 20px;
        position: relative;
        transition: var(--transition);

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: var(--mainColor);
            visibility: hidden;
            transition: var(--transition);
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: var(--whiteColor);
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
            font: {
                size: var(--fontSize);
                weight: 700;
            };
        }
    }
    .prev-link-info-wrapper {
        color: var(--blackColor);
        transition: var(--transition);
    }
    .prev-title {
        display: inline-block;
        font: {
            weight: 700;
            size: 16px;
        };
    }
    .meta-wrapper {
        display: block;
        color: var(--optionalColor);
        text-transform: capitalize;
        margin-top: 7px;
        font: {
            weight: 600;
            size: 14px;
        };
    }
}
.next-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right;
    padding: {
        left: 15px;
    };
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-next {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .next-link-info-wrapper {
                color: var(--mainColor);
            }
        }
    }
    .image-next {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-left: 20px;
        position: relative;
        transition: var(--transition);

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: var(--mainColor);
            visibility: hidden;
            transition: var(--transition);
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: var(--whiteColor);
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
            font: {
                size: var(--fontSize);
                weight: 700;
            };
        }
    }
    .next-link-info-wrapper {
        transition: var(--transition);
        color: var(--blackColor);
    }
    .next-title {
        display: inline-block;
        font: {
            weight: 700;
            size: 16px;
        };
    }
    .meta-wrapper {
        display: block;
        color: var(--optionalColor);
        text-transform: capitalize;
        margin-top: 7px;
        font: {
            weight: 600;
            size: 14px;
        };
    }
}
.comments-area {
    margin-top: 30px;

    .comments-title {
        line-height: initial;
        margin: {
            bottom: 30px;
        };
        font: {
            size: 22px;
        };
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px dashed #eeeeee;
        padding-left: 70px;
        color: var(--blackColor);
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px dashed #ded9d9;
                color: var(--blackColor);
                display: inline-block;
                padding: 6px 20px;
                border-radius: 30px;
                text-transform: uppercase;
                font: {
                    size: 13px;
                    weight: 600;
                };
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                }
            }
        }
    }
    .comment-author {
        font-size: 15px;
        margin-bottom: 8px;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 700;
            };
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: .8em;
        color: var(--optionalColor);
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        };
        a {
            display: inline-block;
            color: var(--optionalColor);

            &:hover {
                color: var(--mainColor);
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            font: {
                size: 22px;
            };
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 20px;
                top: 10px;
            };
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            float: left;
            width: 100%;
        }
        label {
            display: none;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: var(--whiteColor);
            border: none;
            padding: 1px 0 0 15px;
            height: 50px;
            outline: 0;
            border-radius: 3px;
            color: var(--blackColor);
            font: {
                size: 15px;
            };
            &::placeholder {
                transition: var(--transition);
                color: var(--optionalColor);
            }
            &:focus {
                &::placeholder {
                    color: transparent;
                }
            }
        }
        textarea {
            height: auto !important;
            padding-top: 15px;
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin: {
                bottom: 20px;
                top: 15px;
            };
            input {
                position: absolute;
                left: 0;
                top: 6px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: var(--optionalColor);
                font: {
                    weight: normal;
                    size: var(--fontSize);
                };
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: var(--mainColor);
                border: none;
                color: var(--whiteColor);
                padding: 11px 30px;
                display: inline-block;
                cursor: pointer;
                text-transform: capitalize;
                transition: var(--transition);
                border-radius: 0;
                font: {
                    weight: 700;
                    size: 15px;
                };
                &:hover, &:focus {
                    color: var(--whiteColor);
                    background: var(--blackColor);
                }
            }
        }
    }
}

/*================================================
Facility Area CSS
=================================================*/
.single-facility-box {
    margin-bottom: 30px;
    position: relative;
    border-right: 1px solid #e2e2e2;
    padding-left: 62px;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        line-height: 1;
        color: var(--mainColor);
        font-size: 45px;
    }
    h3 {
        margin-bottom: 8px;
        font-size: 22px;
    }
    p {
        font-size: 14.5px;
        line-height: initial;
    }
}
.col-lg-3 {
    &:nth-child(4), &:nth-child(8), &:nth-child(12) {
        .single-facility-box {
            border-right: none;
        }
    }
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden;
    cursor: zoom-in;

    a {
        display: block;
        border-radius: 5px;
        
        img {
            transition: var(--transition);
        }
    }
    &:hover {
        a {
            img {
                transform: scale(1.1);
                filter: blur(2px);
            }
        }
    }
}

/*================================================
History Area CSS
=================================================*/
.history-timeline {
    position: relative;
    list-style-type: none;
    padding-left: 0;
    z-index: 1;
    margin: {
        left: auto;
        right: auto;
        bottom: 0;
    };
    &::before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #ebebeb;
        top: 0;
        left: 130px;
        position: absolute;
    }
    .timeline-block {
        position: relative;
        padding-left: 165px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;

            &::before {
                display: none;
            }
        }
        &::before {
            content: "\ea17";
            position: absolute;
            left: 116px;
            color: var(--mainColor);
            bottom: -25px;
            animation: fade-down 1.5s infinite linear;
            font: {
                family: "boxicons" !important;
                size: 30px;
            };
        }
        .timeline-date {
            text-align: right;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: var(--optionalColor);
            font: {
                size: 15px;
            };
            span {
                display: block;
                margin-bottom: 3px;
                color: var(--blackColor);
                font-weight: 700;
            }
            sup {
                color: var(--blackColor);
                font-weight: 600;
            }
        }
        .timeline-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 126px;

            .dot-badge {
                background-color: var(--mainColor);
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }
        }
        .timeline-content {
            background-color: #f7f8fa;
            padding: 30px;

            .content {
                padding: {
                    left: 5px;
                    right: 15px;
                };
                h3 {
                    margin-bottom: 15px;
                    font-size: 23px;
                }
            }
        }
    }
}

/*================================================
Doctors Area CSS
=================================================*/
.single-doctors-box {
    margin-bottom: 30px;

    .image {
        position: relative;
        text-align: center;

        .social {
            padding-left: 0;
            list-style-type: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;
            margin: {
                left: auto;
                right: auto;
                bottom: 0;
            };
            li {
                display: inline-block;
                transform: translateY(30px);
                transition: all 0.3s ease-in-out 0s;
                opacity: 0;
                visibility: hidden;
                margin: {
                    left: 1px;
                    right: 1px;
                };
                a {
                    display: block;
                    width: 33px;
                    height: 33px;
                    background-color: var(--whiteColor);
                    text-align: center;
                    position: relative;
                    font-size: 20px;
                    color: var(--blackColor);
                    border-radius: 2px;
                    transition: var(--transition);
    
                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: var(--whiteColor);
                        background-color: var(--mainColor);
                    }
                }
                &:nth-child(1) {
                    transition-delay: 0.1s;
                }
                &:nth-child(2) {
                    transition-delay: 0.2s;
                }
                &:nth-child(3) {
                    transition-delay: 0.3s;
                }
                &:nth-child(4) {
                    transition-delay: 0.4s;
                }
                &:nth-child(5) {
                    transition-delay: 0.5s;
                }
            }
        }
    }
    .content {
        margin-top: 25px;

        h3 {
            margin-bottom: 5px;
            font-size: 22px;
        }
        span {
            color: var(--mainColor);
            font-weight: 600;
            display: block;
        }
    }
    &:hover {
        .image {
            .social {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

/*================================================
Funfacts Area CSS
=================================================*/
.single-funfacts-box {
    margin-bottom: 30px;
    text-align: center;

    h3 {
        font-size: 45px;
        color: var(--mainColor);
        margin-bottom: 8px;
        line-height: 1;
    }
    span {
        display: block;
        font: {
            weight: 600;
            size: 17px;
        };
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    text-align: center;
    background-color: #f7f8fa;
    padding: {
        top: 80px;
        bottom: 80px;
    };
}
.page-title-content {
    h1 {
        margin-bottom: 0;
        font-size: 40px;
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 12px;
        };
        li {
            display: inline-block;
            position: relative;
            color: var(--mainColor);
            font: {
                weight: 600;
                size: 16px;
            };
            margin: {
                left: 10px;
                right: 10px;
            };
            &::before {
                width: 1px;
                height: 14px;
                content: '';
                position: absolute;
                right: -13px;
                top: 6.2px;
                background-color: #404040;
                transform: rotate(10deg);
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            a {
                display: inline-block;
                color: var(--optionalColor);

                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    margin-top: 25px;

    .page-numbers {
        width: 40px;
        height: 40px;
        background-color: var(--whiteColor);
        box-shadow: 0 0 15px 2px #e4e4ee;
        color: var(--blackColor);
        text-align: center;
        display: inline-block;
        border-radius: 0;
        line-height: 41px;
        position: relative;
        margin: {
            left: 3px;
            right: 3px;
        };
        font: {
            weight: 700;
            size: 16px;
        };
        &:hover, &.current {
            color: var(--whiteColor);
            background-color: var(--mainColor);
        }
        i {
            position: relative;
            top: 1.5px;
        }
    }
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
    padding-left: 10px;

    &.widget-left-sidebar {
        padding: {
            right: 10px;
            left: 0;
        };
    }
    .widget {
        margin-bottom: 30px;
        background-color: #f7f8fa;
        padding: 25px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 7px;
            border-bottom: 1px solid #eeeeee;
            font: {
                size: 21px;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50px;
                height: 1px;
                bottom: -1px;
                background-color: var(--mainColor);
            }
        }
    }
    .widget_search {
        form {
            position: relative;
            
            .screen-reader-text {
                display: none;
            }
            label {
                display: block;
                margin-bottom: 0;
            }
            .search-field {
                height: 50px;
                color: var(--blackColor);
                background-color: var(--whiteColor);
                display: block;
                width: 100%;
                border-radius: 5px;
                padding: 0 0 0 15px;
                border: none;
                transition: var(--transition);
                font: {
                    weight: 500;
                };
                &::placeholder {
                    color: var(--optionalColor);
                    transition: var(--transition);
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                border: none;
                background-color: var(--whiteColor);
                color: var(--mainColor);
                height: 40px;
                width: 40px;
                position: absolute;
                right: 5px;
                padding: 0;
                transition: var(--transition);
                top: 5px;
                font-size: 20px;

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover, &:focus {
                    border-radius: 5px;
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    .widget_drodo_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 5px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                        background-image: url(../../assets/img/blog/blog-img1.jpg);
                    }
                    &.bg2 {
                        background-image: url(../../assets/img/blog/blog-img2.jpg);
                    }
                    &.bg3 {
                        background-image: url(../../assets/img/blog/blog-img3.jpg);
                    }
                }
                &::before, &::after {
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                    content: '';
                    background-color: var(--whiteColor);
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: var(--optionalColor);
                    text-transform: uppercase;
                    margin: {
                        top: -2px;
                        bottom: 5px;
                    };
                    font: {
                        size: 13px;
                    };
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 17px;
                        weight: 700;
                    };
                    a {
                        display: inline-block;
                    }
                }
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                overflow: hidden;
                color: var(--blackColor);
                padding-left: 18px;
                font: {
                    weight: 600;
                    size: 14.5px;
                };
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: var(--mainColor);
                    height: 8px;
                    width: 8px;
                    content: '';
                    border-radius: 50%;
                    left: 0;
                    top: 7px;
                    position: absolute;
                }
                a {
                    color: var(--blackColor);
                    display: inline-block;

                    &:hover {
                        color: var(--mainColor);
                    }
                }
                .post-count {
                    font-size: 14px;
                    display: inline-block;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 17px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            background: var(--whiteColor);
            color: var(--blackColor);
            padding: 7px 15px;
            border: none;
            border-radius: 3px;
            font: {
                weight: 600;
                size: 14px !important;
            };
            margin: {
                top: 8px;
                right: 4px;
            };
            &:hover, &:focus {
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }
        }
    }
    .widget_instagram {
        ul {
            padding-left: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            margin: {
                bottom: 0;
                left: -5px;
                right: -5px;
                top: -10px;
            };
            li {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                text-align: center;
                padding: {
                    left: 5px;
                    right: 5px;
                    top: 10px;
                };
                .box {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
    
                    .link-btn {
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 3;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 30px;
                        transition: var(--transition);
                        opacity: 0;
                        visibility: hidden;
                        color: var(--whiteColor);
                        z-index: 2;
                    }
                    img {
                        transition: var(--transition);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 1;
                        background-color: var(--mainColor);
                        transition: var(--transition);
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: 0.5;
                            visibility: visible;
                        }
                        img {
                            transform: scale(1.3);
                        }
                        i {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .widget_insight {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: -1px;

                a {
                    position: relative;
                    display: block;
                    padding: 15px 25px 15px 40px;
                    color: var(--blackColor);
                    border: 1px solid #eeeeee;
                    background-color: var(--whiteColor);
                    font: {
                        weight: 700;
                        size: 15px;
                    };
                    &::before {
                        width: 5px;
                        height: 5px;
                        transition: var(--transition);
                        background-color: var(--optionalColor);
                        content: '';
                        position: absolute;
                        left: 25px;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 50%;
                    }
                    &:hover, &.active {
                        border-color: var(--mainColor);
                        background-color: var(--mainColor);
                        color: var(--whiteColor);

                        &::before {
                            background-color: var(--whiteColor);
                        }
                    }
                }
                &.active {
                    a {
                        border-color: var(--mainColor);
                        background-color: var(--mainColor);
                        color: var(--whiteColor);

                        &::before {
                            background-color: var(--whiteColor);
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
    padding: 40px 50px;
    border-radius: 5px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 10px rgba(51,51,51,.1);
    margin-right: 30px;

    h2 {
        margin-bottom: 25px;
        font: {
            size: 30px;
        };
    }
    form {
        .form-group {
            margin-bottom: 25px;

            label {
                display: block;
                margin-bottom: 10px;
                color: var(--blackColor);
                font-weight: 600;
            }
        }
        .remember-me-wrap {
            margin-bottom: 0;

            [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
                display: none;
            }
            [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                margin-bottom: 0;
                color: var(--optionalColor);
                font: {
                    weight: 600;
                };
            }
            [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 19px;
                height: 19px;
                transition: all 0.2s ease;
                border: 1px solid #f5f5f5;
                border-radius: 3px;
                background: #f5f5f5;
            }
            [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                content: '';
                width: 8px;
                height: 8px;
                background: var(--mainColor);
                position: absolute;
                top: 5.5px;
                left: 6px;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
            [type="checkbox"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            [type="checkbox"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
            [type="checkbox"]:hover + label:before {
                border-color: var(--mainColor);
            }
            [type="checkbox"]:checked + label:before {
                border-color: var(--mainColor);
            }
        }
        .lost-your-password-wrap {
            text-align: right;

            a {
                display: inline-block;
                position: relative;
                font-weight: 600;
                line-height: 1.3;

                &::before {
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '';
                    transition: var(--transition);
                    background-color: #eeeeee;
                }
                &::after {
                    width: 0;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    transition: var(--transition);
                    bottom: 0;
                    content: '';
                    background-color: var(--mainColor);
                }
                &:hover {
                    &::before {
                        width: 0;
                    }
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
        button {
            margin-top: 22px;
            border: none;
            display: block;
            text-align: center;
            overflow: hidden;
            color: var(--whiteColor);
            background-color: var(--mainColor);
            transition: var(--transition);
            width: 100%;
            border-radius: 5px;
            padding: 14px 30px;
            font: {
                weight: 700;
            };
            &:hover {
                background-color: var(--blackColor);
                color: var(--whiteColor);
            }
        }
    }
}
.register-form {
    padding: {
        top: 30px;
        left: 30px;
    };
    h2 {
        margin-bottom: 25px;
        font: {
            size: 30px;
        };
    }
    form {
        .form-group {
            margin-bottom: 25px;

            label {
                display: block;
                margin-bottom: 10px;
                color: var(--blackColor);
                font-weight: 600;
            }
        }
        .description {
            font-style: italic;
            font-size: 13.5px;
            margin: {
                top: -10px;
                bottom: 0;
            };
        }
        button {
            margin-top: 22px;
            border: none;
            display: block;
            text-align: center;
            overflow: hidden;
            color: var(--whiteColor);
            background-color: var(--mainColor);
            transition: var(--transition);
            width: 100%;
            border-radius: 5px;
            padding: 14.5px 30px;
            font: {
                weight: 700;
            };
            &:hover {
                background-color: var(--blackColor);
                color: var(--whiteColor);
            }
        }
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-content {
    text-align: center;

    h3 {
        margin: {
            bottom: 20px;
            top: 45px;
        };
        font: {
            size: 40px;
        };
    }
    p {
        max-width: 550px;
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
        };
    }
    .default-btn {
        margin-top: 30px;
    }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;
    background: {
        image: url(../../assets/img/coming-soon-bg.jpg);
        position: top center;
        size: cover;
        repeat: no-repeat;
    };
}
.coming-soon-content {
    max-width: 700px;
    background: var(--whiteColor);
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    padding: 40px 60px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    margin: {
        left: auto;
        right: auto;
    };
    .logo {
        display: inline-block;
    }
    h2 {
        font: {
            size: 48px;
        };
        margin: {
            top: 30px;
            bottom: 0;
        };
    }
    #timer {
        margin-top: 40px;

        div {
            background-color: var(--blackColor);
            color: var(--whiteColor);
            width: 100px;
            height: 105px;
            border-radius: 5px;
            font: {
                size: 40px;
                weight: 700;
            };
            margin: {
                left: 10px;
                right: 10px;
            };
            span {
                display: block;
                margin-top: -2px;
                font: {
                    size: 16.5px;
                    weight: 600;
                };
            }
        }
    }
    form {
        position: relative;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
            top: 50px;
        };
        .form-group {
            margin-bottom: 25px;
            width: 100%;
            position: relative;

            .label-title {
                margin-bottom: 0;
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                pointer-events: none;
                width: 100%;
                height: 100%;
                color: var(--blackColor);

                i {
                    position: absolute;
                    left: 0;
                    transition: var(--transition);
                    top: 12px;
                    font: {
                        size: 22px;
                    };
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    transition: var(--transition);
                    background: var(--mainColor);
                }
            }
            .input-newsletter {
                border-radius: 0;
                border: none;
                border-bottom: 2px solid #eeeeee;
                padding: 0 0 0 32px;
                color: var(--blackColor);
                height: 45px;
                display: block;
                width: 100%;
                transition: var(--transition);
                font: {
                    size: 16px;
                    weight: 400;
                };
                &::placeholder {
                    color: #A1A1A1;
                    transition: var(--transition);
                }
                &:focus {                    
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
        }
        .default-btn {
            border-radius: 0;
            padding: {
                top: 12px;
                bottom: 12px;
                left: 30px;
                right: 30px;
            };
        }
        .validation-danger {
            margin-top: 15px;
            color: red;
        }
        .validation-success {
            margin-top: 15px;
        }
        p {
            margin: {
                bottom: 0;
                top: 20px;
            };
        }
    }
}

/*================================================
Purchase Guide Area CSS
=================================================*/
.purchase-guide-content {
    img {
        margin-bottom: 30px;
    }
    h3 {
        margin: {
            bottom: 15px;
            top: 25px;
        };
        font: {
            size: 22px;
        };
    }
    ol, ul {
        margin-top: 20px;

        li {
            margin-bottom: 10px;
            color: var(--optionalColor);
            line-height: 1.8;
        }
    }
}

/*================================================
Privacy Policy Area CSS
=================================================*/
.privacy-policy-content {
    img {
        margin-bottom: 30px;
    }
    h3 {
        margin: {
            bottom: 15px;
            top: 25px;
        };
        font: {
            size: 22px;
        };
    }
    ol, ul {
        margin-top: 20px;

        li {
            margin-bottom: 10px;
            color: var(--optionalColor);
            line-height: 1.8;
        }
    }
}

/*================================================
Terms Of Service Area CSS
=================================================*/
.terms-of-service-content {
    img {
        margin-bottom: 30px;
    }
    h3 {
        margin: {
            bottom: 15px;
            top: 25px;
        };
        font: {
            size: 22px;
        };
    }
    ol, ul {
        margin-top: 20px;

        li {
            margin-bottom: 10px;
            color: var(--optionalColor);
            line-height: 1.8;
        }
    }
}

/*================================================
Customer Service Area CSS
=================================================*/
.customer-service-content {
    h3 {
        position: relative;
        padding-left: 30px;
        margin: {
            bottom: 20px;
        };
        font: {
            size: 22px;
            weight: 700;
        }
        i {
            position: absolute;
            top: 0;
            left: 0;
            color: var(--mainColor);
        }
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;

        li {
            color: var(--optionalColor);
            margin-bottom: 12px;
            position: relative;
            padding-left: 13px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 9px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: var(--blackColor);
            }
            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
                color: var(--optionalColor);

                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion-tab {
    .tabs {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 60px;
        text-align: center;

        li {
            margin: {
                left: 10px;
                right: 10px;
            };
            a {
                color: var(--blackColor);
                text-decoration: none;
                border-radius: 5px;
                padding: 20px;
                display: block;
                box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, .13)!important;
                font: {
                    weight: 700;
                };
                i {
                    color: var(--mainColor);
                    transition: var(--transition);
                    font: {
                        size: 30px;
                        weight: 400;
                    };
                }
                span {
                    display: block;
                    margin-top: 8px;
                }
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);

                    i {
                        color: var(--whiteColor);
                    }
                }
            }
            &.current {
                a {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);

                    i {
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
}
.faq-accordion {
    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        .accordion-item {
            border-radius: 5px;
            display: block;
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
            background: var(--whiteColor);
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion-title {
            padding: 20px 25px 17px 60px;
            color: var(--blackColor);
            text-decoration: none;
            position: relative;
            display: block;
            font: {
                size: 16px;
                weight: 700;
            };
            i {
                position: absolute;
                left: 25px;
                top: 18px;
                font-size: 25px;
                transition: var(--transition);
            }
            &.active {
                i {
                    transform: rotate(180deg);
                }
            }
        }
        .accordion-content {
            display: none;
            position: relative;
            margin-top: -5px;
            padding: {
                bottom: 20px;
                right: 25px;
                left: 60px;
            };
            p {
                line-height: 1.8;
            }
            &.show {
                display: block;
            }
        }
    }
}
.tab {
    .tabs-item {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
}

/*================================================
Tracking Order Area CSS
=================================================*/
.track-order-content {
    max-width: 700px;
    background-color: var(--whiteColor);
    box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, .1);
    text-align: center;
    padding: 40px;
    margin: {
        left: auto;
        right: auto;
    };
    form {
        padding: {
            left: 30px;
            right: 30px;
        };
        .form-group {
            text-align: left;

            label {
                display: block;
                color: var(--blackColor);
                margin-bottom: 9px;
                text-transform: uppercase;
                font: {
                    size: 14px;
                    weight: 600;
                };
            }
        }
        .default-btn {
            margin-top: 5px;
            padding: {
                left: 30px;
                right: 30px;
                top: 12px;
                bottom: 12px;
            };
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.single-contact-info-box {
    text-align: center;
    margin-bottom: 30px;
    background-color: var(--whiteColor);
    box-shadow: 0 0 7px rgba(0, 0, 0, .10);
    padding: 30px 20px;

    .icon {
        font-size: 40px;
        line-height: 1;
        margin-bottom: 25px;
        color: var(--mainColor);
    }
    h3 {
        font-size: 22px;
        margin-bottom: 12px;
    }
    p {
        margin-bottom: 0;

        a {
            color: var(--optionalColor);
            display: inline-block;

            &:hover {
                color: var(--mainColor);
            }
        }
    }
}
.contact-form {
    padding-right: 30px;

    .sub-title {
        display: block;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--mainColor);
    }
    h2 {
        margin-bottom: 0;
        font-size: 30px;
    }
    form {
        margin-top: 35px;

        label {
            display: block;
            margin-bottom: 10px;
            font-weight: 600;
            color: var(--blackColor);
        }
        .form-group {
            margin-bottom: 25px;
        }
        .default-btn {
            padding: 13px 35px;
        }
        .help-block {
            &.with-errors {
                color: red;
                margin: {
                    bottom: 0;
                    top: 15px;
                };
            }
        }
    }
}
#maps {
    iframe {
        height: 500px;
        width: 100%;
        margin-bottom: -7px;
        border: none;
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    padding-top: 20px;
}
.single-footer-widget {
    margin-bottom: 30px;

    .logo {
        margin-bottom: 20px;
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: block;
            margin-bottom: 12px;
            color: var(--optionalColor);

            span {
                color: var(--blackColor);
                font-weight: 600;
            }
            a {
                color: var(--optionalColor);

                &:hover {
                    color: var(--mainColor);
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 12px;
            bottom: 0;
        };
        li {
            display: inline-block;
            margin-right: 5px;
            
            a {
                font-size: 22px;
                line-height: 1;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    h3 {
        margin-bottom: 25px;
        font-size: 22px;
        position: relative;
        padding-bottom: 5px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 80px;
            height: 1px;
            background-color: var(--mainColor);
        }
    }
    .link-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;

            a {
                display: inline-block;
                color: var(--optionalColor);
                position: relative;
                padding-left: 13px;

                &::before {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: var(--optionalColor);
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transition: var(--transition);
                    transform: translateY(-50%);
                }
                &:hover {
                    color: var(--mainColor);
                    transform: translateX(7px);

                    &::before {
                        background-color: var(--mainColor);
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .newsletter-form {
        .input-newsletter {
            display: block;
            width: 100%;
            border: 1px solid #eeeeee;
            background-color: transparent;
            color: var(--blackColor);
            height: 50px;
            transition: var(--transition);
            padding-left: 15px;
            border-radius: 5px;
            font: {
                weight: 400;
            };
            &::placeholder {
                color: #999999;
                transition: var(--transition);
            }
            &:focus {
                border-color: var(--mainColor);

                &::placeholder {
                    color: transparent;
                }
            }
        }
        .validation-danger {
            color: red;
            margin-top: 15px;
        }
        .validation-success {
            margin-top: 15px;
        }
        .default-btn {
            display: block;
            width: 100%;
            margin-top: 12px;
            padding: {
                left: 25px;
                right: 25px;
            };
        }
    }
}
.footer-bottom-area {
    background-color: #f7f8fa;
    margin-top: 40px;
    padding: {
        top: 25px;
        bottom: 25px;
    };
    p {
        line-height: initial;

        i {
            position: relative;
            top: 1px;
        }
        a {
            font-weight: 600;
            color: var(--mainColor);
        }
    }
    .payment-types {
        text-align: right;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-right: 10px;
                color: var(--optionalColor);
                font-weight: 500;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: 20px;
    right: 20px;
    background-color: var(--mainColor);
    color: var(--whiteColor);
    z-index: 4;
    width: 43px;
    text-align: center;
    height: 45px;
    opacity: 0;
    visibility: hidden;
    font-size: 27px;
    transition: var(--transition);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    i {
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        margin: {
            left: auto;
            right: auto;
        };
    }
    &.active {
        opacity: 1;
        visibility: visible;
        bottom: 20px;
    }
    &:hover {
        background-color: var(--blackColor);
        color: var(--whiteColor);
    }
}

/*================================================
Modal CSS
=================================================*/
.productsQuickView {
    .modal-dialog {
        max-width: 900px;
        margin: 0 auto;
    }
    .modal-content {
        border: none;
        padding: 40px;
        border-radius: 0;

        button {
            &.close {
                position: absolute;
                right: 0;
                top: 0;
                outline: 0;
                opacity: 1;
                color: var(--blackColor);
                transition: var(--transition);
                line-height: 38px;
                padding: 0;
                margin: 0;
                font-size: 25px;
                width: 35px;
                text-shadow: unset;
                height: 35px;

                &:hover {
                    background-color: red;
                    color: var(--whiteColor);
                }
            }
        }
        .products-content {
            h3 {
                margin-bottom: 0;
                font: {
                    size: 22px;
                    weight: 600;
                };
            }
            .price {
                transition: var(--transition);
                color: var(--mainColor);
                margin: {
                    top: 12px;
                    bottom: 15px;
                };
                font: {
                    size: var(--fontSize);
                    weight: 600;
                };
                .old-price {
                    text-decoration: line-through;
                    color: #999999;
                    font-weight: 500;
                }
            }
            .products-review {
                .rating {
                    display: inline-block;
                    padding-right: 5px;

                    i {
                        color: #f49f0b;
                        display: inline-block;
                        margin-right: -2px;
                    }
                }
                .rating-count {
                    display: inline-block;
                    color: var(--blackColor);
                    border-bottom: 1px solid var(--blackColor);
                    line-height: initial;
                    position: relative;
                    top: -1px;

                    &:hover {
                        color: var(--mainColor);
                        border-color: var(--mainColor);
                    }
                }
            }
            .products-info {
                list-style-type: none;
                padding-left: 0;
                margin: {
                    top: 20px;
                    bottom: 0;
                };
                li {
                    color: var(--blackColor);
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        color: var(--optionalColor);
                    }
                    a {
                        display: inline-block;
                        font-weight: 600;
                        color: var(--blackColor);

                        &:hover {
                            color: var(--blackColor);
                        }
                    }
                }
            }
            .products-color-switch {
                margin-top: 8px;

                h4 {
                    font-size: 16px;
                    margin-bottom: 6px;
                }
                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin: {
                        bottom: 0;
                        top: -4px;
                        left: -4px;
                    };
                    li {
                        display: inline-block;
                        margin: {
                            left: 4px;
                            top: 4px;
                        }
                        a {
                            display: inline-block;
                            position: relative;
                            border: 1px solid transparent;
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
        
                            &::before {
                                position: absolute;
                                content: '';
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                background: #eeeeee;
                                border-radius: 50%;
                                margin: 2px;
                                transition: var(--transition);
                            }
                            &:hover, &:focus {
                                border-color: var(--blackColor);
                            }
                            &.color-white {
                                &::before {
                                    background: var(--whiteColor);
                                    border: 1px solid #eeeeee;
                                }
                            }
                            &.color-black {
                                &::before {
                                    background: var(--blackColor);
                                }
                            }
                            &.color-red {
                                &::before {
                                    background: red;
                                }
                            }
                            &.color-blue {
                                &::before {
                                    background: blue;
                                }
                            }
                            &.color-green {
                                &::before {
                                    background: green;
                                }
                            }
                            &.color-yellow {
                                &::before {
                                    background: yellow;
                                }
                            }
                            &.color-yellowgreen {
                                &::before {
                                    background: yellowgreen;
                                }
                            }
                            &.color-pink {
                                &::before {
                                    background: pink;
                                }
                            }
                            &.color-violet {
                                &::before {
                                    background: violet;
                                }
                            }
                            &.color-teal {
                                &::before {
                                    background: teal;
                                }
                            }
                            &.color-plum {
                                &::before {
                                    background: plum;
                                }
                            }
                            &.color-lime {
                                &::before {
                                    background: lime;
                                }
                            }
                            &.color-blueviolet {
                                &::before {
                                    background: blueviolet;
                                }
                            }
                        }
                        &.active {
                            a {
                                border-color: var(--blackColor);
                            }
                        }
                    }
                }
            }
            .products-size-wrapper {
                margin: {
                    top: 8px;
                    bottom: 25px;
                };
                h4 {
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin: {
                        bottom: 0;
                        top: -8px;
                        left: -8px;
                    };
                    li {
                        display: inline-block;
                        margin: {
                            left: 8px;
                            top: 8px;
                        };
                        a {
                            width: 35px;
                            height: 30px;
                            display: inline-block;
                            line-height: 28px;
                            border: 1px solid #eeeeee;
                            color: #858585;
                            text-align: center;
                            font: {
                                size: 14px;
                                weight: 600;
                            };
                            &:hover, &:focus {
                                border-color: var(--blackColor);
                                color: var(--blackColor);
                            }
                        }
                        &.active {
                            a {
                                border-color: var(--blackColor);
                                color: var(--blackColor);
                            }
                        }
                    }
                }
            }
            .products-add-to-cart {
                .input-counter {
                    max-width: 110px;
                    min-width: 110px;
                    margin-right: 10px;
                    text-align: center;
                    display: inline-block;
                    position: relative;    

                    span {
                        position: absolute;
                        top: 0;
                        background-color: transparent;
                        cursor: pointer;
                        color: #d0d0d0;
                        width: 40px;
                        height: 100%;
                        line-height: 50px;
                        transition: var(--transition);
                        font-size: 18px;

                        &.minus-btn {
                            left: 0;
                        }
                        &.plus-btn {
                            right: 0;
                        }
                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                    input {
                        height: 45px;
                        color: var(--blackColor);
                        outline: 0;
                        display: block;
                        border: none;
                        background-color: #f8f8f8;
                        text-align: center;
                        width: 100%;
                        font: {
                            size: 17px;
                            weight: 600;
                        }
                        &::placeholder {
                            color: var(--blackColor);
                        }
                    }
                }
                .default-btn {
                    top: -1px;
                }
            }
            .view-full-info {
                margin-top: 25px;
                line-height: initial;
                display: inline-block;
                position: relative;
                font: {
                    weight: 600;
                    size: 15px;
                };
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: var(--blackColor);
                    transition: var(--transition);
                }
                &:hover {
                    &::before {
                        width: 0;
                    }
                }
            }
        }
    }
}
.shoppingCartModal {
    &.modal {
        &.right {
            .modal-dialog {
                position: fixed;
                margin: auto;
                width: 320px;
                height: 100%;
                transform: translate3d(0%, 0, 0);
            }
            .modal-content {
                height: 100%;
                overflow-y: auto;
            }
            &.fade {
                .modal-dialog {
                    right: -420px;
                    transition: opacity 0.3s linear, right 0.3s ease-out;
                }
                &.show {
                    .modal-dialog {
                        right: 0;
                    }
                }
            }
        }
    }
    .modal-content {
        border-radius: 0;
        border: none;

        .modal-body {
            padding: 25px 15px;

            h3 {
                font-size: 17px;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            .products-cart-content {
                .products-cart {
                    position: relative;
                    border-bottom: 1px dashed #eeeeee;
                    margin-bottom: 15px;
                    padding: {
                        bottom: 15px;
                    };
                    .products-image {
                        margin-right: 15px;
                        width: 55px;

                        a {
                            display: block;
                        }
                    }
                    .products-content {
                        position: relative;

                        h3 {
                            font-size: 15px;
                            text-transform: initial;
                            margin-bottom: 5px;

                            a {
                                display: inline-block;
                            }
                        }
                        span {
                            font-size: 12px;
                            display: block;
                            margin-bottom: 3px;
                            color: var(--optionalColor);
                        }
                        .products-price {
                            span {
                                display: inline-block;
                                margin-bottom: 0;
                                font-size: 13px;
                                color: var(--optionalColor);

                                &.price {
                                    font-size: 14px;
                                    color: var(--blackColor);
                                }
                            }
                        }
                    }
                    .remove-btn {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        display: inline-block;
                        color: var(--optionalColor);
                        opacity: 0;
                        visibility: hidden;
                        transition: var(--transition);
    
                        &:hover {
                            color: red;
                        }
                    }
                    &:hover {
                        .remove-btn {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
            .products-cart-subtotal {
                overflow: hidden;
                margin-bottom: 15px;
                border-bottom: 1px dashed #eeeeee;
                padding-bottom: 15px;

                span {
                    text-transform: uppercase;
                    display: inline-block;
                    font: {
                        size: 15px;
                        weight: 600;
                    };
                    &.subtotal {
                        float: right;
                    }
                }
            }
            .products-cart-btn {
                .default-btn {
                    display: block;
                    width: 80%;
                    text-align: center;
                    padding: {
                        left: 25px;
                        right: 25px;
                    };
                }
            }
        }
    }
    .close {
        color: var(--blackColor);
        font-size: 30px;
        outline: 0 !important;
        line-height: initial;
        transition: var(--transition);
        position: absolute;
        right: 15px;
        top: 17px;
        z-index: 1;

        &:not(:disabled):not(.disabled){
            &:hover, &:focus{
                color: red;
                text-decoration: none;
                opacity: 1;
            }
        }
    }
}
.shoppingWishlistModal {
    &.modal {
        &.right {
            .modal-dialog {
                position: fixed;
                margin: auto;
                width: 320px;
                height: 100%;
                transform: translate3d(0%, 0, 0);
            }
            .modal-content {
                height: 100%;
                overflow-y: auto;
            }
            &.fade {
                .modal-dialog {
                    right: -420px;
                    transition: opacity 0.3s linear, right 0.3s ease-out;
                }
                &.show {
                    .modal-dialog {
                        right: 0;
                    }
                }
            }
        }
    }
    .modal-content {
        border-radius: 0;
        border: none;

        .modal-body {
            padding: 25px 15px;

            h3 {
                font-size: 17px;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            .products-cart-content {
                .products-cart {
                    position: relative;
                    border-bottom: 1px dashed #eeeeee;
                    margin-bottom: 15px;
                    padding: {
                        bottom: 15px;
                    };
                    .products-image {
                        margin-right: 15px;
                        width: 55px;

                        a {
                            display: block;
                        }
                    }
                    .products-content {
                        position: relative;

                        h3 {
                            font-size: 15px;
                            text-transform: initial;
                            margin-bottom: 5px;

                            a {
                                display: inline-block;
                            }
                        }
                        span {
                            font-size: 12px;
                            display: block;
                            margin-bottom: 3px;
                            color: var(--optionalColor);
                        }
                        .products-price {
                            span {
                                display: inline-block;
                                margin-bottom: 0;
                                font-size: 13px;
                                color: var(--optionalColor);

                                &.price {
                                    font-size: 14px;
                                    color: var(--blackColor);
                                }
                            }
                        }
                    }
                    .remove-btn {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        display: inline-block;
                        color: var(--optionalColor);
                        opacity: 0;
                        visibility: hidden;
                        transition: var(--transition);
    
                        &:hover {
                            color: red;
                        }
                    }
                    &:hover {
                        .remove-btn {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
            .products-cart-subtotal {
                overflow: hidden;
                margin-bottom: 15px;
                border-bottom: 1px dashed #eeeeee;
                padding-bottom: 15px;

                span {
                    text-transform: uppercase;
                    display: inline-block;
                    font: {
                        size: 15px;
                        weight: 600;
                    };
                    &.subtotal {
                        float: right;
                    }
                }
            }
            .products-cart-btn {
                .default-btn {
                    display: block;
                    width: 80%;
                    text-align: center;
                    padding: {
                        left: 25px;
                        right: 25px;
                    };
                }
            }
        }
    }
    .close {
        color: var(--blackColor);
        font-size: 30px;
        outline: 0 !important;
        line-height: initial;
        transition: var(--transition);
        position: absolute;
        right: 15px;
        top: 17px;
        z-index: 1;

        &:not(:disabled):not(.disabled){
            &:hover, &:focus{
                color: red;
                text-decoration: none;
                opacity: 1;
            }
        }
    }
}
.productsFilterModal {
    &.modal {
        &.left {
            .modal-dialog {
                position: fixed;
                margin: auto;
                width: 400px;
                height: 100%;
                transform: translate3d(0%, 0, 0);
            }
            &.fade {
                .modal-dialog {
                    left: -320px;
                    transition: opacity 0.3s linear, left 0.3s ease-out;
                }
                &.show {
                    .modal-dialog {
                        left: 0;
                    }
                }
            }
        }
    }
    .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        border: none;
        position: relative;
        padding: {
            top: 60px;
            bottom: 60px;
            left: 30px;
            right: 30px;
        };
        .modal-body {
            padding: 0;

            .woocommerce-widget-area {
                .filter-list-widget {
                    .selected-filters-wrap-list {
                        ul {
                            li {
                                a {
                                    i {
                                        top: 0;
                                        padding-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    button {
        &.close {
            position: absolute;
            left: 30px;
            top: 20px;
            outline: 0;
            padding-left: 25px;
            text-shadow: unset;
            opacity: 1;
            line-height: initial;
            transition: var(--transition);
            font: {
                size: var(--fontSize);
            };
            i {
                font-size: 24px;
                position: absolute;
                left: 0;
                top: -1px;
            }
            &:hover {
                color: red;
            }
        }
    }
    .woocommerce-widget-area {
        display: block !important;
    }
}