

.card-fav {

  width: 400px;

  height: 120px;

  padding: 5px;

  margin: 10px auto;

  border: 1px solid white;

  backdrop-filter: blur(6px);

  border-radius: 10px;

  cursor: pointer;

  transition: all 0.5s;

  user-select: none;

  color: black;

}




.card-fav:hover {

  border: 1px solid #404040;

  transform: scale(1.05);

  background: #fdfdfd;

}




.card-fav:active {

  transform: scale(0.95) rotateZ(1.7deg);

  background: #fdfdfd;

}




.profileContainer {

  width: 100px;

  padding-right: 20px;

  /* align-items: center; */

  flex: auto;

  flex-direction: column;

  top: 3.5rem;

  border-radius: 15px;

  border: 1px solid #007e85;

  position: absolute;

}

.profileCategories {

  font-family: "Poppins";

  font-style: normal;

  font-weight: 500;

  font-size: 17px;

  color: #7c7c7c;
  cursor: pointer;

}

.userProfile {

  /* flex: auto;

  flex-direction: row; */

  /* display: flex; */

  border-top-left-radius: 15px;

  border-bottom-left-radius: 15px;

  border: 1px solid #007e85;

  width: 22%;

  padding-bottom: 70px;

  padding-top: 58px;

  margin-left: 30px;

  margin-top: 27px;

  margin-bottom: 27px;

}

.userProfileh4 {

  display: flex;

  flex-direction: row;

  /* justify-content: center; */

  gap: 20px;

  padding-left: 50px;

  padding-top: 60px;
  padding-bottom: 30px;

  margin: 20px;

}

.ProfilePagecontainer{

  margin: 27px 30px 27px 0px ;

  padding: 25px;

  border: 1px solid #007E85;

  border-top-right-radius: 15px;

  border-bottom-right-radius: 15px;

  /* height: 100%; */

}

.textFormField {

  height: 51px;

  width: 403px;

  border: 1px solid #007e85;

  border-radius: 8px;

}

.saveBtn {

  border: 1px solid #007e85;

  border-radius: 30px;

  height: 55px;

  width: 147px;

  background-color: #fdfdfd;

  margin-top: 10px;

  margin-left: 250px;

  font-size: 20px;

  font-weight: 700;

  font-family: "Poppins";

  color: #007e85;

}

.profileBtn {

  border-radius: 30px;

  width: 223px;

  height: 55px;

  /* background: #007e85; */

  font-family: "Poppins";

  font-style: normal;

  font-weight: 700;

  font-size: 18px;

  color: #fff;

}

.profileImg {

  width: 150px;

  height: 150px;

  background: #ededed;

  border-radius: 100px;

  margin-left: 28%;

  align-items: center;

  display: flex;

  flex-direction: column;

  justify-content: center;

  cursor: pointer;

}

.shippingAddrCard {

  width: 427px;

  height: 95px;

  border: 1px solid #007e85;

  border-radius: 10px;

  padding: 10px;

}

.searchBtn{

  width: 527px;

  height: 65px;

  display: flex;

  align-items: center;

  border: 1px solid #007E85;

border-radius: 30px;




}

.healthPageAck{

  border: 1px solid #007E85;

  border-radius: 30px;

  padding-top: 10px;

  width: 612px;

}