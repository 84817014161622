.comorbidity-row,.comorbidity-add-row{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
.comorbidity-add-row{
	justify-content: center !important;
}
.comorb-item{
	    margin-right: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 5px 10px;
    color: var(--theme-blue);
   
    margin-bottom: 4px;
    text-transform: capitalize;

}
.comorb-item h6{
	 font-weight: bold;
}

.comorbidity-add-row .form-control{
	width :150px;
	height:38px !important;
	border-radius: 4px 0px 0px 4px !important; 
}

.comorb-holder{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	overflow-y: auto;
	max-height: 250px;
	
}
.btn-add-comorbidity{
    align-self: center;
}

.select-chronic-div,.comorb-body-content,.comorbidity-add-row{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items:center;

}
.select-chronic-div .select-div{
	min-width:150px;
}

.select-frequency2{
	margin-bottom:10px;
}
.second-contentbox{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
	width:100% !important;
    align-content: stretch;
  justify-content: center;

}
.chronic-box {
    background-color: #F5F5F5;
    padding: 20px;
    width: 120%;
    border-radius: 3px;
    box-shadow: 0 2px 2px #808080;
}
.primary-disease{
	width: 80%;
	margin-left: 1%  !important;
}
.primary-disease1{
	width: 80%;
	margin-left: 0%  !important;
}
.label-style{
	margin-left: 5%;
}

.primary-disease-table{
	background-color: var(--dark-teal)!important;
	color: white;
}
.primary-disease{
	width:90%;
}
.primary-disease-label{
	float: left;
	font-size: larger;
	margin-left: 0%;
}
.close-button-chronic{
	color: rgb(169, 19, 19);
	cursor: pointer;
	margin-left:114%;
}
.card-body{
	padding-left: 3% !important;
	padding-right: 0% !important;
	background-color:rgb(189 228 159 / 26%);
	margin-left: 20px;
	margin-right: 20px;
}
.card-body3{
	
	padding-left: 3% !important;
	padding-right: 0% !important;
	background-color:rgb(245 243 220);
	margin-left: 20px;
	margin-right: 20px;
	height:160px;
}
.card-style-label{
	padding-top: 2% !important;
	font-size: larger;
}
.disease-label{
	color:red;
}