.App {
  /* height: 100vh; */
  display: flex;
  color: white;
  margin-top:10px;
}
input:focus {
  outline:none;
}




.h4, h4 {
  font-size: 1.5rem;
  color: #ddd5da;
}

.appSide-text {
  text-align: center;
  margin-top: 80px;
}
.btn-outline-primary1{
  color: #000305;
  background-color: transparent;
  background-image: none;
  border-color:none;
  margin-bottom: 10px;
  width:0px;
  height:0px
}

.appAside {
  width: 30% !important;
  background-color:#5DCBBB;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 0px;
  padding: 50px 20px;
}
.appAside1 {
  width: 10%;
  background-color: #d9d1ec;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 0px;
  padding: 50px 20px;
}
.headerdata{
  width : 50%;
  background-color:#bcaee2;
  margin-top:8px;
}
.headerdata1{
  width : 50%;
  background-color:#bcaee2;
  margin-bottom:8px;
}
.divider-line{
  height:1px;
  background-color:grey;
  width:3vw;
  justify-content: center;
  margin:auto;
  margin-top:20px;
  margin-bottom:10px;
}

.appForm {
flex-direction:column;
flex-wrap: nowrap;
justify-content: center;
align-items: center;
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
border-radius: 0px;
/* margin-right: 10px; */
padding: 30px 30px;
width: 100% !important;
  background-color: #ffffff;
  /* overflow: auto; */
}
.appFormDate {
 margin-top: 1%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 0px;
  /* margin-right: 10px; */
  padding: 8px 8px;
  width: 55% !important;
    background-color: #ffffff;
    /* overflow: auto; */
  }
  .appFornextvisit {
 margin-top: 2%;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
    border-radius: 0px;
    /* margin-right: 10px; */
    padding: 11px 8px;
   
      background-color: #ffffff;
      /* overflow: auto; */
    }
.formData{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px rgb(0 0 0 / 30%) !important;
  border-radius: 8px;
  padding: 50px 20px;
  width: 50% !important;
  margin-left:25%;
  background-color:#9ADFCC;
  /* margin-top: 150px;
  margin-left: 300px; */
}
.formdata1{
 
  
  padding: 30px 10px;
  border-radius: 5px;
  
}
h2.name {
  color:#42a5ff;
  text-align: center;
}




.signin{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}


.signup{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}


.formCenter {
  margin-bottom: 100px;
}

.formTitle {
  color: rgb(3, 84, 128);
  font-weight: 300;
  margin-bottom: 50px;
  font-size: 1.7em;
  justify-content: flex-start;
  margin-left: 15px;
}


.formTitleLink-active {
  color: white;
  border-bottom: 1px solid #6b5b95;
}

.formField {
  margin-bottom: 40px;
  margin-left: 15px;
}

.formButtonLayout {
  display: flex;
  flex-direction: row;
}


.formFieldInput {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e8f0fe;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.formFieldLabel{
  color:  #66707d;
  font-size: 1.5em;
}
.formFieldInput::placeholder {
  color: #495057;
}
.box1::placeholder{
  color: #857e82;
}

.formFieldButton {
  background-color: #34998f;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 25px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
  margin: 5px;
  text-align: center;
}

.buttonnew{
  background-color: #34998f;
  box-shadow: #094c66;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 300;
  transition: transform 200ms, box-shadow 200ms;
}
.formFieldButton:active{
  transform: translateY(6px) translateX(6px);
  box-shadow: #094c66 0px 0px 0px;
}
.formFieldButton1 {
  background-color:#34998f;
  color: #750e52;
  border: none;
  outline: none;
  border-radius: 25px;
  padding: 15px 40px;
  font-size: 1.2em;
  font-weight: 500;
}

.formFieldLink {
  color: #f9fafc;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1.5px solid #6b5b95;
  padding-bottom: 5px;
  font-size: 1.5em;
}
.box1{
  border:0px;
  margin-right:100px;
  background-color: rgb(232, 240, 254);
}

@media screen and (max-width: 1200px) {
  .formData{
    margin-left:20%;
    width: 60% !important;
  }
}

.forgot-password {
  text-align: center;
  margin-bottom: 20px;
}

@media screen  and (max-width: 900px){
  .App{flex-direction: column;}

  .appAside {
    width: 100% !important;
  }
  .formButtonLayout {
    display: flex;
    flex-direction: column;
  }
  .formData{
    margin-left:6%;
    width: 90% !important;
  }
  .appSide-text {
    text-align: center;
    margin-top: 30px;
  }
  
}




