.feature-container{
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    gap: 20px;
}
.care-features{
    display: flex;
    flex-direction: column;
    background-color: white;
    
    padding: 10px;
    box-shadow: 2px 2px 7px rgb(0 0 0 / 30%);
}
.care-features:hover{
    background-color: #F5F5F5;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);;
}
.feature-data{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;   
    align-items: center;
}
.seperate-content{
    width: 100%;
    height: 1px;
    background-color: #D3D3D3;
    border-radius: 5px;
    margin: 10px auto 10px 0;
}
.feature-title{
    width: 100%;
    font-weight: bold;
    color: var(--theme-dark);
    text-transform: capitalize;
}
.feature-service{
    color: var(--dark-teal);
    margin-left: auto;
    margin-right: auto;
    text-transform: capitalize;
    font-weight: bold;
}
.feature-request{
    color: var(--dark-teal);
    text-transform: capitalize;
    font-weight: bold;
}
.fit-content{
    max-height: 700px;
    min-height: 500px;
    width: 80%;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(234, 240, 240);;

}
.fit-content span{
    font-weight: normal !important;
    flex-basis: 40%;
    /* color:  white; */
}
.title-input{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}
.name-content{
    justify-content: flex-start !important;
    padding: 10px;
    align-items: center;
}
.serv-width{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
.align-rental{
    align-self: flex-start;
}
.justify-feature{
    flex-basis: 50%;
}
.title-flex{
    width: 80% !important;
    margin-right: auto !important;
    height: 36px;
}
.select-flex{
    width: 100%;
    margin-right: auto;
    cursor: pointer;
}
.select-flex:hover{
    border: 1px solid gainsboro;
    background-color: #EBEFF8;
    color: #000;
    border-radius: 5px;
}
.pad-features{
    padding: 20px 30px;
}

.fit-content input:hover, .form-content input:focus, .form-content .dropdown-toggle.btn-default:hover, .form-content .dropdown-toggle.btn-default:focus , .form-control:hover {
    border: 1px solid #0093FF;
    background-color: #EBEFF8;
    color: #000;
}

.row-of-features{
    display: flex;
    justify-content: space-around;
}

.col-of-features{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.col-of-features h6 {
    color: var(--dark-teal);
    /* margin-left: auto;
    margin-right: auto; */
    align-self: flex-start;
    /* text-transform: capitalize;   */
    font-weight: bold;
}
.col-of-features span{
    color: var(--theme-blue);
    align-self: flex-start;
    text-transform: capitalize; 
}
.error-feature{
    width: 50%;
    margin-left: auto;
    margin-right: 28px;
    margin-top: 2px;
    z-index: 0 !important;
}
.error-feature-area{
    width: 90%;
    margin-top: 2px;
    z-index: 0 !important;
}
.error-feature-inventory {
    width: 80%;
    margin-right: auto;
    margin-top: 2px;
    z-index: 0 !important;
}
