.full-div{
    
    box-sizing: border-box;
    
    margin-top: 2%;
    padding:2%;
    background: #FFFFFF;
    border: 1px solid #B7B7B7;
    border-radius: 30px;
    }
    .vl {
        border-left: 2px solid  #11C057;
        height: 30px;
      }
      .v2 {
        border-left: 2px solid  #696d6b;
        height: 30px;
      }
    
    .order-devider{
        position: absolute;
    width: 5px;
    height: 600px;
    
    
    background: #F5F5F5;
    box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    }
    .horizondal{
        margin-left: 2.5%;
       
    }
    .prof-input{
      /* Rectangle 25 */
    font-size:12px;
      font-family: 'Poppins';
    box-sizing: border-box;
    height: 35px;
    background: #FFFFFF;
    width:90%;
    border: 1px solid #007E85;
    border-radius: 8px;
    }
    .probutton{
      box-sizing: border-box;
    height: 32px;
    min-width: 20%;
    color:#007E85;
    background: #FFFFFF;
    border: 1px solid #007E85;
    border-radius: 30px;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 15px;
    }
    .probutton:hover{
      -ms-transform: scale(1.1);
      /* IE 9 */
      -webkit-transform: scale(1.1);
      /* Safari 3-8 */
      transform: scale(1.1);
    
      box-shadow: 1px 1px 10px;
    }