.doctor-card15 {

    box-shadow: 4px 2px 10px rgb(0 0 0 / 20%);
    margin: 0px 50px;
    /* margin-bottom: 40px; */
    /* margin-left: 400px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-bottom: 20px;
    font-weight: bold;
    color: var(--theme-card-dark-blue) !important;
    border: 1px solid #eeeeee;
    background: #7FB4B5;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 30px;
    height: 200% !important;
    width: 500px;
}
.doctor-card16 {

    box-shadow: 4px 2px 10px rgb(0 0 0 / 20%);
    margin: 0px 50px;
    /* margin-bottom: 40px; */
     margin-left:-40px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-bottom: 20px;
    font-weight: bold;
    color: var(--theme-card-dark-blue) !important;
    border: 1px solid #eeeeee;
    background: #7FB4B5;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 30px;
    height: 1000px ;
    width: 900px;
}

.container34{
width: 92%;
/* padding-right: 15px; */
padding-left: 15px;
margin-right: auto;
margin-left: auto;
}

.modal-body {
    /* margin: 15px; */
    overflow: auto;
    width: 100% !important;
}


.doct-card {
    background-color: rgb(127, 180, 181);
    margin: 10px 40px;

}

.input-item {
    margin: 14px 10px;
    border: 0px;
    width: 94%;
}
.doctor-card1 {

    border-radius: 5px;
    width: 500px;
    box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.2);
    height: 500px;
    margin: 25px;
    margin-left: 250px;
    margin-bottom: 40px;
    background-color: #bdd7db;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: var(--theme-card-dark-blue) !important;
    border: 1px solid #eeeeee;

}

.card-header12 {
    background-color: var(--theme-blue);
    width: 100%;
    color: rgb(238, 197, 197);
    padding-top: 15px;
}

.bdo-card-header {
    /* background-color: var(--flat-blue) !important; */
}

.card-content12 {
    /* background-color: #ddc0c0; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 15px;

}

.card-content13 {
    /* background-color: #ddc0c0; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 15px;
    width: 300px;
    height: 300px;
    margin-top: 50px;
}

.rounded-div {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 8px solid rgba(0, 0, 0, 0.2);
    margin-bottom: auto;

    background-color: #f0f0f0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #7952b3;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.rounded-div h5 {
    margin: auto;


}

.doctor-card .avatar-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    vertical-align: middle;
    margin-bottom: 20px;

}

.dept {
    background-color: #f0f0f0;
    color: #7952b3;
    border-radius: 5px;
    padding: 10px;
}

.icons-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}

.icons-bottom i {
    font-size: 18px;
    margin: 5px;

}

.icons-bottom i:hover {
    color: blue;
}

.contact-div {
    padding-top: 10px;
    border-top: 1px solid #fafafa;
    width: 100%;

}

.contact-div i {
    margin-right: 10px;
    color: #d3d3d3;
}

.dr-id {
    align-self: flex-start;


}



.red,
.green {
    width: 20px;
    height: 20px;
    border-radius: 50%;

    display: inline-block;
    margin-top: 5px;
}

.red {
    background-color: var(--red);


}

.green {
    background-color: var(--green);
}

.key-labels {
    color: var(--theme-card-dark-blue) !important;
    font-weight: bold;
}

.bottom-links {
    border-radius: 0px 0px 20px 20px;

    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 35px;
    border-top: 1px solid #bdd7db;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;


    background-color: #F0f0f0;
}

.bottom-links a {
    color: var(--theme-dark-blue);
    margin-right: 20px;
    font-weight: bold;
}

.card-content h6 {
    color: var(--theme-card-dark-blue) !important;
    font-weight: bold;
}

.doctor-card .temp-disabled {
    color: grey !important;
    cursor: not-allowed !important;
}

.linethrough {
    text-decoration: line-through;
    text-decoration-color: var(--red);
    color: var(--red);
    font-weight: bold;

}

.card-content,
.doctor-card,
.doctor-card h6,
.bold-class,
.card-content span {
    font-weight: bold !important;
}

.cart-button {
    position: relative;
    padding: 10px;
    width: 200px;
    height: 60px;
    border: 0;
    outline: none;
    border-radius: 10px;
    background-color: #1b6eee;
    cursor: pointer;
    color: #fff;
    transition: 0.3s ease-in-out;
    overflow: hidden;
}

.cart-button span {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
    color: #fff;
}

.cart-button span.add-to-cart {
    opacity: 1;
}

.cart-button span.added {
    opacity: 0;

}

.cart-button .cart-icon {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: -10%;
    transform: translate(-50%, -50%);
    font-size: 2em;
}

.cart-button .cart-item {
    position: absolute;
    z-index: 3;
    top: -20%;
    left: -10%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
}

.cart-button.clicked .cart-icon {
    animation: cart 1.5s ease-in-out forwards;
}

.cart-button.clicked .cart-item {
    animation: box 1.5s ease-in-out forwards;
}

.cart-button.clicked span.add-to-cart {
    animation: add 1.5s ease-in-out forwards;
}

.cart-button.clicked span.added {
    animation: added 1.5s ease-in-out forwards;
}

.card-header-color {
    border-radius: 27px 27px 0px 0px !important;
    background-color: #e5eed5 !important;
    height: 79px;
    line-height: 79px;
    color: white;
    width: 100%;
    text-align: center;
}

.card-header-text {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 79px;
    color: #FFFFFF;
}

.card-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
}

.content-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    flex: 1;
    color: #FFFFFF;
}

.input-layout{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 2px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 20px;
    height: 48px;
    flex: 1;
}
@keyframes cart {
    0% {
        left: -10%;
    }

    40%,
    60% {
        left: 50%;
    }

    100% {
        left: 110%;
    }
}

@keyframes box {

    0%,
    40% {
        top: -20%;
    }

    60% {
        top: 40%;
        left: 52%
    }

    100% {
        top: 40%;
        left: 112%;
    }

}

@keyframes add {
    0% {
        opacity: 1;
    }

    20%,
    100% {
        opacity: 0;
    }
}

@keyframes added {

    0%,
    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.delivery-container {
    margin: 12px 10px;
    width: 90%;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .delivery-content input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    padding: 0px !important;
  }

  .input {

  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 10%;
  }
  
  /* On mouse-over, add a grey background color */
  .delivery-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .delivery-container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .delivery-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .delivery-container .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }

  .react-datepicker {
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
    /* font-size: 0.8rem; */
    background-color: #a66;
    color: #000;
    border: 0px;
    border-radius: 0;
    display: inline-block;
    position: relative;
  }