@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400&display=swap');


 .logout-btn{
 
    font-weight: 600 !important;
 } 

.logout-btn:hover{
  color:var(--theme-purple);
}
.logo {
 
  height: 77px;

  
}
.active{
  text-decoration: none;
}
.dropdown-toggle::after {
  color:#0b0a00;
}
.nav-link {
  display: flex !important;
  padding: 0.5rem 1rem;
 
}
.logo-div{
  padding-left: 15px;
}
.logo-small{
  height:32px;
  margin-left:20px;
  margin-right:0px;
}
.dropdown-toggle::after {
  display: none;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* margin-top: 15%; */
  color: #6bcbb8;
}
.nav-bar {


  border: #2f9ec1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  box-shadow: 0 1px 5px #2f9ec1;
  background-color:#e4dd9f;
 
  
}
.nav-bar .btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin-right: 50px;
  align-items: center;
  
}
.btns .btn a:hover {
  text-transform: none;
  text-decoration: none;
}
.btns .login-btn a {
  color: white !important;
}
.btns .btn a,
.btns .btn a:hover {
  text-transform: uppercase;
  font-weight: 600;
}
.btns .btn:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.nav-bar button {
  margin: 5px;
  color: rgb(48, 45, 245); 
}
.loginuser {
  color: #42a5ff;
}
/*
.login-btn {
  background-color: transparent !important;
  border: 2px solid #7d0752 !important;
  color: white !important;
}

*/
/*
.login-btn:hover{
  background-color: #7d0752 !important;
}
*/
.reg-btn:hover,.login-btn:hover {
  border: 2px solid #9ADFCC !important;
  box-shadow: #9ADFCC;
  color:#000806;
  background-color:#9ADFCC ;

}
.reg-btn,.login-btn{
  
  background-color: transparent;
  box-shadow: none !important;
  border:2px solid transparent !important;



}
.btns{
  padding-top: 10px;

}
.nav-bar button a {
  color: #42a5ff !important;
}
nav {
  width: 100%;
  height:90%
}

.home-icon {
  color: #da70d6;
  font-size: 25px;
  margin: 25px 20px;
  display: inline-block;
}
.home-icon-div {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10px;
}
.home-icon p {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-size: 18px;
  margin-left: 5px;
  font-weight: 600;
}

.home-icon:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.2);
}


.logo-div h4{
  color: #f0f0f0;
    font-family: 'Josefin Sans', sans-serif;
  


}
.logo-img{
  width: 17%;
}
.logo-img1{
  margin-top: 1%;
  width: 20%;
}
.logo-1{
   font-family: 'Josefin Sans', sans-serif;
 color: var(--theme-purple);
  font-weight: bolder;
  
}
.logo-2{
   font-family: 'Josefin Sans', sans-serif;
    color: var(--theme-purple);

}

.user-icon{
  color:var(--theme-purple);
  font-size: 25px;
  margin-right:5px;
  margin-left:10px;
  
}

.dropdown {
  margin-top: -2px;
}

.user-name{
  color: var(--theme-purple);
  margin:auto;
  margin-top: 5px;
}
.fa-globe{
  color: white;
}
.fa-globe:hover{
  color: purple;
}
.pageSwitcher {
  width: max-content;
  padding-right: 5ch;
  padding-top: 3ch;
  display:flex;
  justify-content: flex-end;
  margin-bottom: 5%;
}
.pageSwitcherItem {
  background-color:#34998f;
  color: #edf0f5;
  padding: 10px 25px;
  cursor: pointer;
  font-size: 1.2em;
  border: none;
  outline: none;
  display: inline-block;
  text-decoration: none;
  
}
.pageSwitcherItemactive {
  background-color: #6fe4d8;
  color: #750e52;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 1.2em;
  border: none;
  outline: none;
  display: inline-block;
  text-decoration: none;
 
}
@media screen  and (max-width: 400px){
  .pageSwitcher{font-size: smaller;}


}
.nav-btn a:hover, .nav-btn a:active{
  color: #0707ff !important;
  text-decoration: none;
   
}
.notification{
  text-decoration: none !important;;
}
a{
  text-decoration: none !important;;
}
.active{
   color: rgb(34, 79, 19) !important;
   text-decoration: none;
}
.inactive{
  color: #007bff;
  text-decoration: none;
}
.activeconfg{
  color: #525255 !important;
}
.inactiveconfig{
 color: #750e52 !important;
}
