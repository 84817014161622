.schedule-class {
    box-sizing: border-box;

    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
    background-color: #ffffff;
    border-color: 1px solid #dadada;
    border-radius: 6px;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 5%);
}

.each-schedule {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    background-color: #f9f9f9;


}

.schedule-detail {
    display: flex;
    flex-direction: row;
    padding: 24px;

    cursor: pointer;
    width: 100%;
    margin-left: 0.1%;
}

.schedule-detail:hover {
    background-color: #e0e0e07a;
}

.each-schedule-detail {
    flex-shrink: 0;
    box-sizing: border-box;
    width: 350px;
    padding-right: 80px;
    padding-left: 50px;
}

.each-schedule-detail1 {
    flex: 1 1 auto;
    text-transform: capitalize;
}

.each-schedule-detail2 {
    flex-shrink: 0;
}

.detail-open {
    color: var(--theme-blue);
    font-size: 20px;
}

.detail-btns {

    display: flex;
    /* padding: 0 24px 24px 24px; */
    animation: slide-in 0.5s forwards;
    animation-timing-function: ease;
}

@keyframes slide-in {
    0% {
        -webkit-transform: translateY(-30%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}

.left-detail {
    display: flex;
    flex-direction: column;
    float: right;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 350px;
    padding-right: 8px;

    box-sizing: border-box;
}

.bt-style {
    margin-top: 10px;
    border-style: solid;
    border-color: gainsboro;
    border-width: 2px;
    border-radius: 20px;
    padding: 5px;
    background-color: #ffffff;
}

.bt-del-style {
    margin-top: 10px;
    border-style: solid;
    border-color: red;
    border-width: 2px;
    border-radius: 20px;
    padding: 5px 20px 5px 20px;
    background-color: #ffffff;
}

.all-detail {
    flex: 1 1 auto;
    padding-top: 24px;
    border: 2px solid #e2e2e2;
    padding: 5px;
}

.detail-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.consult-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}

.resch-date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.date-content {
    text-align: left;
    background-color: #F8F8F8;
    width: 700px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #E8E8E8 !important;
    color: var(--flat-blue);
    font-weight: 600;
}

.cal-col {
    color: var(--flat-yellow);
}

.walk-col {
    color: var(--flat-green);
}

.rep-icon {
    color: var(--flat-red);
}

.plus-slot {
    color: black;
    cursor: pointer;
    float: right;
    margin-right: 165px;
    margin-top: 5px;

}

.date-align {
    margin-top: 10px;
}

.start-margin {
    margin-right: 50px;
    margin-left: 10px;
}

.sort-margin {
    margin-bottom: 10px;
}

.slot-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.token-align {
    width: 100px;
    height: 39px !important;
    background-color: #ffffff !important;
    
   
}
.overtoken-align {
    width: 100px;
    height: 39px !important;
    background-color: #ffffff !important;
    margin-left: 60px;
}

.align-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.align-close {
    margin-left: 60px;
    font-size: large;
    cursor: pointer;
}

.schedule-btn {
    margin-top: 10px;
}

.add-schedule-btn {


    margin-left: 24%;
    width: 160px;
    height: 32px;
    background: #128F8F;
    border-radius: 10px;
}

.slot-data {
    margin-right: 60px;
}

.slot-table {
    margin-top: 10px;
    margin-right: 70px;
    color: var(--theme-blue);
    font-weight: 600;
}

.slot-table1 {
    margin-top: 10px;
    margin-right: 75px;
    color: var(--theme-blue);
    font-weight: 600;
}

.slot-table2 {
    margin-top: 10px;
    margin-right: 60px;
    color: var(--theme-blue);
    font-weight: 600;
}

.slot-head {
    margin-left: 60px;
}

.schedule-list {
    border-radius: 22px;
    padding: 10px;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 30%) !important;
    background-color: gainsboro;
}

.schedule-date {
    font-weight: 600;
    color: var(--theme-dark-blue);
    width: 100%;
}

.select-time {
    width: 130px;
    margin-right: 40px;
    margin-bottom: 15px;
}

.schedule-frequency {
    width: 130px;
    margin-left: 200px;
}

.head-section {
    margin-right: 123px;
    margin-left: 10px;
}

.head-token {
    margin-right: 123px;
    margin-left: 53px;
}
.head-overtoken {
    /* margin-right: 123px; */
    margin-left: -46px;
}

.from-date {
    border: 2px solid darkcyan;
    border-radius: 5px;
}

.from-date:hover {
    border: 2px solid red;
    border-radius: 5px;
    box-shadow: 1px 2px #dadada;
}

.month-align {
    margin-top: 20px;
}

.select-monthly {
    width: 130px;
    margin-left: 50px;
    margin-bottom: 20px;
}

.month-width {
    width: 130px;
    height: 36px !important;
    margin-left: 47px;
}

.btn-center {
    align-self: center !important;
}

.doc-icon {
    color: var(--theme-purple);
}

.days-class {
    margin-left: 5px;
    margin-right: 20px;
}

.checkbox-class {
    cursor: pointer;
}

.checkbox-class1 {
    cursor: pointer;
    margin-left: 10px;
}

.consult-color {
    margin-top: 10px;
    background-color: #F0F8FF;
    border-radius: 10px;
    padding: 5px;

}

.check-consult {
    width: 700px;
    background-color: #F8F8FF;
    border-radius: 6px;
    margin-bottom: 20px;
}

.cal-size {
    font-size: 90px;
    margin-top: 80px;
}

.no-schedule {
    margin-top: 30px;
    color: red
}

.schedule {
    /* background-color: #dce8f46b; */
}

.text-style-schedule {

    font-family: 'Inter';
    font-style: normal;

    font-size: 15px;

    color: #000000;
}

.fade.modal.show {
    z-index: 2000;
}

.appFormPayment {
    border: 2px solid darkcyan;
    /* margin-top: !important; */
    margin-Top: 30% !important;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px #ebf5ffaa !important;
    border-radius: 20px;
    /* margin-right: 10px; */
    padding: 10px 10px;
    width: 100% !important;
    /* background-color: #dce8f46b; */
    background-color: #f2f9ffaa !important;
}

.consultation {
    box-sizing: border-box;
    margin-left: 2%;

    width: 295px;
    height: 143px;

    margin-top: 33%;
    background: #FFFFFF;
    border: 2px solid #D1D0D0;
    border-radius: 10px;
}

.title-of-consultation {

    /* Consultation fee */


    text-align: center;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #959494;

}

.conslt-div {
    padding: 2%;
}

.label-consult {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
}

.confinput-consult {
    box-sizing: border-box;
    background-color: rgba(146, 145, 145, 0.87);
    width: 101px;
    height: 29px;

    background: #FFFFFF;
    border: 0.5px solid #979797;
    box-shadow: 0px 4px 4px rgba(207, 207, 207, 0.25);
    border-radius: 10px;
}


.schedule-head {
    margin-top: 2%;
   width:96%;
    height: 36px;
    padding-top: 1%;
    background: #128F8F;
    border-radius: 10px 10px 0px 0px;
}

.schedule-title {
    display: flex;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #FFFFFF;
    /* margin-top: 1%; */
}

.schedule-subtitle {
    display: flex;
    margin-top: 0.5%;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}
.schedule-frequancy{
    display: flex;
    margin-top: 15%;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
    margin-left: 18%;
    text-align: center;
}
.mid-freq{
    text-transform: capitalize;
    margin-top: 10%;
    padding-right: 10px;
}
.schedule-body {
    box-sizing: border-box;
    margin-left: 0%;
   width:96%;
   padding-top: 1%;
    min-height: 120px;
    margin-bottom: 3%;
    background: #FFFFFF;
    border: 2px solid #CCCCCC;
    border-radius: 0px 0px 10px 10px;
}

.consult-days {
    border-radius: 50%;
    width: 28.64px;
    height: 28.64px;
    background: #11760F;
    color: #FFFFFF;
    padding-left: 8px;
    padding-top: 1%;
    margin-left: 5%;
    margin-top: 1%
}

.non-consult-days {
    border-radius: 50%;
    width: 28.64px;
    height: 28.64px;
    background: #adaead;
    color: #1e1d1d;
    text-align: center;
    padding-left: 8px;
    padding-top: 1%;
    margin-left: 5%;
    margin-top: 1%
}
.slotDisplay{
    
     min-height:280px;
    margin-left: 5.3%;
   
    width:90%;
   margin-top: 0.5%;
    border-radius: 10px;
}
.react-date-picker__wrapper{
    border: 1px solid #979797;
}


.react-date-picker__inputGroup{
    min-width: 57px;
    border-radius: 10px;
   
}