$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;

@media only #{$media} and ($feature_max : $value_two) {

    body {
        font-size: 13px;
    }
    .container {
        max-width: 100%;
    }
    p {
        font-size: 13px;
    }
    .default-btn {
        font-size: 13px;
        padding: {
            top: 10px;
            bottom: 10px;
            left: 42px;
            right: 20px;
        };
        i {
            left: 20px;
            top: 12px;
            font-size: 15px;
        }
    }
    .ptb-70 {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .pt-70 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .ptb-40 {
        padding: {
            top: 20px;
            bottom: 20px;
        };
    }
    .pt-40 {
        padding-top: 20px;
    }
    .pb-40 {
        padding-bottom: 20px;
    }
    .section-title {
        text-align: center;
        margin-bottom: 30px;

        h2 {
            font-size: 24px;
        }
        &::before {
            left: 50%;
            transform: translateX(-50%);
            width: 70px;
        }
    }

    .top-header {
        text-align: center;
    }
    .top-header-contact-info {
        margin-bottom: -10px;

        li {
            margin-bottom: 10px;
        }
    }
    .top-header-menu {
        text-align: center;
        margin-top: 10px;

        li {
            .language-switcher {
                .dropdown-toggle {
                    font-size: 13px;
                }
            }
            .currency-switcher {
                .dropdown-toggle {
                    font-size: 13px;
                }
            }
        }
    }

    .search-overlay {
        .search-overlay-form {
            max-width: 290px;
            width: 290px;

            form {
                .input-search {
                    height: 50px;
                    padding: 0 0 0 20px;
                }
                button {
                    width: 40px;
                    height: 40px;
                    font-size: 20px;
                    line-height: 40px;
                }
            }
        }
    }

    .single-banner-item {
        height: auto;
        padding: {
            top: 60px;
            bottom: 120px;
        };
    }
    .banner-content {
        text-align: center;

        h1 {
            margin-bottom: 12px;
            font-size: 30px;
        }
        p {
            font-size: 13px;
        }
        .btn-box {
            margin-top: 20px;

            .d-flex {
                justify-content: center;
            }
            .default-btn {
                margin-right: 10px;
            }
            .price {
                margin-left: 10px;
                font-size: 15px;
            }
        }
    }
    .banner-image {
        margin-top: 30px;
    }
    .home-slides {
        &.owl-theme {
            .owl-nav {
                // position: absolute;
                left: 0;
                right: 0;
                bottom: 25px;

                [class*=owl-] {
                    position: relative;
                    left: 0;
                    top: 0;
                    transform: translateY(0);
                    margin: 0 5px;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    font-size: 20px;
                    padding-right: 5px !important;

                    &.owl-next {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
    .home-wrapper-area {
        padding: {
            top: 85px;
            bottom: 80px;
        };
    }
    .wrapper-content {
        text-align: center;

        .sub-title {
            font-size: 13px;
        }
        h1 {
            margin-bottom: 12px;
            font-size: 30px;
        }
        .default-btn {
            margin-top: 10px;
        }
    }
    .wrapper-image {
        margin-top: 30px;
    }
    .home-slides-two {
        &.owl-theme {
            .owl-dots {
                text-align: left;
                margin-top: 0;
                bottom: 4px;
            }
            .owl-nav {
                position: relative;
                left: 0;
                transform: unset;
                margin-left: 0;
                bottom: 0;
                text-align: right;
                margin-top: 25px;
            }
        }
    }
    .main-banner {
        padding: {
            top: 60px;
            bottom: 45px;
        };
    }
    .main-banner-content {
        text-align: center;

        h1 {
            margin-bottom: 12px;
            font-size: 30px;
            line-height: 1.3;

            br {
                display: none;
            }
        }
        p {
            font-size: 13px;
        }
        .default-btn {
            margin-top: 5px;
        }
    }
    .main-banner-image {
        margin-top: 35px;
    }
    .megamenu-container {
        display: none;
    }
    .banner-area {
        padding: {
            top: 65px;
            bottom: 105px;
            left: 15px;
            right: 15px;
        };
    }
    .home-slides-three {
        &.owl-theme {
            .owl-nav.disabled + .owl-dots {
                text-align: center;
            }
            .owl-dots {
                left: 0;
                right: 0;
            }
        }
    }

    .single-banner-categories-box {
        overflow: unset;
        text-align: center;

        img {
            transform: unset !important;
        }
        .content {
            position: relative;
            left: 0;
            right: 0;
            top: 0;
            transform: unset;
            padding: 20px 0 0 0;

            h3 {
                font-size: 19px;
            }
            .btn-box {
                .d-flex {
                    justify-content: center;
                }
                .default-btn {
                    font-size: 13px;
                    margin-right: 7px;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                        left: 42px;
                        right: 20px;
                    };
                    i {
                        left: 20px;
                        top: 12px;
                        font-size: 15px;
                    }
                }
                .price {
                    margin-left: 7px;
                    font-size: 15px;
                }
            }
        }
    }

    .single-categories-box {
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
        padding: 20px 10px;

        h3 {
            font-size: 17px;
            margin-top: 25px;
        }
    }
    .categories-box {
        img {
            width: 100%;
        }
        .content {
            top: 15px;
            left: 15px;
            padding: {
                top: 9px;
                bottom: 7px;
                left: 15px;
                right: 15px;
            };
            h3 {
                font-size: 15px;
            }
        }
    }

    .single-products-box {
        .image {
            .buttons-list {
                ul {
                    li {
                        opacity: 1 !important;
                        visibility: visible !important;
                        transform: translateX(0) !important;
                    }
                }
            }
        }
        .content {
            text-align: center;

            h3 {
                font-size: 18px;
            }
            .price {
                span {
                    font-size: 13.5px;
                }
            }
        }
    }
    .products-slides {
        &.owl-theme {
            .owl-nav {
                margin-top: 0;
                position: relative;
                right: 0;
                top: 0;
                margin-bottom: 30px;

                [class*=owl-] {
                    margin: 0 4px;

                    &.owl-next {
                        margin-left: 4px;
                    }
                }
            }
        }
    }

    .single-products-promotions-box {
        img {
            width: 100%;
        }
        .content {
            h3 {
                font-size: 18px;
            }
            .discount {
                font-size: 18px;
                margin-top: 2px;

                span {
                    font-size: 13px;
                }
            }
            .link-btn {
                margin-top: 15px;
            }
        }
    }

    .hot-deal-content {
        h2 {
            font-size: 30px;
        }
        .back-text {
            top: -25px;
            font-size: 125px;
        }
        #timer {
            margin-bottom: 30px;

            div {
                width: 65px;
                height: 70px;
                font: {
                    size: 24px;
                };
                margin: {
                    left: 3px;
                    right: 3px;
                };
                span {
                    font: {
                        size: 13px;
                    };
                }
            }
        }
    }

    .single-brands-item {
        border-right: none;
    }

    .single-blog-post {
        .post-content {
            h3 {
                font-size: 17px;
            }
        }
    }
    
    .promotion-banner {
        padding: {
            top: 30px;
            bottom: 30px;
            left: 15px;
            right: 15px;
        };
        h2 {
            font-size: 24px;
        }
        #timer {
            margin-bottom: 20px;

            div {
                width: 61px;
                height: 65px;
                font: {
                    size: 24px;
                };
                margin: {
                    left: 2px;
                    right: 2px;
                };
                span {
                    font: {
                        size: 13px;
                    };
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .feedback-inner {
        margin-top: 30px;
        padding: {
            left: 15px;
            right: 15px;
            bottom: 20px;
        };
        h2 {
            font-size: 22px;

            span {
                padding: {
                    left: 10px;
                    right: 10px;
                };
            }
        }
    }
    .single-feedback-item {
        padding-top: 55px;

        p {
            font-size: 15px;
        }
        &::before {
            font-size: 40px;
        }
        .client-info {
            img {
                width: 50px !important;
                height: 50px;
            }
            .title {
                h3 {
                    font-size: 16px;
                }
            }
        }
    }

    .single-facility-box {
        border-right: none;
        padding-left: 0;
        text-align: center;

        .icon {
            position: relative;
            font-size: 30px;
            top: 0;
            left: 0;
            margin-bottom: 14px;
        }
        h3 {
            margin-bottom: 7px;
            font-size: 18px;
        }
        p {
            font-size: 13px;
        }
    }

    .page-title-area {
        padding: {
            top: 65px;
            bottom: 65px;
        };
    }
    .page-title-content {
        h1 {
            font-size: 25px;
        }
        ul {
            li {
                font-size: 13px;
                margin: {
                    left: 8px;
                    right: 8px;
                };
                &::before {
                    height: 12px;
                    right: -10px;
                    top: 5.2px;
                }
            }
        }
    }

    .about-content {
        text-align: center;
        padding-right: 0;

        h2 {
            font-size: 24px;
        }
    }
    .about-image {
        margin-top: 30px;
    }

    .single-funfacts-box {
        h3 {
            font-size: 28px;
        }
        span {
            font-size: 13px;
        }
    }

    .single-why-choose-box {
        padding: 25px 15px;

        .icon {
            font-size: 30px;
        }
        h3 {
            font-size: 18px;
        }
    }

    .single-feedback-box {
        padding: {
            left: 15px;
            right: 15px;
            bottom: 20px;
            top: 65px;
        };
        &::before {
            top: 20px;
            font-size: 30px;
        }
        p {
            font-size: 14px;
        }
        .client-info {
            img {
                width: 50px !important;
                height: 50px;
            }
            .title {
                h3 {
                    font-size: 15px;
                }
            }
        }
    }
    .feedback-slides-two {
        &.owl-theme {
            .owl-nav {
                &.disabled + .owl-dots {
                    margin-top: 5px;
                }
            }
        }
    }

    .single-doctors-box {
        text-align: center;

        .content {
            margin-top: 20px;

            h3 {
                font-size: 18px;
            }
        }
    }

    .history-timeline {
        &::before {
            left: 50%;
            transform: translateX(-50%);
        }
        .timeline-block {
            padding-left: 0;
            margin-bottom: 40px;

            .timeline-date {
                text-align: center;
                position: relative;
                left: 0;
                top: 0;
                transform: unset;
                font-size: 15px;
                margin-bottom: 15px;
                background-color: var(--whiteColor);
                padding-bottom: 5px;

                span {
                    margin-bottom: 2px;
                    font-weight: 700;
                }
            }
            .timeline-icon {
                display: none;
            }
            .timeline-content {
                text-align: center;
                padding: 20px;

                .content {
                    margin-bottom: 25px;
                    padding: {
                        left: 0;
                        right: 0;
                    };
                    h3 {
                        margin-bottom: 10px;
                        font-size: 18px;
                    }
                }
            }
            &::before {
                display: none;
            }
        }
    }

    .pagination-area {
        margin-top: 15px;

        .page-numbers {
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 15px;
            margin: {
                left: 2px;
                right: 2px;
            };
        }
    }

    .widget-area {
        padding-left: 0;
        margin-top: 35px;

        &.widget-left-sidebar {
            padding-right: 0;
            margin: {
                top: 0;
                bottom: 35px;
            };
        }
        .widget {
            padding: 15px;

            .widget-title {
                font-size: 18px;
            }
        }
        .widget_drodo_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 14px;
                    }
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    font-size: 13px;
                }
            }
        }
        .tagcloud {
            a {
                font-size: 13px !important;
            }
        }
        .widget_insight {
            ul {
                li {
                    a {
                        padding: 10px 15px 10px 30px;
                        font-size: 14px;

                        &::before {
                            left: 15px;
                        }
                    }
                }
            }
        }
    }

    .blog-details-desc {
        padding: 15px;

        .article-content {
            .entry-meta {
                ul {
                    margin-bottom: -15px;

                    li {
                        display: inline-block;
                        border-right: none;
                        font-size: 13px;
                        padding-right: 0;
                        margin: {
                            bottom: 13px;
                            right: 13px;
                        };
                        &:last-child {
                            margin: {
                                right: 0;
                            };
                        }
                    }
                }
            }
            h3 {
                font-size: 18px;
                line-height: 1.4;
            }
        }
        .article-footer {
            text-align: center;

            .article-tags {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .article-share {
                flex: 0 0 100%;
                max-width: 100%;

                .social {
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
        .article-video {
            iframe {
                height: 145px;
            }
        }
        .article-image-slides {
            &.owl-theme {
                .owl-nav {
                    opacity: 1;
                    visibility: visible;

                    [class*=owl-] {
                        font-size: 15px;
                        width: 35px;
                        height: 35px;
                        line-height: 33px;
                        left: 10px;

                        &.owl-next {
                            left: auto;
                            right: 10px;
                        }
                    }
                }
            }
        }
        .article-author {
            .author-profile {
                padding: 0 15px 15px;

                .author-profile-title {
                    h4 {
                        font-size: 18px;
                    }
                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    blockquote, .blockquote {
        padding: 20px !important;

        p {
            font-size: 16px !important;
        }
    }
    .prev-link-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;

        .prev-title {
            font-size: 14px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }
    .next-link-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;

        .next-title {
            font-size: 14px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }
    .comments-area {
        .comments-title {
            font-size: 18px;
            margin-bottom: 25px;
        }
        .comment-author {
            font-size: 15px;

            .avatar {
                height: 50px;
                left: 0;
                position: relative;
                width: 50px;
                display: block;
                margin-bottom: 15px;
            }
        }
        .comment-body {
            padding-left: 0;

            .reply {
                a {
                    padding: 5px 20px 3px;
                    font-size: 12px;
                }
            }
        }
        .comment-content {
            p {
                font-size: 13px;
            }
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 18px;
            }
            .comment-form-author {
                width: 100%;
                padding-right: 0;
            }
            .comment-form-email {
                width: 100%;
                padding-left: 0;
            }
            .form-submit {
                input {
                    font-size: 13px;
                }
            }
        }
    }
    .comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
        font-size: 13px;
    }

    .customer-service-content {
        h3 {
            padding-left: 25px;
            font-size: 18px;
        }
    }

    .error-content {
        h3 {
            font-size: 25px;
            margin: {
                top: 35px;
                bottom: 15px;
            };
        }
        .default-btn {
            margin-top: 20px;
        }
    }

    .faq-accordion-tab {
        .tabs {
            margin-bottom: 20px;

            li {
                margin: {
                    left: 5px;
                    right: 5px;
                    bottom: 15px;
                };
                a {
                    padding: 15px;
                }
            }
        }
    }
    .faq-accordion {
        .accordion {
            .accordion-title {
                font-size: 14px;
                padding: 20px 15px 17px 45px;

                i {
                    left: 15px;
                }
            }
            .accordion-content {
                padding: {
                    right: 15px;
                    left: 45px;
                };
            }
        }
    }

    .privacy-policy-content {
        h3 {
            font-size: 18px;
        }
    }

    .purchase-guide-content {
        h3 {
            font-size: 18px;
        }
    }

    .terms-of-service-content {
        h3 {
            font-size: 18px;
        }
    }

    .login-form {
        padding: 30px 20px;
        margin-right: 0;

        h2 {
            margin-bottom: 20px;
            text-align: center;
            font-size: 25px;
        }
        form {
            .lost-your-password-wrap {
                text-align: left;
                margin-top: 15px;
            }
            button {
                padding: 13px 30px;
                font-size: 15px;
            }
        }
    }
    .register-form {
        margin-top: 40px;
        border-top: 1px solid #eeeeee;
        padding: {
            left: 0;
            top: 30px;
        };
        h2 {
            margin-bottom: 20px;
            text-align: center;
            font-size: 25px;
        }
        form {
            .lost-your-password-wrap {
                text-align: left;
                margin-top: 15px;
            }
            button {
                padding: 13px 30px;
                font-size: 15px;
            }
        }
    }

    .track-order-content {
        max-width: 100%;
        padding: 25px 15px;

        form {
            padding: {
                left: 0;
                right: 0;
            };
            .form-group {
                label {
                    font-size: 13px;
                }
            }
        }
    }

    .coming-soon-area {
        height: 100%;
        padding: {
            top: 120px;
            bottom: 120px;
            left: 15px;
            right: 15px;
        };
    }
    .coming-soon-content {
        padding: 30px 20px;

        h2 {
            margin-top: 25px;
            font-size: 28px;
        }
        #timer {
            margin-top: 10px;

            div {
                width: 90px;
                height: 95px;
                font-size: 30px;
                margin-top: 20px;

                span {
                    font-size: 14px;
                }
            }
        }
        form {
            max-width: 100%;
            margin-top: 40px;

            .form-group {
                .input-newsletter {
                    font-size: 14px;
                }
                .label-title {
                    i {
                        top: 10px;
                    }
                }
            }
        }
    }

    .single-contact-info-box {
        padding: 20px 15px;

        .icon {
            font-size: 25px;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 18px;
        }
    }
    .contact-form {
        padding-right: 0;
        text-align: center;

        h2 {
            font-size: 25px;
        }
        form {
            text-align: left;
        }
    }
    .contact-image {
        margin-top: 30px;
    }

    .products-details-desc {
        margin-top: 30px;

        h3 {
            margin-bottom: 12px;
            font-size: 18px;
        }
        .price {
            margin-bottom: 12px;
            font-size: 13px;
        }
        .products-review {
            .rating {
                font-size: 15px;
            }
            .rating-count {
                top: -1px;
            }
        }
        .products-info {
            li {
                font-size: 13px;
            }
        }
        .products-color-switch {
            span {
                font-size: 13px;
            }
        }
        .products-size-wrapper {
            span {
                font-size: 13px;
            }
        }
        .products-info-btn {
            a {
                font-size: 13px;

                &:not(:first-child) {
                    margin-left: 5px;
                }
            }
        }
        .products-details-accordion {
            .accordion {
                .accordion-content {
                    .products-reviews {
                        h3 {
                            font-size: 18px;
                        }
                        .rating {
                            top: 2px;
                            position: relative;
            
                            span {
                                font-size: 17px;
                            }
                        }
                        .side {
                            width: 20%;
            
                            div {
                                font-size: 15px;
                            }
                        }
                        .middle {
                            width: 60%;
                        }
                    }
                    .products-review-comments {
                        h3 {
                            padding-bottom: 10px;
                            font-size: 18px;
                        }
                        .user-review {
                            padding-left: 0;
            
                            img {
                                position: relative;
                                left: 0;
                                top: 0;
                                margin-bottom: 15px;
                            }
                            .review-rating {
                                span {
                                    display: block !important;
                                    top: 0;
                                    font-size: 17px;
                                    margin: {
                                        left: 0;
                                        top: 5px;
                                    };
                                }
                            }
                        }
                    }
                    .review-form-wrapper {
                        h3 {
                            font-size: 18px;
                        }
                        form {
                            button {
                                padding: {
                                    left: 30px;
                                    right: 32px;
                                };
                            }
                        }
                    }
                }
            }
        }
    }
    .products-details-tabs {
        .nav {
            .nav-item {
                margin: {
                    left: 8px;
                    right: 8px;
                };
                .nav-link {
                    font-size: 15px;
                    padding-bottom: 6px;

                    &::before {
                        height: 2px;
                        bottom: -1px;
                    }
                }
            }
        }
        .tab-content {
            .tab-pane {
                .products-reviews {
                    h3 {
                        font-size: 18px;
                    }
                    .rating {
                        top: 2px;
                        position: relative;
        
                        span {
                            font-size: 17px;
                        }
                    }
                    .side {
                        width: 20%;
        
                        div {
                            font-size: 15px;
                        }
                    }
                    .middle {
                        width: 60%;
                    }
                }
                .products-review-comments {
                    h3 {
                        padding-bottom: 10px;
                        font-size: 18px;
                    }
                    .user-review {
                        padding-left: 0;
        
                        img {
                            position: relative;
                            left: 0;
                            top: 0;
                            margin-bottom: 15px;
                        }
                        .review-rating {
                            span {
                                display: block !important;
                                top: 0;
                                font-size: 17px;
                                margin: {
                                    left: 0;
                                    top: 5px;
                                };
                            }
                        }
                    }
                }
                .review-form-wrapper {
                    h3 {
                        font-size: 18px;
                    }
                    form {
                        button {
                            padding: {
                                left: 30px;
                                right: 32px;
                            };
                        }
                    }
                }
            }
        }
    }
    .products-details-image-slider {
        &.owl-theme {
            margin-bottom: 0;
        }
    }

    .drodo-grid-sorting {
        text-align: center;
        margin-bottom: 35px;

        .ordering {
            text-align: center;
            margin-top: 15px;
        }
    }

    .woocommerce-widget-area {
        display: none;
    }

    .products-collections-area {
        .container-fluid {
            width: 100%;
        }
    }

    .cart-table {
        table {
            thead {
                tr {
                    th {
                        font-size: 14.5px;
                        padding: {
                            left: 15px;
                            right: 15px;
                        };
                        &:first-child {
                            padding-left: 0;
                        }
                        &:last-child {
                            padding-right: 60px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        font-size: 14.5px;
                        padding: {
                            left: 15px;
                            right: 15px;
                        };
                        &:first-child {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
    .cart-buttons {
        .shopping-coupon-code {
            text-align: center;

            button {
                position: relative;
                right: 0;
                top: 0;
                height: auto;
                padding: 12px 25px;
                line-height: initial;
                font-size: 14px;
                border-radius: 5px;
                margin-top: 15px;
            }
        }
        .text-right {
            text-align: center !important;
            margin-top: 15px;
        }
    }
    .cart-totals {
        padding: 25px 20px;
        max-width: 100%;
        margin: {
            top: 40px;
        };
        h3 {
            font-size: 20px;
        }
        ul {
            margin-bottom: 20px;

            li {
                font-size: 14px;

                &:last-child {
                    font-size: 16px;
                }
            }
        }
    }

    .billing-details {
        .title {
            font-size: 18px;
        }
    }
    .order-details {
        margin-top: 40px;

        .title {
            font-size: 18px;
        }
        .order-table {
            table {
                thead {
                    tr {
                        th {
                            font-size: 13.5px;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            font-size: 13.5px;
                        }
                    }
                }
            }
        }
        .payment-box {
            padding: 20px;
        }
    }
    .order-details .order-table table tbody tr td.order-subtotal span, .order-details .order-table table tbody tr td.order-shipping span, .order-details .order-table table tbody tr td.total-price span {
        font-size: 15px;
    }

    .footer-area {
        padding-top: 20px;
    }
    .single-footer-widget {
        h3 {
            margin-bottom: 22px;
            font-size: 18px;
        }
        .social {
            li {
                a {
                    font-size: 18px;
                }
            }
        }
        .newsletter-form {
            .default-btn {
                padding: {
                    top: 12px;
                    bottom: 12px;
                };
            }
        }
    }
    .footer-bottom-area {
        text-align: center;
        margin-top: 20px;
        padding: {
            top: 20px;
            bottom: 20px;
        };
        .payment-types {
            text-align: center;
            margin-top: 15px;

            .justify-content-end {
                justify-content: center !important;
            }
        }
    }

    .go-top {
        right: 15px;
        width: 33px;
        height: 35px;
        font-size: 22px;

        &.active {
            bottom: 15px;
        }
    }

    .shoppingCartModal {
        &.modal {
            &.right {
                .modal-dialog {
                    width: 100%;
                }
            }
        }
    }
    .productsQuickView {
        .modal-dialog {
            max-width: 100%;
        }
        .modal-content {
            padding: 20px;

            button.close {
                z-index: 5;
                background-color: red;
                color: var(--whiteColor);
            }
            .products-content {
                // margin-top: 25px;

                h3 {
                    font-size: 18px;
                }
                .price {
                    font-size: 13px;
                }
                .products-color-switch {
                    h4 {
                        font-size: 14px;
                    }
                }
                .products-size-wrapper {
                    h4 {
                        font-size: 14px;
                    }
                }
                .products-add-to-cart {
                    .input-counter {
                        input {
                            height: 39px;
                            font-size: 15px;
                        }
                        span {
                            line-height: 42px;
                            font-size: 17px;
                        }
                    }
                }
                .view-full-info {
                    font-size: 13px;
                }
            }
        }
    }
    .shoppingWishlistModal {
        &.modal {
            &.right {
                .modal-dialog {
                    width: 100%;
                }
            }
        }
        .modal-content {
            .modal-body {
                .products-cart-content {
                    .products-cart {
                        .remove-btn {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .productsFilterModal {
        button.close {
            left: 15px;
            top: 15px;
        }
        &.modal.left {
            .modal-dialog {
                width: 100%;
            }
        }
        .modal-content {
            padding: {
                top: 55px;
                bottom: 55px;
                left: 15px;
                right: 15px;
            };
        }
    }

}

@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {

    .container {
        max-width: 540px;
    }

    .banner-content {
        p {
            max-width: 360px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }

    .single-banner-categories-box {
        text-align: left;

        .content {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 20px;

            .btn-box {
                .d-flex {
                    justify-content: unset;
                }
            }
        }
    }

    .products-collections-area {
        .container-fluid {
            max-width: 540px;
        }
    }

    .shoppingCartModal {
        &.modal {
            &.right {
                .modal-dialog {
                    width: 320px;
                }
            }
        }
    }
    .shoppingWishlistModal {
        &.modal {
            &.right {
                .modal-dialog {
                    width: 320px;
                }
            }
        }
    }
    .productsFilterModal {
        &.modal.left {
            .modal-dialog {
                width: 400px;
            }
        }
    }

}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {

    body {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }
    .container {
        max-width: 720px;
    }
    .section-title {
        text-align: center;

        &::before {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .top-header-contact-info {
        li {
            margin-right: 10px;
            padding-left: 20px;
            font-size: 13.5px;

            i {
                top: 2px;
                font-size: 14px;
            }
        }
    }
    .top-header-menu {
        li {
            .currency-switcher {
                .dropdown-toggle {
                    font-size: 14px;
                }
            }
            .language-switcher {
                .dropdown-toggle {
                    font-size: 14px;
                }
            }
        }
    }

    .drodo-responsive-nav {
        .drodo-responsive-menu {
            &.mean-container {
                .others-option {
                    top: 6px;

                    .option-item {
                        .search-btn-box {                            
                            .search-btn {
                                font-size: 23px;
                            }
                        }
                        .cart-btn {
                            font-size: 23px;
                        }
                        .wishlist-btn {
                            font-size: 23px;
                        }
                    }
                }
            }
        }
    }

    .single-banner-item {
        height: auto;
        padding: {
            top: 80px;
            bottom: 80px;
        };
    }
    .banner-content {
        text-align: center;
        max-width: 540px;
        margin: {
            left: auto;
            right: auto;
        };
        h1 {
            font-size: 50px;
        }
        .btn-box {
            .d-flex {
                justify-content: center;
            }
            .default-btn {
                margin-right: 8px;
            }
            .price {
                margin-left: 8px;
            }
        }
    }
    .banner-image {
        margin-top: 30px;
    }
    .home-wrapper-area {
        padding: {
            top: 100px;
            bottom: 100px;
        };
    }
    .wrapper-content {
        text-align: center;
        max-width: 540px;
        margin: {
            left: auto;
            right: auto;
        };
        h1 {
            font-size: 50px;
        }
    }
    .wrapper-image {
        margin-top: 30px;
    }
    .home-slides-two {
        &.owl-theme {
            .owl-dots {
                text-align: left;
                margin-top: 0;
                bottom: 4px;
            }
            .owl-nav {
                position: relative;
                left: 0;
                transform: unset;
                margin-left: 0;
                bottom: 0;
                text-align: right;
                margin-top: 25px;
            }
        }
    }
    .main-banner-content {
        max-width: 540px;
        text-align: center;
        margin: {
            left: auto;
            right: auto;
        };
        h1 {
            font-size: 50px;
        }
    }
    .main-banner-image {
        text-align: center;
        margin-top: 35px;
    }
    .megamenu-container {
        display: none;
    }
    .banner-area {
        .banner-content {
            max-width: 600px;
            text-align: left;
            margin: {
                left: 0;
                right: 0;
            };
            .btn-box {
                .d-flex {
                    justify-content: unset;
                }
            }
        }
    }

    .single-banner-categories-box {
        .content {
            position: relative;
            top: 0;
            transform: translateY(0);
            padding: 25px 0 0;
        }
    }

    .single-products-box {
        .image {
            .buttons-list {
                ul {
                    li {
                        opacity: 1 !important;
                        visibility: visible !important;
                        transform: translateX(0) !important;
                    }
                }
            }
        }
    }

    .hot-deal-content {
        max-width: 540px;
        margin: {
            left: auto;
            right: auto;
        };
        h2 {
            font-size: 50px;
        }
        .back-text {
            font-size: 240px;
        }
    }

    .single-brands-item {
        border-right: none;
    }

    .single-facility-box {
        padding-left: 0;
        text-align: center;
        border-right: none;

        .icon {
            position: relative;
            margin-bottom: 15px;
        }
        h3 {
            font-size: 19px;
        }
    }

    .feedback-inner {
        margin-top: 40px;
    }

    .page-title-content {
        h1 {
            font-size: 35px;
        }
        ul {
            li {
                font-size: 15px;

                &::before {
                    top: 5.2px;
                    height: 13px;
                }
            }
        }
    }

    .about-content {
        text-align: center;
    }
    .about-image {
        margin-top: 35px;
    }

    .single-feedback-box {
        padding: {
            left: 15px;
            right: 15px;
            bottom: 20px;
            top: 75px;
        };
        p {
            font-size: 15px;
        }
        &::before {
            top: 20px;
            font-size: 40px;
        }
        .client-info {
            .title {
                h3 {
                    font-size: 18px;
                }
            }
        }
    }

    .history-timeline {
        .timeline-block {
            .timeline-content {
                .image {
                    margin-top: 30px;
                }
            }
        }
    }

    .widget-area {
        margin-top: 40px;
        padding-left: 0;
        
        &.widget-left-sidebar {
            padding-right: 0;
            margin: {
                top: 0;
                bottom: 40px;
            };
        }
    }

    .blog-details-desc {
        .article-image-slides {
            &.owl-theme {
                .owl-nav {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .coming-soon-area {
        height: auto;
        padding: {
            top: 120px;
            bottom: 120px;
        };
    }
    .coming-soon-content {
        max-width: 650px;

        h2 {
            font-size: 35px;
        }
    }

    .contact-image {
        margin-top: 30px;
    }
    .contact-form {
        padding-right: 0;
        text-align: center;

        form {
            .form-group {
                text-align: left;
            }
        }
    }

    .faq-accordion-tab {
        .tabs {
            li {
                margin: {
                    left: 7px;
                    right: 7px;
                };
                a {
                    padding: 10px;
                }
            }
        }
    }

    .track-order-content {
        max-width: 600px;

        form {
            padding: {
                left: 0;
                right: 0;
            };
        }
    }

    .products-details-desc {
        margin-top: 35px;
    }
    .products-details-image-slider {
        &.owl-theme {
            margin-bottom: 0;
        }
    }

    .woocommerce-widget-area {
        display: none;
    }

    .products-collections-area {
        .container-fluid {
            max-width: 720px;
            width: 100%;
        }
    }

    .order-details {
        margin-top: 35px;
    }

    .productsQuickView {
        .modal-dialog {
            max-width: 720px;
        }
        .modal-content {
            padding: 25px;
        }
    }

}

@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {

    .container {
        max-width: 960px;
    }

    .banner-content {
        h1 {
            font-size: 55px;
        }
    }
    .home-slides {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    top: auto;
                    transform: unset;
                    bottom: 20px;
                }
            }
        }
    }
    .home-slides-two {
        &.owl-theme {
            .owl-dots {
                display: none;
            }
            .owl-nav {
                margin-left: -60px;

                [class*=owl-] {
                    width: 35px;
                    height: 35px;
                    font-size: 20px;
                    line-height: 35px;
                }
            }
        }
    }
    .main-banner-content {
        h1 {
            font-size: 42px;
        }
    }
    .megamenu-container {
        .megamenu-category {
            .nav {
                .nav-item {
                    .nav-link {
                        padding: {
                            top: 11px;
                            bottom: 11px;
                        };
                    }
                }
            }
        }
    }

    .single-categories-box {
        padding: 20px 10px;

        h3 {
            font-size: 15px;
        }
    }
    .categories-box {
        .content {
            top: 15px;
            left: 15px;
            padding: {
                left: 15px;
                right: 15px;
            };
            h3 {
                font-size: 15px;
            }
        }
    }

    .single-products-promotions-box {
        .content {
            h3 {
                font-size: 22px;
            }
            .discount {
                font-size: 22px;
            }
        }
    }

    .hot-deal-content {
        h2 {
            font-size: 60px;
        }
        #timer {
            div {
                width: 98px;
                height: 100px;
                margin: {
                    left: 7px;
                    right: 7px;
                };
            }
        }
    }

    .promotion-banner {
        padding: {
            top: 75px;
            bottom: 75px;
        };
    }

    .single-facility-box {
        padding-left: 0;
        text-align: center;

        .icon {
            position: relative;
            margin-bottom: 18px;
        }
    }

    .page-title-area {
        top: 70px;
        bottom: 70px;
    }
    .page-title-content {
        h1 {
            font-size: 35px;
        }
        ul {
            margin-top: 10px;

            li {
                font-size: 15px;

                &::before {
                    top: 5.2px;
                }
            }
        }
    }

    .single-feedback-box {
        p {
            font-size: 16px;
        }
    }

    .single-why-choose-box {
        padding: 25px 15px;
    }

    .widget-area {
        padding-left: 0;

        .widget {
            padding: 20px;
        }
        &.widget-left-sidebar {
            padding-right: 0;
        }
    }

    .login-form {
        margin-right: 0;
    }
    .register-form {
        padding-left: 0;
    }

    .products-collections-area {
        .container-fluid {
            max-width: 960px;
            width: 100%;
        }
    }

}